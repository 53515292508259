import React, { useEffect, useRef } from 'react';
import { PencilIcon, TrashIcon, PlusIcon } from '@heroicons/react/20/solid';

const KategoriContextMenu = ({ x, y, kategori, onClose, onDuzenle, onSil, onYeniEkle }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleYeniAltKategoriEkle = () => {
    onYeniEkle(null);
    onClose();
  };

  return (
    <div
      ref={menuRef}
      className="absolute bg-white border rounded shadow-lg z-50"
      style={{ top: `${y}px`, left: `${x}px` }}
    >
      <ul className="py-2">
        <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center" onClick={() => { onDuzenle(kategori); onClose(); }}>
          <PencilIcon className="h-5 w-5 mr-2 text-blue-500" />
          <span>Düzenle</span>
        </li>
        <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center" onClick={() => { onSil(kategori); onClose(); }}>
          <TrashIcon className="h-5 w-5 mr-2 text-red-500" />
          <span>Sil</span>
        </li>
        <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center" onClick={handleYeniAltKategoriEkle}>
          <PlusIcon className="h-5 w-5 mr-2 text-green-500" />
          <span>Yeni Alt Kategori Ekle</span>
        </li>
      </ul>
    </div>
  );
};

export default KategoriContextMenu;
