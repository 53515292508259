import React, { useState, useEffect, useCallback } from 'react';
import GoruntulemeAlani from './duzenleme/GoruntulemeAlani.js';
import AISuggestionsModal from './duzenleme/AISuggestionsModal.js';
import DuzenlemeFormu from './duzenleme/DuzenlemeFormu.js';

import HeaderCard from './HeaderCard';
import Select from 'react-select';
import { toast } from 'react-toastify';

const SablonDuzenleyici = ({ sablon, onKaydet, onIptal, kategoriler, yeniSablon, tumKanunMaddeleri, tumEtiketler, onSablonEklendi }) => {
  const [duzenlenmisSablon, setDuzenlenmisSablon] = useState({
    ...sablon,
    etiketler: (sablon.etiketler || []).map(etiket => 
      typeof etiket === 'string' ? { value: etiket, label: etiket } : etiket
    ),
    kanun_maddeleri: (sablon.kanun_maddeleri || []).map(madde => 
      typeof madde === 'string' ? { value: madde, label: madde } : madde
    )
  });
  const [activeTab, setActiveTab] = useState(yeniSablon ? 'duzenle' : 'onizle');
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showAISuggestionsModal, setShowAISuggestionsModal] = useState(false);
  const [editableAISuggestions, setEditableAISuggestions] = useState({
    ozet: '',
    baslik: '',
    etiketler: [],
    kanunMaddeleri: []
  });
  const [gorunumModu, setGorunumModu] = useState('sayfa');

  useEffect(() => {
    setDuzenlenmisSablon({
      ...sablon,
      etiketler: Array.isArray(sablon.etiketler) 
        ? sablon.etiketler.map(etiket => typeof etiket === 'string' ? { value: etiket, label: etiket } : etiket) 
        : [],
      kanun_maddeleri: Array.isArray(sablon.kanun_maddeleri) 
        ? sablon.kanun_maddeleri.map(madde => typeof madde === 'string' ? { value: madde, label: madde } : madde) 
        : []
    });
    setActiveTab(yeniSablon ? 'duzenle' : 'onizle');
  }, [sablon, yeniSablon]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDuzenlenmisSablon(prev => ({ ...prev, [name]: value }));
  };

  const handleIcerikChange = (icerik) => {
    setDuzenlenmisSablon(prev => ({ ...prev, icerik }));
  };

  const handleSelectChange = useCallback((name, selectedOptions) => {
    setDuzenlenmisSablon(prev => {
      const updatedSablon = {
        ...prev,
        [name]: name === 'kategoriler' 
          ? selectedOptions.map(option => ({ id: parseInt(option.value), isim: option.label }))
          : selectedOptions
      };
      return updatedSablon;
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const kaydedilenSablon = await onKaydet(duzenlenmisSablon);
      toast.success('Şablon başarıyla kaydedildi.');
      if (yeniSablon && onSablonEklendi) {
        onSablonEklendi(kaydedilenSablon);
      }
      onIptal();
    } catch (error) {
      toast.error('Şablon kaydedilirken bir hata oluştu.');
    }
  };

  const handleAIEnhance = ({ ozet, baslik, etiketler, kanunMaddeleri }) => {
    setEditableAISuggestions({ ozet, baslik, etiketler, kanunMaddeleri });
    setShowAISuggestionsModal(true);
  };

  const handleAISuggestionsChange = (e) => {
    const { name, value } = e.target;
    setEditableAISuggestions(prev => ({ ...prev, [name]: value }));
  };

  const handleAISuggestionsSave = () => {
    setDuzenlenmisSablon(prev => ({
      ...prev,
      ozet: editableAISuggestions.ozet,
      baslik: editableAISuggestions.baslik || prev.baslik,
      etiketler: [...new Set([...prev.etiketler, ...editableAISuggestions.etiketler])],
      kanun_maddeleri: [...new Set([...(prev.kanun_maddeleri || []), ...editableAISuggestions.kanunMaddeleri])]
    }));
    setShowAISuggestionsModal(false);
  };

  const handleDetailsSave = async (updatedSablon) => {
    try {
      const formattedSablon = {
        ...updatedSablon,
        kategoriler: updatedSablon.kategoriler
          .filter(kategori => kategori && typeof kategori.id === 'number' && kategori.isim)
          .map(kategori => ({
            id: kategori.id,
            isim: kategori.isim
          }))
      };
      await onKaydet(formattedSablon);
      setDuzenlenmisSablon(formattedSablon);
      setShowDetailsModal(false);
      setActiveTab('duzenle');
      toast.success('Şablon başarıyla güncellendi.');
    } catch (error) {
      console.error('Şablon güncellenirken hata:', error);
      toast.error('Şablon güncellenirken bir hata oluştu.');
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* <div className="w-full bg-gray-200 p-2 rounded-lg mb-4">
        <div className="flex w-full bg-gray-100 rounded-md p-1">
          <button
            className={`flex-1 py-2 rounded-md transition-all duration-300 ease-in-out ${
              activeTab === 'onizle'
                ? 'bg-white text-gray-800 shadow-md font-bold'
                : 'bg-transparent text-gray-700 hover:bg-gray-200'
            }`}
            onClick={() => setActiveTab('onizle')}
          >
            Önizleme
          </button>
          <button
            className={`flex-1 py-2 rounded-md transition-all duration-300 ease-in-out ${
              activeTab === 'duzenle'
                ? 'bg-white text-gray-800 shadow-md font-bold'
                : 'bg-transparent text-gray-700 hover:bg-gray-200'
            }`}
            onClick={() => setActiveTab('duzenle')}
          >
            Düzenle
          </button>
        </div>
      </div> */}
      <HeaderCard
        baslik={duzenlenmisSablon.baslik}
        onDuzenle={() => setActiveTab('duzenle')}
        onIptal={onIptal}
        onAIGelistir={handleAIEnhance}
        onDetaylar={() => setShowDetailsModal(true)}
        gorunumModu={gorunumModu}
        setGorunumModu={setGorunumModu}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        icerik={duzenlenmisSablon.icerik}
        tumEtiketler={tumEtiketler}
        tumKanunMaddeleri={tumKanunMaddeleri}
        duzenlenmisSablon={duzenlenmisSablon}
        kategoriler={kategoriler}
        handleChange={handleChange}
        handleSelectChange={handleSelectChange}
        kayitliEtiketler={tumEtiketler}
        kayitliKanunMaddeleri={tumKanunMaddeleri}
        onSave={handleDetailsSave}
        handleSubmit={handleSubmit}
      />
      <div className="flex-grow overflow-y-auto">
        {activeTab === 'duzenle' ? (
          <DuzenlemeFormu
            sablon={sablon}
            duzenlenmisSablon={duzenlenmisSablon}
            kategoriler={kategoriler}
            tumEtiketler={tumEtiketler}
            tumKanunMaddeleri={tumKanunMaddeleri}
            handleChange={handleChange}
            handleIcerikChange={handleIcerikChange}
            handleSelectChange={handleSelectChange}
            handleSubmit={handleSubmit}
            handleIptal={onIptal}
            handleAIEnhance={handleAIEnhance}
            onShowDetails={() => setShowDetailsModal(true)}
          />
        ) : (
          <GoruntulemeAlani
            sablon={duzenlenmisSablon}
            onDuzenle={() => setActiveTab('duzenle')}
            onKapat={onIptal}
            onShowDetails={() => setShowDetailsModal(true)}
          />
        )}
      </div>
  
      <AISuggestionsModal
        show={showAISuggestionsModal}
        suggestions={editableAISuggestions}
        onChange={handleAISuggestionsChange}
        onSave={handleAISuggestionsSave}
        onClose={() => setShowAISuggestionsModal(false)}
      />
    </div>
  );
};

export default SablonDuzenleyici;
