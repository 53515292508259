import React from 'react';

const GoruntulemeAlani = ({ sablon }) => (
  <div className="flex flex-col h-full bg-gray-100 p-4 overflow-auto">
    <div className="bg-white shadow-md mx-auto w-[21cm] min-h-[29.7cm] p-[1cm]">
      <div className="prose max-w-none">
        <div dangerouslySetInnerHTML={{ __html: sablon.icerik }} />
      </div>
    </div>
  </div>
);

export default GoruntulemeAlani;
