import React, { useState, useEffect } from 'react';
import SablonListesi from './SablonListesi';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

const SablonListesiSayfalama = ({ sablonlar, kategoriler, onDuzenle, onSil }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sablonlarPerPage] = useState(18);

  useEffect(() => {
    setCurrentPage(1);
  }, [sablonlar]);

  const totalPages = Math.ceil(sablonlar.length / sablonlarPerPage);
  const indexOfLastSablon = currentPage * sablonlarPerPage;
  const indexOfFirstSablon = indexOfLastSablon - sablonlarPerPage;
  const currentSablonlar = sablonlar.slice(indexOfFirstSablon, indexOfLastSablon);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <SablonListesi
        sablonlar={currentSablonlar}
        kategoriler={kategoriler}
        onDuzenle={onDuzenle}
        onSil={onSil}
      />
      {totalPages > 1 && (
        <div className="flex justify-center items-center mt-6 space-x-2">
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-3 py-1 rounded-md bg-gray-200 text-gray-600 disabled:opacity-50"
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </button>
          {[...Array(totalPages).keys()].map((number) => (
            <button
              key={number + 1}
              onClick={() => paginate(number + 1)}
              className={`px-3 py-1 rounded-md ${
                currentPage === number + 1
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-600'
              }`}
            >
              {number + 1}
            </button>
          ))}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-3 py-1 rounded-md bg-gray-200 text-gray-600 disabled:opacity-50"
          >
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SablonListesiSayfalama;
