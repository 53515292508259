import axios from 'axios';
import { Kategori } from '../components/kararSablonlari/Kategorilendirme';

const API_URL = `${process.env.REACT_APP_API_URL}/kategoriler`;

export const getAllKategoriler = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data.map(kategori => new Kategori(kategori.id, kategori.isim, kategori.altKategoriler));
  } catch (error) {
    console.error('Kategoriler alınırken hata oluştu:', error);
    throw error;
  }
};

export const createKategori = async (kategoriData) => {
  try {
    const response = await axios.post(API_URL, kategoriData);
    return response.data;
  } catch (error) {
    console.error('Kategori oluşturma hatası:', error);
    console.log('Sunucu yanıtı:', error.response?.data);
    throw error;
  }
};

export const updateKategori = async (id, kategoriData) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, kategoriData);
    return response.data;
  } catch (error) {
    console.error('Kategori güncellenirken hata oluştu:', error);
    throw error;
  }
};

export const deleteKategori = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`);
    if (response.status === 204 || response.status === 200) {
      return true; // Başarılı silme işlemi
    } else {
      console.error('Beklenmeyen yanıt:', response);
      throw new Error('Kategori silinemedi: Beklenmeyen sunucu yanıtı');
    }
  } catch (error) {
    console.error('Kategori silinirken hata oluştu:', error);
    if (error.response) {
      console.error('Sunucu yanıtı:', error.response.data);
      throw new Error(`Kategori silinemedi: ${error.response.data.message || 'Sunucu hatası'}`);
    }
    throw error;
  }
};

export const getKategoriByName = async (kategoriAdi) => {
  try {
    const response = await axios.get(`${API_URL}/name/${encodeURIComponent(kategoriAdi)}`);
    console.log('Kategori bulundu:', response.data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.log(`Kategori bulunamadı: ${kategoriAdi}`);
      return null;
    }
    console.error('Kategori adına göre alınırken hata oluştu:', error);
    throw error;
  }
};

