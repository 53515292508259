import { filtreliSablonlariGetir } from '../components/kararSablonlari/sablonFiltrele';
import { turkishToLower } from './stringUtils';

export const hesaplaKategoriSablonSayilari = (kategoriler, sablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, aramaMetni) => {
  const sablonSayilari = new Map();
  const sayilmisSablonlar = new Set();

  // Şablonları önceden filtrele
  const filtrelenmisŞablonlar = filtreliSablonlariGetir(sablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, aramaMetni);

  const hesaplaSayilar = (kategori) => {
    let dogrudan = 0;
    let toplam = 0;

    filtrelenmisŞablonlar.forEach(sablon => {
      if (sablon.kategoriler && sablon.kategoriler.some(kat => kat.isim === kategori.isim)) {
        dogrudan++;
        if (!sayilmisSablonlar.has(sablon.id)) {
          toplam++;
          sayilmisSablonlar.add(sablon.id);
        }
      }
    });

    if (kategori.altKategoriler) {
      kategori.altKategoriler.forEach(altKategori => {
        const [altDogrudan, altToplam] = hesaplaSayilar(altKategori);
        toplam += altToplam; // Sadece toplam sayıyı ekle
      });
    }

    sablonSayilari.set(kategori.isim, [dogrudan, toplam]);
    return [dogrudan, toplam];
  };

  kategoriler.forEach(kategori => hesaplaSayilar(kategori));

  // Kategorisiz şablonları hesapla
  const kategorisizSablonlar = filtrelenmisŞablonlar.filter(sablon => !sablon.kategoriler || sablon.kategoriler.length === 0);
  sablonSayilari.set('Kategorisiz', [kategorisizSablonlar.length, kategorisizSablonlar.length]);

  // Tüm şablonların sayısını hesapla
  sablonSayilari.set('Tümü', [filtrelenmisŞablonlar.length, filtrelenmisŞablonlar.length]);

  return sablonSayilari;
};
