import React, { useState, useEffect } from 'react';

const CezaSahsilestirmeCard = ({ info, onUpdate }) => {
  const [localInfo, setLocalInfo] = useState(info);

  useEffect(() => {
    setLocalInfo(info);
  }, [info]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleBlur = () => {
    onUpdate(localInfo);
  };

  return (
    <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      {/* <h3 className="text-xl font-bold mb-4">Cezanın Şahsîleştirilmesi</h3>
       */}
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="yerelMahkemeUygulamasi">
          Yerel Mahkeme ya da BAM'ın cezanın şahsîleştirilmesine ilişkin uygulaması ve gerekçesi:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="yerelMahkemeUygulamasi"
          name="yerelMahkemeUygulamasi"
          value={localInfo.yerelMahkemeUygulamasi || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="4"
        />
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tck62">
          TCK'nın 62.m:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="tck62"
          name="tck62"
          value={localInfo.tck62 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="3"
        />
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tck50">
          TCK'nın 50.m:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="tck50"
          name="tck50"
          value={localInfo.tck50 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="3"
        />
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tck51">
          TCK'nın 51.m:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="tck51"
          name="tck51"
          value={localInfo.tck51 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="3"
        />
      </div>
      
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cmk231">
          CMK'nın 231.m:
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="cmk231"
          name="cmk231"
          value={localInfo.cmk231 || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          rows="3"
        />
      </div>
    </div>
  );
};

export default CezaSahsilestirmeCard;