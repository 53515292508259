import React, { useState, useEffect } from 'react';
import { Etiketleme, KanunMaddeleri } from './Etiketleme.js';
import { KategoriSecici, Kategori } from '../Kategorilendirme';
import Select from 'react-select';
import { toast } from 'react-toastify';


const DetaylarModal = ({
  duzenlenmisSablon,
  kategoriler = [],
  handleChange,
  handleSelectChange,
  onClose,
  kayitliEtiketler,
  kayitliKanunMaddeleri,
  onSave
}) => {
  const [localSablon, setLocalSablon] = useState(duzenlenmisSablon);

  useEffect(() => {
    setLocalSablon({
      ...duzenlenmisSablon,
      kategoriler: duzenlenmisSablon.kategoriler
        ? duzenlenmisSablon.kategoriler
            .filter(kategori => kategori && typeof kategori.id === 'number' && kategori.isim)
            .map(kategori => new Kategori(kategori.id, kategori.isim))
        : []
    });
  }, [duzenlenmisSablon]);

  const handleEtiketChange = (yeniEtiketler) => {
    setLocalSablon(prev => ({ ...prev, etiketler: yeniEtiketler }));
  };

  const handleKanunMaddeleriChange = (yeniKanunMaddeleri) => {
    setLocalSablon(prev => ({ ...prev, kanun_maddeleri: yeniKanunMaddeleri }));
  };

  const handleLocalChange = (e) => {
    const { name, value } = e.target;
    setLocalSablon(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    const updatedSablon = {
      ...localSablon,
      etiketler: localSablon.etiketler.map(etiket => typeof etiket === 'object' ? etiket.value : etiket),
      kanun_maddeleri: localSablon.kanun_maddeleri.map(madde => typeof madde === 'object' ? madde.value : madde),
      kategoriler: localSablon.kategoriler
        .filter(kategori => kategori instanceof Kategori && typeof kategori.id === 'number' && kategori.isim)
        .map(kategori => ({
          id: kategori.id,
          isim: kategori.isim
        }))
    };

    try {
      await onSave(updatedSablon);
      toast.success('Şablon başarıyla kaydedildi.');
      onClose();
    } catch (error) {
      console.error('Şablon kaydedilirken hata:', error);
      toast.error('Şablon kaydedilirken bir hata oluştu.');
    }
  };

  // Null checks ve varsayılan değerler ekleyelim
  const etiketler = localSablon?.etiketler || [];
  const kanunMaddeleri = localSablon?.kanun_maddeleri || [];
  const kayitliEtiketlerArray = kayitliEtiketler || [];
  const kayitliKanunMaddeleriArray = kayitliKanunMaddeleri || [];


  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-lg">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800">Detayları Düzenle</h2>
        <div className="space-y-6">
          <div>
            <label htmlFor="ozet" className="block text-lg font-medium text-gray-700">Özet</label>
            <textarea
              id="ozet"
              name="ozet"
              value={localSablon?.ozet || ''}
              onChange={handleLocalChange}
              className="mt-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
              rows="4"
            />
          </div>
          <div>
            <label htmlFor="baslik" className="block text-lg font-medium text-gray-700">Başlık</label>
            <input
              type="text"
              id="baslik"
              name="baslik"
              value={localSablon?.baslik || ''}
              onChange={handleLocalChange}
              className="mt-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
            />
          </div>
          <div>
            <Etiketleme
              etiketler={etiketler}
              onChange={handleEtiketChange}
              kayitliEtiketler={kayitliEtiketlerArray.map(etiket => typeof etiket === 'string' ? etiket : etiket.value)}
            />
          </div>
          <div>
            <KanunMaddeleri
              kanunMaddeleri={kanunMaddeleri}
              onChange={handleKanunMaddeleriChange}
              kayitliKanunMaddeleri={kayitliKanunMaddeleriArray.map(madde => typeof madde === 'string' ? madde : madde.value)}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Kategoriler</label>
            <KategoriSecici
              kategoriler={kategoriler}
              secilenKategoriler={localSablon.kategoriler}
              onChange={(updatedKategoriler) => {
                setLocalSablon(prev => ({
                  ...prev,
                  kategoriler: updatedKategoriler.filter(k => k instanceof Kategori && k.id && k.isim)
                }));
              }}
            />
          </div>
        </div>
        <div className="flex justify-end space-x-4 mt-8">
          <button
            onClick={onClose}
            className="px-5 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition duration-150 ease-in-out"
          >
            İptal
          </button>
          <button
            onClick={handleSave}
            className="px-5 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-150 ease-in-out"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetaylarModal;
