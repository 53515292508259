import React, { useState, useEffect, useMemo, useCallback } from 'react';
import KategoriAgaci from '../components/kararSablonlari/KategoriAgaci.js';
import SablonKarti from '../components/kararSablonlari/SablonKarti.js';
import SablonDuzenleyici from '../components/kararSablonlari/SablonDuzenleyici.js';
import SablonArama from '../components/kararSablonlari/SablonArama';
import EtiketListesi from '../components/kararSablonlari/EtiketListesi';
import CollapsiblePanel from '../components/common/CollapsiblePanel.js';
import { getSablonlar, setSablonlar } from '../components/kararSablonlari/sablonUtils';
import { PlusIcon } from '@heroicons/react/20/solid';
import { turkishToLower } from '../utils/stringUtils';
import { filtreliSablonlariGetir } from '../components/kararSablonlari/sablonFiltrele.js';
import * as sablonService from '../services/sablonService';
import * as kategoriService from '../services/kategoriService';
import { toast } from 'react-toastify';
import Tab from '../components/common/Tab';
import SablonListesi from '../components/kararSablonlari/SablonListesi';
import YeniSablonEkle from '../components/kararSablonlari/YeniSablonEkle';
import { useMediaQuery } from 'react-responsive';
import SablonListesiSayfalama from '../components/kararSablonlari/SablonListesiSayfalama';
import { hesaplaKategoriSablonSayilari } from '../utils/kategoriSablonSayaci'; // Bu satırı ekleyin

const baslangicKategoriYapisi = [
  {
    isim: 'Tekerrür',
    altKategoriler: [
      { isim: 'TCK Maddeleri', altKategoriler: [{ isim: 'TCK 58. Madde' }, { isim: 'TCK 50. Madde' }] },
      { isim: 'Suç Türleri', altKategoriler: [{ isim: 'Elektrik Hırsızlığı' }] },
      { isim: 'Hüküm Türleri', altKategoriler: [{ isim: 'Erteleme' }, { isim: 'Seçenek Yaptırımlar' }] },
      { isim: 'Özel Durumlar', altKategoriler: [{ isim: 'Yaş Durumu' }, { isim: 'Süre Hesaplaması' }] },
    ]
  }
];

const KararSablonlari = () => {
  const [sablonlar, setSablonlarState] = useState([]);
  const [sablonAramaMetni, setSablonAramaMetni] = useState('');
  const [kategoriAramaMetni, setKategoriAramaMetni] = useState('');
  const [secilenKategoriler, setSecilenKategoriler] = useState(['Tümü']);
  const [secilenEtiketler, setSecilenEtiketler] = useState([]);
  const [secilenKanunMaddeleri, setSecilenKanunMaddeleri] = useState([]);
  const [duzenlenecekSablon, setDuzenlenecekSablon] = useState(null);
  const [kategoriYapisi, setKategoriYapisi] = useState(baslangicKategoriYapisi);
  const [tumKanunMaddeleri, setTumKanunMaddeleri] = useState([]);
  const [tumEtiketler, setTumEtiketler] = useState([]);
  const [activeTab, setActiveTab] = useState('list');
  const [openTabs, setOpenTabs] = useState([]);
  const [aktifSablon, setAktifSablon] = useState(null);
  const [filtrelenmisVeGoruntulenensablonlar, setFiltrelenmisVeGoruntulenensablonlar] = useState([]);
  const [kategoriSablonSayilari, setKategoriSablonSayilari] = useState(new Map());

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [solPanelAcik, setSolPanelAcik] = useState(!isMobile);

  useEffect(() => {
    const fetchData = async () => {
      const [yuklenenSablonlar, yuklenenKategoriler] = await Promise.all([
        sablonService.getSablonlar(),
        fetchKategoriler()
      ]);

      const kanunMaddeleriSet = new Set();
      const etiketlerSet = new Set();

      yuklenenSablonlar.forEach(sablon => {
        if (sablon.kanun_maddeleri && Array.isArray(sablon.kanun_maddeleri)) {
          sablon.kanun_maddeleri.forEach(madde => {
            const maddeValue = typeof madde === 'string' ? madde : madde.value;
            kanunMaddeleriSet.add(maddeValue);
          });
        }
        if (sablon.etiketler && Array.isArray(sablon.etiketler)) {
          sablon.etiketler.forEach(etiket => {
            const etiketValue = typeof etiket === 'string' ? etiket : etiket.value;
            etiketlerSet.add(etiketValue);
          });
        }
      });

      const kanunMaddeleriArray = Array.from(kanunMaddeleriSet);
      const etiketlerArray = Array.from(etiketlerSet);

      setSablonlarState(yuklenenSablonlar);
      setTumKanunMaddeleri(kanunMaddeleriArray);
      setTumEtiketler(etiketlerArray);

      console.log('Yüklenen kanun maddeleri:', kanunMaddeleriArray);
      console.log('Yüklenen etiketler:', etiketlerArray);

      setKategoriYapisi(yuklenenKategoriler);
    };

    fetchData();
  }, []);

  const filtreliSablonlar = useMemo(() => {
    return filtreliSablonlariGetir(sablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, sablonAramaMetni);
  }, [sablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, sablonAramaMetni]);

  // Filtrelenmiş şablonlardan etiketleri ve kanun maddelerini hesapla
  const [filtrelenmisEtiketler, filtrelenmisKanunMaddeleri] = useMemo(() => {
    const etiketSet = new Set();
    const kanunMaddeleriSet = new Set();

    filtreliSablonlar.forEach(sablon => {
      if (sablon.etiketler && Array.isArray(sablon.etiketler)) {
        sablon.etiketler.forEach(etiket => etiketSet.add(etiket));
      }
      if (sablon.kanun_maddeleri && Array.isArray(sablon.kanun_maddeleri)) {
        sablon.kanun_maddeleri.forEach(madde => kanunMaddeleriSet.add(madde));
      }
    });

    return [Array.from(etiketSet), Array.from(kanunMaddeleriSet)];
  }, [filtreliSablonlar]);

  const handleYeniSablon = () => {
    setActiveTab('yeniSablon');
  };

  const handleSablonKaydet = async (yeniSablon) => {
    try {
      let kaydedilenSablon;
      if (yeniSablon.id) {
        kaydedilenSablon = await sablonService.updateSablon(yeniSablon.id, yeniSablon);
      } else {
        kaydedilenSablon = await sablonService.createSablon(yeniSablon);
      }
      
      if (!kaydedilenSablon.id) {
        throw new Error('Şablon kaydedilirken bir hata oluştu: Geçersiz yanıt');
      }

      setSablonlarState(prevSablonlar => {
        const yeniSablonlar = prevSablonlar.filter(s => s.id !== kaydedilenSablon.id);
        return [...yeniSablonlar, kaydedilenSablon];
      });

      toast.success('Şablon başarıyla kaydedildi.');
      return kaydedilenSablon;
    } catch (error) {
      console.error('Şablon kaydedilirken hata:', error);
      toast.error('Şablon kaydedilirken bir hata oluştu.');
      throw error;
    }
  };

  const handleKategoriEkle = async (yeniKategoriAdi, parentKategori) => {
    try {
      let parentId = null;
      if (parentKategori) {
        const parentKategoriObj = await kategoriService.getKategoriByName(parentKategori);
        if (parentKategoriObj) {
          parentId = parentKategoriObj.id;
        }
      }
      console.log('Ekleniyor:', yeniKategoriAdi, 'Parent:', parentKategori, 'ParentID:', parentId); // Hata ayıklama için
      const yeniKategori = await kategoriService.createKategori({ isim: yeniKategoriAdi, parent_id: parentId });
      const updatedKategoriler = await kategoriService.getAllKategoriler();
      setKategoriYapisi(updatedKategoriler);
    } catch (error) {
      console.error('Kategori eklenirken hata:', error);
      toast.error('Kategori eklenirken bir hata oluştu');
    }
  };

  const handleSablonSil = async (sablonId) => {
    try {
      await sablonService.deleteSablon(sablonId);
      const yeniSablonlar = sablonlar.filter(sablon => sablon.id !== sablonId);
      setSablonlarState(yeniSablonlar);
    } catch (error) {
      console.error('Şablon silinirken hata oluştu:', error);
    }
  };

  const handleSelectChange = (field, selectedOptions) => {
    setDuzenlenecekSablon(prevSablon => ({
      ...prevSablon,
      [field]: selectedOptions.map(option => option.value)
    }));
  };

  const fetchKategoriler = async () => {
    try {
      const response = await kategoriService.getAllKategoriler();
      return response;
    } catch (error) {
      console.error('Kategoriler yüklenirken hata:', error);
      toast.error('Kategoriler yüklenirken bir hata oluştu');
      return [];
    }
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const openNewTab = (sablon) => {
    if (!openTabs.some(tab => tab.id === sablon.id)) {
      setOpenTabs(prevTabs => [...prevTabs, { id: sablon.id, sablon }]);
    }
    setActiveTab(sablon.id);
  };

  const closeTab = (tabId) => {
    setOpenTabs(prevTabs => prevTabs.filter(tab => tab.id !== tabId));
    if (activeTab === tabId) {
      // Eğer kapatılan sekme aktif sekmeyse, bir önceki sekmeye veya şablon listesine geç
      const remainingTabs = openTabs.filter(tab => tab.id !== tabId);
      if (remainingTabs.length > 0) {
        setActiveTab(remainingTabs[remainingTabs.length - 1].id);
      } else {
        setActiveTab('list');
      }
    }
  };

  const handleAIEnhance = async (sablonId, aiSuggestions) => {
    const updatedSablonlar = sablonlar.map(sablon => 
      sablon.id === sablonId 
        ? { ...sablon, ...aiSuggestions }
        : sablon
    );
    setSablonlarState(updatedSablonlar);
    await sablonService.updateSablon(sablonId, aiSuggestions);
  };

  const onSablonEklendi = async (yeniSablon) => {
    console.log('Yeni eklenen şablon:', yeniSablon);
    if (yeniSablon.id) {
      try {
        const guncelSablon = await sablonService.getSablonById(yeniSablon.id);
        setSablonlarState(prevSablonlar => {
          const yeniListe = prevSablonlar.filter(s => s.id !== guncelSablon.id);
          return [...yeniListe, guncelSablon];
        });
        setActiveTab('list');
      } catch (error) {
        console.error('Yeni şablon alınırken hata oluştu:', error);
        toast.error(`Yeni şablon eklenirken bir hata oluştu: ${error.response?.data?.message || error.message}`);
        // Hata durumunda da yeni şablonu ekleyelim
        setSablonlarState(prevSablonlar => [...prevSablonlar, yeniSablon]);
        setActiveTab('list');
      }
    } else {
      console.error('Yeni eklenen şablonda ID bilgisi yok:', yeniSablon);
      toast.error('Yeni şablon eklenirken bir hata oluştu: ID bilgisi eksik');
    }
  };

  // Şablonları filtreleyen ve görüntüleyen fonksiyon
  const filtreleSablonlar = useCallback(() => {
    const filtrelenmis = sablonlar.filter(sablon => {
      // Filtreleme mantığınızı buraya ekleyin
      // Örnek:
      return (
        (!sablonAramaMetni || sablon.baslik.toLowerCase().includes(sablonAramaMetni.toLowerCase())) &&
        (secilenEtiketler.length === 0 || secilenEtiketler.every(etiket => sablon.etiketler.includes(etiket))) &&
        (secilenKanunMaddeleri.length === 0 || secilenKanunMaddeleri.every(madde => sablon.kanun_maddeleri.includes(madde)))
      );
    });
    setFiltrelenmisVeGoruntulenensablonlar(filtrelenmis);
  }, [sablonlar, sablonAramaMetni, secilenEtiketler, secilenKanunMaddeleri]);

  useEffect(() => {
    filtreleSablonlar();
  }, [filtreleSablonlar]);

  useEffect(() => {
    const sablonSayilari = hesaplaKategoriSablonSayilari(
      kategoriYapisi,
      sablonlar,
      secilenKategoriler,
      secilenEtiketler,
      secilenKanunMaddeleri,
      sablonAramaMetni
    );
    setKategoriSablonSayilari(sablonSayilari);
  }, [kategoriYapisi, sablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, sablonAramaMetni]);

  return (
    <div className="flex flex-col md:flex-row h-screen bg-gray-100 overflow-hidden">
      {/* Sol panel (kategoriler, etiketler, vb.) */}
      {(solPanelAcik || !isMobile) && (
        <div className={`${isMobile ? 'fixed inset-0 z-50 bg-white' : 'w-full md:w-1/4'} border-r flex flex-col overflow-y-auto transition-all duration-300 ease-in-out`}>
          {isMobile && (
            <button
              onClick={() => setSolPanelAcik(false)}
              className="p-2 text-gray-500 hover:text-gray-700"
            >
              Kapat
            </button>
          )}
          <CollapsiblePanel title="Kategoriler">
            <KategoriAgaci 
              kategoriler={kategoriYapisi}
              setKategoriler={setKategoriYapisi}
              secilenKategoriler={secilenKategoriler}
              setSecilenKategoriler={setSecilenKategoriler}
              onKategoriEkle={async (yeniKategori, parentKategori) => {
                const updatedKategoriler = await fetchKategoriler();
                setKategoriYapisi(updatedKategoriler);
              }}
              onKategoriSil={async (silinenKategori) => {
                const updatedKategoriler = await fetchKategoriler();
                setKategoriYapisi(updatedKategoriler);
              }}
              goruntulenensablonlar={filtreliSablonlar}
              aramaMetni={kategoriAramaMetni}
              setAramaMetni={setKategoriAramaMetni}
              secilenEtiketler={secilenEtiketler}
              secilenKanunMaddeleri={secilenKanunMaddeleri}
            />
          </CollapsiblePanel>
          <CollapsiblePanel title="Etiketler">
            <EtiketListesi
              etiketler={filtrelenmisEtiketler}
              secilenEtiketler={secilenEtiketler}
              setSecilenEtiketler={setSecilenEtiketler}
            />
          </CollapsiblePanel>
          <CollapsiblePanel title="Kanun Maddeleri">
            <EtiketListesi
              etiketler={filtrelenmisKanunMaddeleri}
              secilenEtiketler={secilenKanunMaddeleri}
              setSecilenEtiketler={setSecilenKanunMaddeleri}
            />
          </CollapsiblePanel>
        </div>
      )}

      {/* Sağ panel (sekmeler) */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {isMobile && !solPanelAcik && (
          <button
            onClick={() => setSolPanelAcik(true)}
            className="p-2 text-gray-500 hover:text-gray-700"
          >
            Filtreleri Göster
          </button>
        )}
        <div className="bg-white border-b flex overflow-x-auto">
          <Tab isActive={activeTab === 'list'} onClick={() => handleTabClick('list')} isFirst={true}>
            Şablon Listesi
          </Tab>
          {openTabs.map((tab, index) => (
            <Tab 
              key={tab.id} 
              isActive={activeTab === tab.id} 
              onClick={() => handleTabClick(tab.id)}
              onClose={() => closeTab(tab.id)}
              isFirst={false}
            >
              {isMobile ? `Şablon ${index + 1}` : tab.sablon.baslik}
            </Tab>
          ))}
        </div>
        <div className="flex-1 overflow-y-auto p-4">
          {activeTab === 'list' ? (
            <div>
              <div className="mb-4 flex flex-col md:flex-row justify-between items-center">
                <SablonArama 
                  aramaMetni={sablonAramaMetni} 
                  setAramaMetni={setSablonAramaMetni}
                  className="w-full md:w-auto mb-2 md:mb-0"
                />
                <button 
                  onClick={handleYeniSablon}
                  className="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md flex items-center justify-center transition duration-150 ease-in-out"
                >
                  <PlusIcon className="h-5 w-5 mr-2" />
                  Yeni Şablon
                </button>
              </div>
              <div className="text-sm text-gray-600 mb-4">
                {filtreliSablonlar.length} şablon bulundu
              </div>
              <SablonListesiSayfalama 
                sablonlar={filtreliSablonlar}
                kategoriler={kategoriYapisi}
                onDuzenle={(sablon) => openNewTab(sablon)}
                onSil={handleSablonSil}
                filtreAktif={sablonAramaMetni || secilenKategoriler.length > 0 || secilenEtiketler.length > 0 || secilenKanunMaddeleri.length > 0}
              />
            </div>
          ) : activeTab === 'yeniSablon' ? (
            <YeniSablonEkle
              kategoriler={kategoriYapisi}
              tumEtiketler={tumEtiketler}
              tumKanunMaddeleri={tumKanunMaddeleri}
              onKaydet={handleSablonKaydet}
              onIptal={() => setActiveTab('list')}
              onSablonEklendi={onSablonEklendi}
            />
          ) : (
            openTabs.map(tab => (
              <div key={tab.id} className={activeTab === tab.id ? 'block' : 'hidden'}>
                <SablonDuzenleyici
                  sablon={tab.sablon}
                  onKaydet={handleSablonKaydet}
                  onIptal={() => closeTab(tab.id)}
                  kategoriler={kategoriYapisi}
                  yeniSablon={tab.sablon.yeniSablon}
                  tumKanunMaddeleri={tumKanunMaddeleri.map(madde => typeof madde === 'string' ? madde : madde.value)}
                  tumEtiketler={tumEtiketler.map(etiket => typeof etiket === 'string' ? etiket : etiket.value)}
                  handleSelectChange={handleSelectChange}
                  onAIEnhance={(aiSuggestions) => handleAIEnhance(tab.sablon.id, aiSuggestions)}
                />
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default KararSablonlari;
