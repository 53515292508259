import React from 'react';

function formatSure(sure) {
  if (typeof sure === 'number') {
    const tamYil = Math.floor(sure);
    const kalanAy = Math.floor((sure - tamYil) * 12);
    const kalanGun = Math.round(((sure - tamYil) * 12 - kalanAy) * 30);
    
    const parcalar = [];
    if (tamYil > 0) parcalar.push(`${tamYil} yıl`);
    if (kalanAy > 0) parcalar.push(`${kalanAy} ay`);
    if (kalanGun > 0) parcalar.push(`${kalanGun} gün`);
    
    return parcalar.join(' ');
  }

  // Eğer sure bir obje ise (year, month, day)
  const parcalar = [];
  if (sure.year > 0) parcalar.push(`${sure.year} yıl`);
  if (sure.month > 0) parcalar.push(`${sure.month} ay`);
  if (sure.day > 0) parcalar.push(`${sure.day} gün`);
  
  return parcalar.join(' ');
}

function SonucGosterimi({ sonuc }) {
  if (!sonuc) return null;

  const formatTarih = (tarih) => {
    return new Date(tarih).toLocaleDateString('tr-TR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  return (
    <div className="mt-8 bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="bg-indigo-500 px-6 py-4">
        <h2 className="text-2xl font-bold text-white">Zamanaşımı Sonuçları</h2>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="relative bg-gradient-to-br from-blue-50 to-white rounded-lg p-6 border border-blue-100">
            <div className="absolute top-0 right-0 mt-4 mr-4">
              <svg className="w-8 h-8 text-blue-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Normal Zamanaşımı</h3>
            <p className="text-sm text-gray-600 mb-3">Normal şartlarda zamanaşımı tarihi:</p>
            <p className="text-xl font-bold text-blue-600">{formatTarih(sonuc.olaganZamanAsimi)}</p>
          </div>

          <div className="relative bg-gradient-to-br from-purple-50 to-white rounded-lg p-6 border border-purple-100">
            <div className="absolute top-0 right-0 mt-4 mr-4">
              <svg className="w-8 h-8 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Olağanüstü Zamanaşımı</h3>
            <p className="text-sm text-gray-600 mb-3">En son zamanaşımı tarihi:</p>
            <p className="text-xl font-bold text-purple-600">{formatTarih(sonuc.olaganustuZamanAsimi)}</p>
          </div>
        </div>

        <div className="space-y-4">
          <div className="bg-green-50 rounded-lg p-4 border border-green-100">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div className="ml-4">
                <h4 className="text-sm font-medium text-green-800">Kalan Süre</h4>
                <p className="text-lg font-semibold text-green-900">{sonuc.kalanGun} gün</p>
              </div>
            </div>
          </div>

          {sonuc.toplamDurdurmaSuresi > 0 && (
            <div className="bg-gradient-to-br from-yellow-50 to-white rounded-lg p-6 border border-yellow-100">
              <div className="flex items-start justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <div className="bg-yellow-100 rounded-full p-3">
                      <svg className="w-6 h-6 text-yellow-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </div>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-semibold text-gray-800">Durduran Sebepler Etkisi</h4>
                    <p className="text-sm text-gray-600 mt-1">
                      Zamanaşımı süresine eklenecek toplam durma süresi
                    </p>
                  </div>
                </div>
                <div className="bg-yellow-100 rounded-full px-3 py-1">
                  <span className="text-sm font-medium text-yellow-800">
                    +{sonuc.toplamDurdurmaSuresi} gün
                  </span>
                </div>
              </div>

              <div className="mt-4 grid grid-cols-3 gap-4">
                <div className="bg-white rounded-lg p-3 border border-yellow-100 text-center">
                  <span className="text-2xl font-bold text-yellow-700">
                    {Math.floor(sonuc.toplamDurdurmaSuresi / 365)}
                  </span>
                  <p className="text-xs text-gray-600 mt-1">Yıl</p>
                </div>
                <div className="bg-white rounded-lg p-3 border border-yellow-100 text-center">
                  <span className="text-2xl font-bold text-yellow-700">
                    {Math.floor((sonuc.toplamDurdurmaSuresi % 365) / 30)}
                  </span>
                  <p className="text-xs text-gray-600 mt-1">Ay</p>
                </div>
                <div className="bg-white rounded-lg p-3 border border-yellow-100 text-center">
                  <span className="text-2xl font-bold text-yellow-700">
                    {Math.floor(sonuc.toplamDurdurmaSuresi % 30)}
                  </span>
                  <p className="text-xs text-gray-600 mt-1">Gün</p>
                </div>
              </div>

              <div className="mt-4 bg-yellow-50 rounded-lg p-3">
                <div className="flex items-start">
                  <svg className="w-5 h-5 text-yellow-600 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <p className="ml-3 text-sm text-yellow-800">
                    Durduran sebepler süreleri zamanaşımı süresinin sonuna eklenir ve 
                    bu süre içinde zamanaşımı işlemez.
                  </p>
                </div>
              </div>
            </div>
          )}

          {sonuc.yasGrubuOrani !== 1 && (
            <div className="bg-orange-50 rounded-lg p-4 border border-orange-100">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="w-6 h-6 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                </div>
                <div className="ml-4">
                  <h4 className="text-sm font-medium text-orange-800">Yaş Grubu Etkisi</h4>
                  <p className="text-lg font-semibold text-orange-900">
                    Zamanaşımı süresi {
                      sonuc.yasGrubuBilgisi.oran.rate.numerator === sonuc.yasGrubuBilgisi.oran.rate.denominator ? 'tam' :
                      `${sonuc.yasGrubuBilgisi.oran.rate.numerator}/${sonuc.yasGrubuBilgisi.oran.rate.denominator}`
                    } oranında uygulanmıştır
                  </p>
                  <div className="mt-3 grid grid-cols-2 gap-4">
                    <div className="bg-white rounded-lg p-3 border border-orange-100">
                      <p className="text-xs text-orange-600 mb-1">Normal Süre</p>
                      <p className="text-sm font-medium text-gray-900">
                        {formatSure(sonuc.yasGrubuBilgisi.normalSure)}
                      </p>
                    </div>
                    <div className="bg-white rounded-lg p-3 border border-orange-100">
                      <p className="text-xs text-orange-600 mb-1">Yaş Grubuna Göre Süre</p>
                      <p className="text-sm font-medium text-gray-900">
                        {formatSure(sonuc.yasGrubuBilgisi.ayarlanmisSure)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mt-6 bg-gray-50 rounded-lg p-4 border border-gray-200">
          <div className="flex items-start">
            <div className="flex-shrink-0 mt-0.5">
              <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <p className="ml-3 text-sm text-gray-600">
              Olağanüstü zamanaşımı süresi, normal zamanaşımı süresinin bir buçuk katıdır ve 
              hiçbir durumda bu süre aşılamaz. Kesen sebepler olağanüstü zamanaşımı süresini geçemez.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SonucGosterimi;

