import React, { useState, useEffect } from 'react';

const TemyizTalebiKarti = ({ talep, onGuncelle, onSil }) => {
  const [duzenlemeModu, setDuzenlemeModu] = useState(false);
  const [geciciTalep, setGeciciTalep] = useState(talep);

  const tebligTurleri = [
    "Yüze karşı",
    "Ceza infaz kurumu tebliği",
    "Normal tebliğ",
    "Diğer"
  ];

  const kaydet = () => {
    onGuncelle(geciciTalep);
    setDuzenlemeModu(false);
  };

  const tebligTuruRengi = (tur) => {
    switch(tur) {
      case "Yüze karşı": return "bg-blue-500";
      case "Ceza infaz kurumu tebliği": return "bg-purple-500";
      case "Normal tebliğ": return "bg-green-500";
      default: return "bg-gray-500";
    }
  };

  const formatTarih = (tarih) => {
    if (!tarih) return '';
    const [yil, ay, gun] = tarih.split('-');
    return `${gun}/${ay}/${yil}`;
  };

  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 p-2">
      <div 
        className="bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl relative group border border-gray-200"
        onClick={() => setDuzenlemeModu(true)}
      >
        {!duzenlemeModu ? (
          <div className="relative p-5">
            <span className={`absolute top-0 right-0 ${tebligTuruRengi(talep.tebligTuru)} text-white text-xs font-bold px-2 py-1 rounded-bl-lg`}>
              {talep.tebligTuru === "Diğer" ? talep.digerTebligTuru : talep.tebligTuru}
            </span>
            <h3 className="text-xl font-semibold mb-2 pr-20">
              {talep.talepEden}
              {talep.isim && <span className="text-sm font-normal ml-2">({talep.isim})</span>}
            </h3>
            <div className="text-sm text-gray-600">
              {talep.tebligTarihi && <p>Tebliğ: {formatTarih(talep.tebligTarihi)}</p>}
              {talep.temyizTarihi && <p>Temyiz: {formatTarih(talep.temyizTarihi)}</p>}
            </div>
            <button
              className="absolute bottom-2 left-2 text-red-500 hover:text-red-700 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
                onSil(talep.id);
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        ) : (
          <div className="p-5 space-y-3" onClick={(e) => e.stopPropagation()}>
            <select
              value={geciciTalep.talepEden}
              onChange={(e) => setGeciciTalep({...geciciTalep, talepEden: e.target.value})}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Talep Eden Seçiniz</option>
              {["Sanık", "Katılan", "SSÇ", "Cumhuriyet Savcısı", "Suçtan Zarar Gören"].map((secenek) => (
                <option key={secenek} value={secenek}>{secenek}</option>
              ))}
            </select>
            <input
              type="text"
              value={geciciTalep.isim || ''}
              onChange={(e) => setGeciciTalep({...geciciTalep, isim: e.target.value})}
              placeholder="İsim"
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <select
              value={geciciTalep.tebligTuru}
              onChange={(e) => setGeciciTalep({...geciciTalep, tebligTuru: e.target.value})}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Tebliğ Türü Seçiniz</option>
              {tebligTurleri.map((tur) => (
                <option key={tur} value={tur}>{tur}</option>
              ))}
            </select>
            {geciciTalep.tebligTuru === "Diğer" && (
              <input
                type="text"
                value={geciciTalep.digerTebligTuru || ''}
                onChange={(e) => setGeciciTalep({...geciciTalep, digerTebligTuru: e.target.value})}
                placeholder="Diğer tebliğ türünü giriniz"
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            )}
            <input
              type="date"
              value={geciciTalep.tebligTarihi}
              onChange={(e) => setGeciciTalep({...geciciTalep, tebligTarihi: e.target.value})}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="date"
              value={geciciTalep.temyizTarihi}
              onChange={(e) => setGeciciTalep({...geciciTalep, temyizTarihi: e.target.value})}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="flex justify-end space-x-2">
              <button onClick={kaydet} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300">Kaydet</button>
              <button onClick={() => setDuzenlemeModu(false)} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded transition duration-300">İptal</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const YeniTalepModal = ({ isOpen, onClose, onEkle }) => {
  const [yeniTalep, setYeniTalep] = useState({
    talepEden: "",
    isim: "",
    tebligTuru: "",
    digerTebligTuru: "",
    tebligTarihi: "",
    temyizTarihi: "",
  });

  const tebligTurleri = [
    "Yüze karşı",
    "Ceza infaz kurumu tebliği",
    "Normal tebliğ",
    "Diğer"
  ];

  const kaydet = () => {
    onEkle(yeniTalep);
    onClose();
    // Formu sıfırla
    setYeniTalep({
      talepEden: "",
      isim: "",
      tebligTuru: "",
      digerTebligTuru: "",
      tebligTarihi: "",
      temyizTarihi: "",
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Yeni Temyiz Talebi</h3>
        <div className="space-y-3">
          <select
            value={yeniTalep.talepEden}
            onChange={(e) => setYeniTalep({...yeniTalep, talepEden: e.target.value})}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Talep Eden Seçiniz</option>
            {["Sanık", "Katılan", "SSÇ", "Cumhuriyet Savcısı", "Suçtan Zarar Gören"].map((secenek) => (
              <option key={secenek} value={secenek}>{secenek}</option>
            ))}
          </select>
          <input
            type="text"
            value={yeniTalep.isim}
            onChange={(e) => setYeniTalep({...yeniTalep, isim: e.target.value})}
            placeholder="İsim"
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <select
            value={yeniTalep.tebligTuru}
            onChange={(e) => setYeniTalep({...yeniTalep, tebligTuru: e.target.value})}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Tebliğ Türü Seçiniz</option>
            {tebligTurleri.map((tur) => (
              <option key={tur} value={tur}>{tur}</option>
            ))}
          </select>
          {yeniTalep.tebligTuru === "Diğer" && (
            <input
              type="text"
              value={yeniTalep.digerTebligTuru}
              onChange={(e) => setYeniTalep({...yeniTalep, digerTebligTuru: e.target.value})}
              placeholder="Diğer tebliğ türünü giriniz"
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          )}
          <input
            type="date"
            value={yeniTalep.tebligTarihi}
            onChange={(e) => setYeniTalep({...yeniTalep, tebligTarihi: e.target.value})}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="date"
            value={yeniTalep.temyizTarihi}
            onChange={(e) => setYeniTalep({...yeniTalep, temyizTarihi: e.target.value})}
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="mt-4 flex justify-end">
          <button onClick={kaydet} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
            Ekle
          </button>
          <button onClick={onClose} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
            İptal
          </button>
        </div>
      </div>
    </div>
  );
};

const TemyizTalebindeBulunanlarCard = ({ info, onUpdate }) => {
  const [talepler, setTalepler] = useState(info || []);
  const [modalAcik, setModalAcik] = useState(false);

  useEffect(() => {
    if (info) {
      setTalepler(info);
    }
  }, [info]);

  const yeniTalepEkle = (yeniTalep) => {
    const talepWithId = { ...yeniTalep, id: Date.now().toString() };
    const yeniTalepler = [...talepler, talepWithId];
    setTalepler(yeniTalepler);
    onUpdate(yeniTalepler);
  };

  const talepGuncelle = (guncelTalep) => {
    const yeniTalepler = talepler.map(talep => 
      talep.id === guncelTalep.id ? guncelTalep : talep
    );
    setTalepler(yeniTalepler);
    onUpdate(yeniTalepler);
  };

  const talepSil = (id) => {
    const yeniTalepler = talepler.filter(talep => talep.id !== id);
    setTalepler(yeniTalepler);
    onUpdate(yeniTalepler);
  };

  return (
    <div className="w-full mb-4 bg-white shadow-md rounded-lg overflow-hidden">
      {/* <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Temyiz Talebinde Bulunanlar
        </h3>
      </div> */}
      <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
        <button 
          onClick={() => setModalAcik(true)}
          className="mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Yeni Temyiz Talebi Ekle
        </button>
        <div className="flex flex-wrap -mx-2">
          {talepler.map((talep) => (
            <TemyizTalebiKarti 
              key={talep.id} 
              talep={talep} 
              onGuncelle={talepGuncelle} 
              onSil={talepSil} 
            />
          ))}
        </div>
      </div>
      <YeniTalepModal 
        isOpen={modalAcik}
        onClose={() => setModalAcik(false)}
        onEkle={yeniTalepEkle}
      />
    </div>
  );
};

export default TemyizTalebindeBulunanlarCard;