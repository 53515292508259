import Select from 'react-select';

export class Kategori {
  constructor(id, isim, altKategoriler = []) {
    this.id = id;
    this.isim = isim;
    this.altKategoriler = altKategoriler.map(alt => 
      alt instanceof Kategori ? alt : new Kategori(alt.id, alt.isim, alt.altKategoriler)
    );
  }

  toSelectOption() {
    return {
      value: this.id ? this.id.toString() : '',
      label: this.isim || ''
    };
  }

  static fromSelectOption(option) {
    return new Kategori(option.value ? parseInt(option.value) : null, option.label);
  }

  static flattenKategoriler(kategoriler) {
    let flatList = [];
    for (const kategori of kategoriler) {
      if (kategori instanceof Kategori) {
        flatList.push(kategori.toSelectOption());
      } else {
        flatList.push({ value: kategori.id.toString(), label: kategori.isim });
      }
      if (kategori.altKategoriler && kategori.altKategoriler.length > 0) {
        flatList = flatList.concat(Kategori.flattenKategoriler(kategori.altKategoriler));
      }
    }
    return flatList;
  }
}

export const KategoriSecici = ({ kategoriler, secilenKategoriler, onChange }) => {
  const options = Kategori.flattenKategoriler(kategoriler);

  const value = secilenKategoriler
    .filter(kategori => kategori && kategori.id && kategori.isim)
    .map(kategori => ({
      value: kategori.id.toString(),
      label: kategori.isim
    }));

  const handleChange = (selectedOptions) => {
    const updatedKategoriler = selectedOptions.map(option => 
      new Kategori(parseInt(option.value), option.label)
    );
    onChange(updatedKategoriler);
  };

  return (
    <Select
      isMulti
      options={options}
      value={value}
      onChange={handleChange}
      className="basic-multi-select"
      classNamePrefix="select"
    />
  );
};
