import React from 'react';
import SablonKarti from './SablonKarti';

const SablonListesi = ({ sablonlar, kategoriler, onDuzenle, onSil }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {sablonlar.map(sablon => (
        <SablonKarti 
          key={sablon.id} 
          sablon={sablon} 
          kategoriler={kategoriler}
          onClick={() => onDuzenle(sablon)} 
          onDelete={() => onSil(sablon.id)} 
        />
      ))}
    </div>
  );
};

export default SablonListesi;
