import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const CollapsiblePanel = React.memo(({ title, isOpen, setIsOpen, children }) => {
  return (
    <div className="border rounded-lg overflow-visible">
      <button
        className="w-full px-4 py-2 bg-gray-100 flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold">{title}</h3>
        {isOpen ? (
          <ChevronUp className="w-5 h-5" />
        ) : (
          <ChevronDown className="w-5 h-5" />
        )}
      </button>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
});

export default CollapsiblePanel;