import React from 'react';
import { tarihFarki } from './tarihHesaplamalari';

function SebepKarti({ sebep, onSil }) {
  const formatTarih = (tarih) => {
    return new Date(tarih).toLocaleDateString('tr-TR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  // Eğer durduran sebep ise (başlangıç ve bitiş tarihi varsa)
  const isDurduranSebep = sebep.baslangic && sebep.bitis;
  
  // Durduran sebep için süre hesaplama
  const hesaplaSure = () => {
    if (!isDurduranSebep) return null;
    const { yil, ay, gun } = tarihFarki(new Date(sebep.baslangic), new Date(sebep.bitis));
    return { yil, ay, gun };
  };

  const sure = hesaplaSure();

  return (
    <div className="bg-gradient-to-br from-gray-50 to-white rounded-xl shadow-md hover:shadow-lg transition-all duration-200 border border-gray-100">
      <div className="p-4 relative">
        {/* Üst Kısım - Tip ve Silme Butonu */}
        <div className="flex justify-between items-start mb-3">
          <div className="flex items-center">
            <div className={`rounded-full p-2 ${
              isDurduranSebep 
                ? 'bg-blue-100/80 text-blue-600' 
                : 'bg-green-100/80 text-green-600'
            }`}>
              {isDurduranSebep ? (
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              ) : (
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              )}
            </div>
            <div className="ml-3">
              <h3 className={`font-medium ${
                isDurduranSebep ? 'text-blue-900' : 'text-green-900'
              }`}>
                {sebep.tip}
              </h3>
            </div>
          </div>
          <button
            onClick={onSil}
            className="text-gray-400 hover:text-red-500 transition-colors duration-200 p-1 hover:bg-red-50 rounded-full"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </button>
        </div>

        {/* Tarih Bilgileri */}
        <div className={`grid ${isDurduranSebep ? 'grid-cols-2' : 'grid-cols-1'} gap-4 mb-3`}>
          {isDurduranSebep ? (
            <>
              <div className="bg-white rounded-lg p-2 border border-gray-100 shadow-sm">
                <p className="text-xs text-gray-500 mb-1">Başlangıç</p>
                <p className="text-sm font-medium text-gray-700">{formatTarih(sebep.baslangic)}</p>
              </div>
              <div className="bg-white rounded-lg p-2 border border-gray-100 shadow-sm">
                <p className="text-xs text-gray-500 mb-1">Bitiş</p>
                <p className="text-sm font-medium text-gray-700">{formatTarih(sebep.bitis)}</p>
              </div>
            </>
          ) : (
            <div className="bg-white rounded-lg p-2 border border-gray-100 shadow-sm">
              <p className="text-xs text-gray-500 mb-1">Tarih</p>
              <p className="text-sm font-medium text-gray-700">{formatTarih(sebep.tarih)}</p>
            </div>
          )}
        </div>

        {/* Süre Gösterimi (Sadece durduran sebepler için) */}
        {isDurduranSebep && sure && (
          <div className="border-t border-gray-100 pt-3 mt-3">
            <p className="text-xs text-gray-500 mb-2">Toplam Süre</p>
            <div className="flex space-x-3">
              {sure.yil > 0 && (
                <div className="bg-white border border-blue-100 rounded-lg px-3 py-1 shadow-sm">
                  <span className="text-sm font-semibold text-blue-700">{sure.yil}</span>
                  <span className="text-xs text-blue-600 ml-1">yıl</span>
                </div>
              )}
              {sure.ay > 0 && (
                <div className="bg-white border border-blue-100 rounded-lg px-3 py-1 shadow-sm">
                  <span className="text-sm font-semibold text-blue-700">{sure.ay}</span>
                  <span className="text-xs text-blue-600 ml-1">ay</span>
                </div>
              )}
              {sure.gun > 0 && (
                <div className="bg-white border border-blue-100 rounded-lg px-3 py-1 shadow-sm">
                  <span className="text-sm font-semibold text-blue-700">{sure.gun}</span>
                  <span className="text-xs text-blue-600 ml-1">gün</span>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Açıklama */}
        {sebep.aciklama && (
          <div className="mt-3 text-sm text-gray-600 bg-white rounded-lg p-2 border border-gray-100 shadow-sm">
            {sebep.aciklama}
          </div>
        )}

        {/* Toplam Gün Sayısı - Sağ Alt Köşe */}
        {isDurduranSebep && (
          <div className="absolute bottom-2 right-4 text-xs text-gray-400 italic">
            ({tarihFarki(new Date(sebep.baslangic), new Date(sebep.bitis)).gunSayisi} gün)
          </div>
        )}
      </div>
    </div>
  );
}

export default SebepKarti;

