import React, { useState } from 'react';
import { hesaplaZamanAsimi } from '../zamanasimi/zamanAsimiHesaplayici';
import TarihGirisi from '../zamanasimi/TarihGirisi';
import ZamanAsimiSuresiSecimi from '../zamanasimi/ZamanAsimiSuresiSecimi.js';
import DurduranSebepModal from '../zamanasimi/DurduranSebepModal.js';
import KesenSebepModal from '../zamanasimi/KesenSebepModal.js';
import SebepKarti from '../zamanasimi/SebepKarti.js';
import SonucGosterimi from '../zamanasimi/SonucGosterimi.js';
import YasGrubuSecimi from '../zamanasimi/YasGrubuSecimi';
import ZamanAsimiTimeline from '../zamanasimi/ZamanAsimiTimeline';

function ZamanAsimi() {
  const [sucTarihi, setSucTarihi] = useState(new Date());
  const [zamanAsimiSuresi, setZamanAsimiSuresi] = useState(8);
  const [durduranSebepler, setDurduranSebepler] = useState([]);
  const [kesenSebepler, setKesenSebepler] = useState([]);
  const [sonuc, setSonuc] = useState(null);
  const [durduranModalAcik, setDurduranModalAcik] = useState(false);
  const [kesenModalAcik, setKesenModalAcik] = useState(false);
  const [dogumTarihi, setDogumTarihi] = useState(null);
  const [manuelYasGrubu, setManuelYasGrubu] = useState(false);
  const [secilenYasGrubu, setSecilenYasGrubu] = useState('yetiskin');
  const [yurtDisiSuc, setYurtDisiSuc] = useState(false);
  const [sucNiteligi, setSucNiteligi] = useState('normal');
  const [cocugaKarsiSuc, setCocugaKarsiSuc] = useState(false);

  const hesapla = () => {
    let hesaplamaIcinYasGrubu = secilenYasGrubu;
    
    if (!manuelYasGrubu && dogumTarihi) {
      const sucTarihindekiYas = Math.floor(
        (sucTarihi - dogumTarihi) / (1000 * 60 * 60 * 24 * 365.25)
      );
      
      if (sucTarihindekiYas < 15) {
        hesaplamaIcinYasGrubu = '12-15';
      } else if (sucTarihindekiYas < 18) {
        hesaplamaIcinYasGrubu = '15-18';
      } else {
        hesaplamaIcinYasGrubu = 'yetiskin';
      }
    }

    const hesaplananSonuc = hesaplaZamanAsimi(
      sucTarihi, 
      zamanAsimiSuresi, 
      durduranSebepler, 
      kesenSebepler,
      hesaplamaIcinYasGrubu,
      yurtDisiSuc,
      sucNiteligi,
      cocugaKarsiSuc
    );
    setSonuc(hesaplananSonuc);
  };

  const hesaplananYasGrubu = React.useMemo(() => {
    if (!dogumTarihi || manuelYasGrubu) return null;
    
    const sucTarihindekiYas = Math.floor(
      (sucTarihi - dogumTarihi) / (1000 * 60 * 60 * 24 * 365.25)
    );
    
    if (sucTarihindekiYas < 15) return '12-15';
    if (sucTarihindekiYas < 18) return '15-18';
    return 'yetiskin';
  }, [dogumTarihi, sucTarihi, manuelYasGrubu]);

  const ekleKesenSebep = (yeniSebep) => {
    setKesenSebepler([...kesenSebepler, yeniSebep]);
    setKesenModalAcik(false);
  };

  const ekleDurduranSebep = (yeniSebep) => {
    setDurduranSebepler([...durduranSebepler, yeniSebep]);
    setDurduranModalAcik(false);
  };

  const silKesenSebep = (index) => {
    setKesenSebepler(kesenSebepler.filter((_, i) => i !== index));
  };

  const silDurduranSebep = (index) => {
    setDurduranSebepler(durduranSebepler.filter((_, i) => i !== index));
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="container mx-auto px-4 py-8">
        {/* Başlık */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            Ceza Zamanaşımı Hesaplama
          </h1>
          <p className="text-gray-600">
            TCK'ya göre dava zamanaşımı süresini hesaplayın
          </p>
        </div>

        {/* Ana İçerik */}
        <div className="max-w-6xl mx-auto">
          {/* Temel Bilgiler Kartları */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {/* Yaş Bilgileri Kartı - Yeni Tasarım */}
            <div className="bg-gradient-to-br from-green-50 to-white rounded-xl shadow-lg overflow-hidden">
              <div className="bg-green-500 px-6 py-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className="bg-white/20 backdrop-blur-sm rounded-full p-2">
                      <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                      </svg>
                    </div>
                    <h2 className="text-xl font-semibold text-white ml-3">Yaş Bilgileri</h2>
                  </div>
                  <button
                    type="button"
                    onClick={() => setManuelYasGrubu(!manuelYasGrubu)}
                    className={`
                      relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent 
                      transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-white/50
                      ${manuelYasGrubu ? 'bg-white' : 'bg-white/30'}
                    `}
                  >
                    <span
                      className={`
                        pointer-events-none inline-block h-5 w-5 transform rounded-full shadow ring-0 
                        transition duration-200 ease-in-out
                        ${manuelYasGrubu ? 'translate-x-5 bg-green-500' : 'translate-x-0 bg-white'}
                      `}
                    />
                  </button>
                </div>
              </div>
              <div className="p-6">
                <YasGrubuSecimi
                  dogumTarihi={dogumTarihi}
                  onDogumTarihiChange={setDogumTarihi}
                  sucTarihi={sucTarihi}
                  manuelYasGrubu={manuelYasGrubu}
                  secilenYasGrubu={secilenYasGrubu}
                  onYasGrubuChange={setSecilenYasGrubu}
                  otomatikYasGrubu={hesaplananYasGrubu}
                />
              </div>
            </div>

            {/* Suç Tarihi Kartı - Yeni Tasarım */}
            <div className="bg-gradient-to-br from-blue-50 to-white rounded-xl shadow-lg overflow-hidden">
              <div className="bg-blue-500 px-6 py-4">
                <div className="flex items-center">
                  <div className="bg-white/20 backdrop-blur-sm rounded-full p-2">
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                  </div>
                  <h2 className="text-xl font-semibold text-white ml-3">Suç Tarihi</h2>
                </div>
              </div>
              <div className="p-6">
                <TarihGirisi 
                  label="Suçun İşlendiği Tarih" 
                  value={sucTarihi} 
                  onChange={setSucTarihi}
                />
              </div>
            </div>

            {/* Zamanaşımı Süresi Kartı - Yeni Tasarım */}
            <div className="bg-gradient-to-br from-purple-50 to-white rounded-xl shadow-lg overflow-hidden">
              <div className="bg-purple-500 px-6 py-4">
                <div className="flex items-center">
                  <div className="bg-white/20 backdrop-blur-sm rounded-full p-2">
                    <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h2 className="text-xl font-semibold text-white ml-3">Zamanaşımı Süresi</h2>
                </div>
              </div>
              <div className="p-6">
                <ZamanAsimiSuresiSecimi 
                  value={zamanAsimiSuresi} 
                  onChange={setZamanAsimiSuresi}
                />
              </div>
            </div>
          </div>

          {/* Sebepler Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            {/* Durduran Sebepler */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-800">
                  Durduran Sebepler
                </h2>
                <button 
                  onClick={() => setDurduranModalAcik(true)}
                  className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium rounded-md transition-colors duration-150 ease-in-out"
                >
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  Ekle
                </button>
              </div>
              <div className="space-y-4">
                {durduranSebepler.map((sebep, index) => (
                  <SebepKarti 
                    key={index} 
                    sebep={sebep} 
                    onSil={() => silDurduranSebep(index)}
                  />
                ))}
                {durduranSebepler.length === 0 && (
                  <p className="text-gray-500 text-center py-4">
                    Henüz durduran sebep eklenmemiş
                  </p>
                )}
              </div>
            </div>

            {/* Kesen Sebepler */}
            <div className="bg-white rounded-xl shadow-lg p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-800">
                  Kesen Sebepler
                </h2>
                <button 
                  onClick={() => setKesenModalAcik(true)}
                  className="inline-flex items-center px-4 py-2 bg-green-600 hover:bg-green-700 text-white text-sm font-medium rounded-md transition-colors duration-150 ease-in-out"
                >
                  <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  Ekle
                </button>
              </div>
              <div className="space-y-4">
                {kesenSebepler.map((sebep, index) => (
                  <SebepKarti 
                    key={index} 
                    sebep={sebep} 
                    onSil={() => silKesenSebep(index)}
                  />
                ))}
                {kesenSebepler.length === 0 && (
                  <p className="text-gray-500 text-center py-4">
                    Henüz kesen sebep eklenmemiş
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Hesapla Butonu */}
          <div className="text-center mb-8">
            <button 
              onClick={hesapla}
              className="inline-flex items-center px-8 py-3 bg-indigo-600 hover:bg-indigo-700 text-white text-lg font-medium rounded-lg transition-colors duration-150 ease-in-out shadow-lg hover:shadow-xl"
            >
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
              </svg>
              Hesapla
            </button>
          </div>

          {/* Sonuç */}
          <SonucGosterimi sonuc={sonuc} />
          
          {/* Timeline Gösterimi */}
          {sonuc && (
            <ZamanAsimiTimeline 
              sonuc={sonuc}
              sucTarihi={sucTarihi}
              durduranSebepler={durduranSebepler}
              kesenSebepler={kesenSebepler}
              zamanAsimiSuresi={zamanAsimiSuresi}
              ayarlanmisSure={sonuc?.yasGrubuBilgisi?.ayarlanmisSure}
              toplamDurmaSuresi={sonuc?.toplamDurdurmaSuresi}
            />
          )}
        </div>
      </div>

      {/* Modaller */}
      <DurduranSebepModal 
        acik={durduranModalAcik} 
        kapat={() => setDurduranModalAcik(false)} 
        ekle={ekleDurduranSebep}
      />
      <KesenSebepModal 
        acik={kesenModalAcik} 
        kapat={() => setKesenModalAcik(false)} 
        ekle={ekleKesenSebep}
      />
    </div>
  );
}

export default ZamanAsimi;
