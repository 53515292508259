import React, { useState, lazy, Suspense } from 'react';
import { Etiketleme, KanunMaddeleri } from './duzenleme/Etiketleme';
import AIEnhancer from './duzenleme/AIEnhancer';
import { KategoriSecici, Kategori } from './Kategorilendirme';
import { toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';

const ReactQuill = lazy(() => import('react-quill'));

const YeniSablonEkle = ({
  kategoriler,
  tumEtiketler,
  tumKanunMaddeleri,
  onKaydet,
  onIptal,
  onSablonEklendi
}) => {
  const [sablon, setSablon] = useState({
    baslik: '',
    ozet: '',
    icerik: '',
    etiketler: [],
    kanun_maddeleri: [],
    kategoriler: []
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSablon(prev => ({ ...prev, [name]: value }));
  };

  const handleIcerikChange = (icerik) => {
    setSablon(prev => ({ ...prev, icerik }));
  };

  const handleSelectChange = (field, selectedOptions) => {
    if (field === 'kategoriler') {
      const updatedKategoriler = selectedOptions
        .filter(option => option instanceof Kategori && option.id && option.isim)
        .map(option => new Kategori(option.id, option.isim));
      console.log('Güncellenmiş kategoriler:', updatedKategoriler);
      setSablon(prev => ({ ...prev, [field]: updatedKategoriler }));
    } else {
      setSablon(prev => ({
        ...prev,
        [field]: Array.isArray(selectedOptions) ? selectedOptions.filter(Boolean) : [selectedOptions].filter(Boolean)
      }));
    }
  };

  const handleAIEnhance = ({ ozet, baslik, etiketler, kanunMaddeleri }) => {
    setSablon(prev => ({
      ...prev,
      ozet,
      baslik,
      etiketler: [...new Set([...prev.etiketler, ...etiketler])],
      kanun_maddeleri: [...new Set([...prev.kanun_maddeleri, ...kanunMaddeleri])]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Gönderilmeden önce sablon:', sablon);
      
      if (!sablon.kategoriler || sablon.kategoriler.length === 0) {
        throw new Error('En az bir kategori seçilmelidir.');
      }

      const sablonToSave = {
        ...sablon,
        kategoriler: sablon.kategoriler
          .filter(kategori => kategori instanceof Kategori && typeof kategori.id === 'number' && kategori.isim)
          .map(kategori => ({
            id: kategori.id,
            isim: kategori.isim
          }))
      };
      
      console.log('Kaydedilecek sablon:', sablonToSave);
      
      const yeniSablon = await onKaydet(sablonToSave);
      if (yeniSablon) {
        onSablonEklendi(yeniSablon);
        toast.success('Yeni şablon başarıyla eklendi.');
        onIptal();
      } else {
        throw new Error('Şablon eklenemedi');
      }
    } catch (error) {
      console.error('Şablon eklenirken hata:', error);
      toast.error(`Şablon eklenirken bir hata oluştu: ${error.message}`);
    }
  };

  return (
    <div className="flex flex-col bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-gray-800 border-b p-6">Yeni Şablon Ekle</h2>
      
      <form onSubmit={handleSubmit} className="flex flex-col p-6 space-y-6">
        <div>
          <label htmlFor="baslik" className="block text-sm font-medium text-gray-700 mb-1">Başlık</label>
          <input
            type="text"
            id="baslik"
            name="baslik"
            value={sablon.baslik}
            onChange={handleChange}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 border p-2"
          />
        </div>

        <div>
          <label htmlFor="ozet" className="block text-sm font-medium text-gray-700 mb-1">Özet</label>
          <textarea
            id="ozet"
            name="ozet"
            value={sablon.ozet}
            onChange={handleChange}
            rows="3"
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 border p-2"
          />
        </div>

        <div className="space-y-2 relative z-10">
          <label htmlFor="icerik" className="block text-sm font-medium text-gray-700">İçerik</label>
          <div className="bg-gray-50 p-4 rounded-lg shadow-inner border border-gray-300">
            <div className="h-[calc(100vh-450px)] min-h-[300px] overflow-hidden">
              <Suspense fallback={<div>Yükleniyor...</div>}>
                <ReactQuill
                  value={sablon.icerik}
                  onChange={handleIcerikChange}
                  className="h-full pb-12"
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, 3, false] }],
                      ['bold', 'italic', 'underline'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['link', 'image'],
                      ['clean']
                    ],
                  }}
                  ref={(el) => {
                    if (el) {
                      el.getEditor().root.setAttribute('data-gramm', 'false');
                    }
                  }}
                />
              </Suspense>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 relative z-50">
          <div className="relative z-50">
            <Etiketleme
              etiketler={sablon.etiketler}
              onChange={(yeniEtiketler) => handleSelectChange('etiketler', yeniEtiketler)}
              kayitliEtiketler={tumEtiketler}
            />
          </div>
          <div className="relative z-50">
            <KanunMaddeleri
              kanunMaddeleri={sablon.kanun_maddeleri}
              onChange={(yeniKanunMaddeleri) => handleSelectChange('kanun_maddeleri', yeniKanunMaddeleri)}
              kayitliKanunMaddeleri={tumKanunMaddeleri}
            />
          </div>
        </div>

        <div className="relative z-40">
          <label className="block text-sm font-medium text-gray-700 mb-1">Kategoriler</label>
          <KategoriSecici
            kategoriler={kategoriler}
            secilenKategoriler={sablon.kategoriler}
            onChange={(selectedOptions) => handleSelectChange('kategoriler', selectedOptions)}
          />
        </div>
      </form>

      <div className="flex justify-between items-center p-6 border-t bg-gray-50 relative z-30">
        <AIEnhancer
          icerik={sablon.icerik}
          onEnhance={handleAIEnhance}
          tumEtiketler={tumEtiketler}
          tumKanunMaddeleri={tumKanunMaddeleri}
        />
        <div className="space-x-2">
          <button
            type="button"
            onClick={onIptal}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition"
          >
            İptal
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default YeniSablonEkle;
