import React from 'react';

const zamanAsimiSureleri = [
  { 
    label: '30 Yıl',
    value: 30,
    description: 'Ağırlaştırılmış müebbet hapis cezasını gerektiren suçlarda'
  },
  { 
    label: '25 Yıl',
    value: 25,
    description: 'Müebbet hapis cezasını gerektiren suçlarda'
  },
  { 
    label: '20 Yıl',
    value: 20,
    description: 'Yirmi yıldan aşağı olmamak üzere hapis cezasını gerektiren suçlarda'
  },
  { 
    label: '15 Yıl',
    value: 15,
    description: 'Beş yıldan fazla ve yirmi yıldan az hapis cezasını gerektiren suçlarda'
  },
  { 
    label: '8 Yıl',
    value: 8,
    description: 'Beş yıldan fazla olmamak üzere hapis veya adlî para cezasını gerektiren suçlarda'
  }
];

function ZamanAsimiSuresiSecimi({ value, onChange }) {
  return (
    <div className="mb-6">
      <label className="block text-gray-700 text-sm font-bold mb-2">
        Zamanaşımı Süresi
      </label>
      <div className="relative">
        <select
          value={value}
          onChange={(e) => onChange(Number(e.target.value))}
          className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {zamanAsimiSureleri.map((sure) => (
            <option key={sure.value} value={sure.value}>
              {sure.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
          </svg>
        </div>
      </div>
      {zamanAsimiSureleri.find(sure => sure.value === value)?.description && (
        <p className="mt-2 text-sm text-gray-600">
          {zamanAsimiSureleri.find(sure => sure.value === value).description}
        </p>
      )}
    </div>
  );
}

export default ZamanAsimiSuresiSecimi;

