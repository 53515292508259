import React, { useState, useMemo, useCallback } from 'react';
import { ChevronDownIcon, ChevronRightIcon, FolderIcon, MagnifyingGlassIcon, MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import YeniKategoriModal from './YeniKategoriModal.js';
import KategoriKlasorGorunumu from './KategoriKlasorGorunumu.js';
import { turkishToLower } from '../../utils/stringUtils';
import { toast } from 'react-toastify';
import { handleKategoriEkleVeyaGuncelle, silKategori } from './agac/kategoriIslemleri.js';
import KategoriContextMenu from './KategoriContextMenu.js'; // Yeni bileşeni import edin
import { ekleYeniSablon } from './sablonUtils';
import * as kategoriService from '../../services/kategoriService';
import { hesaplaKategoriSablonSayilari } from '../../utils/kategoriSablonSayaci';

const KategoriAgaci = ({ kategoriler, setKategoriler, secilenKategoriler, setSecilenKategoriler, onKategoriEkle, onYeniSablonEkle, goruntulenensablonlar = [], aramaMetni, setAramaMetni, secilenEtiketler, secilenKanunMaddeleri }) => {
  const [acikKategoriler, setAcikKategoriler] = useState({});
  const [modalAcik, setModalAcik] = useState(false);
  const [yeniKategoriParent, setYeniKategoriParent] = useState(null);
  const [duzenlenecekKategori, setDuzenlenecekKategori] = useState(null);
  const [ustKategoriler, setUstKategoriler] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [klasorGorunumu, setKlasorGorunumu] = useState(false);
  const [parentId, setParentId] = useState(null);

  const toggleKategori = (kategori) => {
    setAcikKategoriler(prev => ({...prev, [kategori]: !prev[kategori]}));
  };

  const tumAltKategorileriGetir = (kategori) => {
    let altKategoriler = [kategori.isim];
    if (kategori.altKategoriler) {
      kategori.altKategoriler.forEach(alt => {
        altKategoriler = altKategoriler.concat(tumAltKategorileriGetir(alt));
      });
    }
    return altKategoriler;
  };

  const handleKategoriSecim = (kategori, altKategoriler) => {
    setSecilenKategoriler(prev => {
      let yeniSecim = [...prev];
      const tumAltKategoriler = tumAltKategorileriGetir({ isim: kategori, altKategoriler });
      
      if (yeniSecim.includes(kategori)) {
        // Kategori ve tüm alt kategorileri kaldır
        yeniSecim = yeniSecim.filter(k => !tumAltKategoriler.includes(k));
      } else {
        // Kategori ve tüm alt kategorileri ekle
        tumAltKategoriler.forEach(k => {
          if (!yeniSecim.includes(k)) {
            yeniSecim.push(k);
          }
        });
      }
      return yeniSecim;
    });
  };

  const tumAltKategoriler = (kategoriler) => {
    return kategoriler.reduce((acc, kategori) => {
      acc.push(kategori.isim);
      return acc.concat(tumAltKategoriler(kategori.altKategoriler || []));
    }, []);
  };

  const handleKategoriSil = async (kategori) => {
    try {
      if (kategori.id) {
        const silmeBasarili = await kategoriService.deleteKategori(kategori.id);
        if (silmeBasarili) {
          const updatedKategoriler = await kategoriService.getAllKategoriler();
          setKategoriler(updatedKategoriler);
          setSecilenKategoriler(prev => prev.filter(k => k !== kategori.isim));

          const tumAltKategoriler = tumAltKategorileriGetir(kategori);
          setSecilenKategoriler(prev => prev.filter(k => !tumAltKategoriler.includes(k)));

          setAcikKategoriler(prev => {
            const yeniAcikKategoriler = { ...prev };
            delete yeniAcikKategoriler[kategori.isim];
            return yeniAcikKategoriler;
          });

          toast.success(`"${kategori.isim}" kategorisi başarıyla silindi.`);
        } else {
          throw new Error('Silme işlemi başarısız oldu');
        }
      } else {
        throw new Error(`"${kategori.isim}" kategorisi için ID bulunamadı.`);
      }
    } catch (error) {
      console.error('Kategori silinirken hata:', error);
      toast.error(`Kategori silinirken bir hata oluştu: ${error.message}`);
    }
  };

  const handleKategoriDuzenle = (kategori) => {
    setDuzenlenecekKategori(kategori.isim);
    setModalAcik(true);
  };

  const getUstKategoriler = (kategoriId, kategoriler) => {
    const yol = [];
    const bul = (id, liste) => {
      for (const kategori of liste) {
        if (kategori.id === id) {
          return [kategori.isim];
        }
        if (kategori.altKategoriler && kategori.altKategoriler.length > 0) {
          const altYol = bul(id, kategori.altKategoriler);
          if (altYol) {
            return [kategori.isim, ...altYol];
          }
        }
      }
      return null;
    };
    return bul(kategoriId, kategoriler) || [];
  };

  const handleYeniKategoriEkle = async (yeniKategoriAdi, parentId) => {
    try {
      if (!yeniKategoriAdi) {
        setModalAcik(true);
        setParentId(parentId);
        return;
      }
      const yeniKategori = await kategoriService.createKategori({ isim: yeniKategoriAdi, parent_id: parentId });
      const updatedKategoriler = await kategoriService.getAllKategoriler();
      setKategoriler(updatedKategoriler);
      return true;
    } catch (error) {
      console.error('Kategori eklenirken hata:', error);
      toast.error('Kategori eklenirken bir hata oluştu');
      return false;
    }
  };

  const handleContextMenu = useCallback((e, kategori) => {
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      kategori
    });
    setParentId(kategori.id);
    setUstKategoriler(getUstKategoriler(kategori.id, kategoriler));
  }, [kategoriler]);

  const closeContextMenu = useCallback(() => {
    setContextMenu(null);
  }, []);

  const sablonSayilari = useMemo(() => {
    return hesaplaKategoriSablonSayilari(
      kategoriler,
      goruntulenensablonlar,
      secilenKategoriler,
      secilenEtiketler,
      secilenKanunMaddeleri,
      aramaMetni
    );
  }, [kategoriler, goruntulenensablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, aramaMetni]);

  const renderKategori = (kategori, altKategoriler = [], derinlik = 0, ustKategoriler = []) => {
    const ikonBoyutu = 'h-5 w-5';
    const yaziBoyutu = 'text-sm';

    const isAramaEslesmesi = aramaMetni && turkishToLower(kategori.isim).includes(turkishToLower(aramaMetni));

    const handleKategoriClick = () => {
      toggleKategori(kategori.isim);
      console.log('Kategori ID:', kategori.id);
    };

    const [dogrudan, toplam] = sablonSayilari.get(kategori.isim) || [0, 0];
    console.log(`Kategori: ${kategori.isim}, Doğrudan: ${dogrudan}, Toplam: ${toplam}`);

    return (
      <div 
        key={`${ustKategoriler.join('-')}-${kategori.isim}`} 
        className={derinlik === 0 ? "" : "ml-0"}
      >
        <div 
          className="flex items-center group hover:bg-gray-100 rounded-lg p-2 transition-colors duration-150"
          onContextMenu={(e) => handleContextMenu(e, kategori)}
        >
          <input
            type="checkbox"
            checked={secilenKategoriler.includes(kategori.isim)}
            onChange={() => handleKategoriSecim(kategori.isim, altKategoriler)}
            className={`form-checkbox ${ikonBoyutu} text-blue-600 rounded focus:ring-blue-400 mr-2`}
          />
          <div 
            className={`flex items-center cursor-pointer ${
              secilenKategoriler.includes(kategori.isim) 
                ? 'text-blue-600 font-medium' 
                : isAramaEslesmesi 
                  ? 'text-green-600 font-medium'
                  : 'text-gray-700'
            }`}
            onClick={handleKategoriClick}
          >
            {altKategoriler.length > 0 ? (
              <button className="mr-1 focus:outline-none">
                {acikKategoriler[kategori.isim] 
                  ? <ChevronDownIcon className={`${ikonBoyutu} text-gray-400`} /> 
                  : <ChevronRightIcon className={`${ikonBoyutu} text-gray-400`} />
                }
              </button>
            ) : (
              <MinusIcon className={`${ikonBoyutu} text-gray-400 mr-1`} />
            )}
            <FolderIcon className={`${ikonBoyutu} text-yellow-500 mr-2 flex-shrink-0`} />
            <span className={`${yaziBoyutu} truncate`}>
              {kategori.isim} ({dogrudan}) ({toplam})
            </span>
          </div>
        </div>
        {acikKategoriler[kategori.isim] && altKategoriler.length > 0 && (
          <div className="ml-4">
            {altKategoriler.map(alt => renderKategori(alt, alt.altKategoriler || [], derinlik + 1, [...ustKategoriler, kategori.isim]))}
          </div>
        )}
      </div>
    );
  };

  const handleKategoriEkleVeyaGuncelleWrapper = async (yeniKategoriAdi, parentId, eskiKategoriAdi) => {
    try {
      console.log('Ekleniyor:', yeniKategoriAdi, 'Parent ID:', parentId);
      
      let yeniKategori;
      if (eskiKategoriAdi) {
        // Kategori güncelleme
        const eskiKategori = await kategoriService.getKategoriByName(eskiKategoriAdi);
        yeniKategori = await kategoriService.updateKategori(eskiKategori.id, { isim: yeniKategoriAdi, parent_id: parentId });
      } else {
        // Yeni kategori ekleme
        yeniKategori = await kategoriService.createKategori({ isim: yeniKategoriAdi, parent_id: parentId });
      }
      
      const updatedKategoriler = await kategoriService.getAllKategoriler();
      setKategoriler(updatedKategoriler);
      return true;
    } catch (error) {
      console.error('Kategori eklenirken/güncellenirken hata:', error);
      toast.error('Kategori işlemi sırasında bir hata oluştu');
      return false;
    }
  };

  const filtrelenmiKategoriler = useMemo(() => {
    if (!aramaMetni) return kategoriler;

    const filtreleme = (kategoriListesi, ustKategoriler = []) => {
      return kategoriListesi.reduce((acc, kategori) => {
        const isimUyuyor = turkishToLower(kategori.isim).includes(turkishToLower(aramaMetni));
        const altKategorilerSonuc = kategori.altKategoriler ? filtreleme(kategori.altKategoriler, [...ustKategoriler, kategori.isim]) : [];
        
        if (isimUyuyor) {
          // Eğer kategori ismi uyuyorsa, tüm alt kategorileri de ekle
          acc.push({
            ...kategori,
            ustKategoriler,
            altKategoriler: kategori.altKategoriler || []
          });
        } else if (altKategorilerSonuc.length > 0) {
          // Eğer alt kategorilerden biri uyuyorsa, bu kategoriyi ve uygun alt kategorileri ekle
          acc.push({
            ...kategori,
            ustKategoriler,
            altKategoriler: altKategorilerSonuc
          });
        }
        return acc;
      }, []);
    };

    return filtreleme(kategoriler);
  }, [kategoriler, aramaMetni]);

  const handleSablonEklendi = (yeniSablon) => {
    // Burada yeni ablonu eklemek için gerekli işlemleri yapabilirsiniz
    // Örneğin, bir API çağrısı yapabilir veya yerel state'i güncelleyebilirsiniz
    const eklenenSablon = ekleYeniSablon(yeniSablon);
    // Eğer gerekiyorsa, eklenen şablonla ilgili başka işlemler yapabilirsiniz
    console.log('Yeni şablon eklendi:', eklenenSablon);
  };

  const buildKategoriAgaci = (kategoriler) => {
    const kategoriMap = new Map();
    const rootKategoriler = [];

    kategoriler.forEach(kategori => {
      kategoriMap.set(kategori.id, { ...kategori, altKategoriler: [] });
    });

    kategoriler.forEach(kategori => {
      if (kategori.parent_id) {
        const parentKategori = kategoriMap.get(kategori.parent_id);
        if (parentKategori) {
          parentKategori.altKategoriler.push(kategoriMap.get(kategori.id));
        }
      } else {
        rootKategoriler.push(kategoriMap.get(kategori.id));
      }
    });

    return rootKategoriler;
  };

  const getKategoriId = async (kategoriAdi) => {
    try {
      const kategori = await kategoriService.getKategoriByName(kategoriAdi);
      return kategori ? kategori.id : null;
    } catch (error) {
      console.error('Kategori ID alınırken hata:', error);
      return null;
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-sm">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-800">Kategoriler</h2>
        <div className="flex items-center space-x-4">
          <Switch
            checked={klasorGorunumu}
            onChange={setKlasorGorunumu}
            className={`${
              klasorGorunumu ? 'bg-blue-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
          >
            <span className="sr-only">Klasör görünümünü etkinleştir</span>
            <span
              className={`${
                klasorGorunumu ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
            />
          </Switch>
          <button 
            onClick={() => {
              setYeniKategoriParent(null);
              setModalAcik(true);
              setParentId(null);
              setUstKategoriler([]);
              setDuzenlenecekKategori(null);
            }}
            className="text-gray-400 hover:text-green-500 focus:outline-none"
          >
            <PlusIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Kategori ara..."
          value={aramaMetni}
          onChange={(e) => setAramaMetni(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
      </div>
      <div className="mb-4 flex items-center hover:bg-gray-100 rounded-lg p-2 transition-colors duration-150">
        <input
          type="checkbox"
          checked={secilenKategoriler.includes('Tümü')}
          onChange={() => handleKategoriSecim('Tümü', tumAltKategoriler(kategoriler))}
          className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-400"
        />
        <FolderIcon className="h-5 w-5 text-gray-400 ml-2 mr-2" />
        <span className="text-sm font-medium text-gray-700 truncate flex-grow">Tümü</span>
      </div>
      <div className="mb-4">
        <div className="flex items-center hover:bg-gray-100 rounded-lg p-2 transition-colors duration-150">
          <input
            type="checkbox"
            checked={secilenKategoriler.includes('Kategorisiz')}
            onChange={() => handleKategoriSecim('Kategorisiz', [])}
            className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-400"
          />
          <FolderIcon className="h-5 w-5 text-gray-400 ml-2 mr-2" />
          {/* <FolderIcon className="h-5 w-5 text-yellow-500 mr-2" /> */}
          <span className="text-sm font-medium text-gray-700">Kategorisiz</span>
        </div>
        <div className="border-b border-gray-200 my-2"></div> {/* İnce çizgi eklendi */}
      </div>
      {klasorGorunumu ? (
        <KategoriKlasorGorunumu
          kategoriler={filtrelenmiKategoriler}
          secilenKategoriler={secilenKategoriler}
          handleKategoriSecim={handleKategoriSecim}
          handleContextMenu={handleContextMenu}
          onYeniKategoriEkle={handleYeniKategoriEkle}
          onYeniSablonEkle={onYeniSablonEkle}
          modalAcik={modalAcik}
          setModalAcik={setModalAcik}
        />
      ) : (
        <div className="overflow-x-auto overflow-y-hidden">
          <div className="min-w-max">
            {filtrelenmiKategoriler.map((kat, index) => (
              <div key={`main-${kat.id || index}-${kat.isim}`}>
                {renderKategori(kat, kat.altKategoriler || [], 0, [])}
              </div>
            ))}
          </div>
        </div>
      )}
      <YeniKategoriModal 
        isOpen={modalAcik}
        onClose={() => {
          setModalAcik(false);
          setDuzenlenecekKategori(null);
          setParentId(null);
          setUstKategoriler([]);
        }}
        onKategoriEkle={handleKategoriEkleVeyaGuncelleWrapper}
        parentKategori={yeniKategoriParent ? yeniKategoriParent.isim : null}
        parentId={parentId}
        duzenlenecekKategori={duzenlenecekKategori}
        stKategoriler={ustKategoriler}
        kategoriler={kategoriler}
      />
      {contextMenu && (
        <KategoriContextMenu
          x={contextMenu.x}
          y={contextMenu.y}
          kategori={contextMenu.kategori}
          onClose={closeContextMenu}
          onDuzenle={handleKategoriDuzenle}
          onSil={handleKategoriSil}
          onYeniEkle={(yeniKategoriAdi) => handleYeniKategoriEkle(yeniKategoriAdi, contextMenu.kategori.id)}
        />
      )}
    </div>
  );
};

export default KategoriAgaci;
