import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../contexts/NotificationContext';
import { FolderIcon, DocumentIcon, PlusIcon, XMarkIcon, ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';

const KayitliTakrirler = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const [records, setRecords] = useState([]);
  const [folders, setFolders] = useState([]);
  const [activeFolder, setActiveFolder] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');

  // Summaries bileşeninden taşınan state'ler
  const [items, setItems] = useState([]);
  const [generalInfo, setGeneralInfo] = useState({});
  const [procedureInfo, setProcedureInfo] = useState({});
  const [takrirInfo, setTakrirInfo] = useState({});
  const [cezaSahsilestirmeInfo, setCezaSahsilestirmeInfo] = useState({});
  const [sanikSabikasıInfo, setSanikSabikasıInfo] = useState('');
  const [tebliğnameInfo, setTebliğnameInfo] = useState('');
  const [tetkikHakimiGorusuInfo, setTetkikHakimiGorusuInfo] = useState('');
  const [onIncelemeInfo, setOnIncelemeInfo] = useState('');
  const [temyizNedenleriInfo, setTemyizNedenleriInfo] = useState('');
  const [delillerInfo, setDelillerInfo] = useState('');

  const fileInputRef = useRef(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const savedFolders = JSON.parse(localStorage.getItem('takrirFolders')) || [];
    setFolders(savedFolders);
    const savedRecords = JSON.parse(localStorage.getItem('takrirRecords')) || [];
    setRecords(savedRecords);
  };

  const handleRecordClick = (record) => {
    console.log("Seçilen takrir verisi:", record.data);
    localStorage.setItem('selectedTakrir', JSON.stringify(record.data));
    localStorage.setItem('selectedTabIndex', '4'); // Özetler sekmesinin indeksi
    showNotification('Takrir yükleniyor...', 'success');
    navigate('/');
  };

  const handleCreateFolder = () => {
    if (newFolderName.trim() !== '') {
      const newFolder = { id: Date.now(), name: newFolderName.trim() };
      const updatedFolders = [...folders, newFolder];
      setFolders(updatedFolders);
      localStorage.setItem('takrirFolders', JSON.stringify(updatedFolders));
      setNewFolderName('');
    }
  };

  const handleSelectFolder = (folderId) => {
    setActiveFolder(folderId);
  };

  const getFilteredRecords = () => {
    if (activeFolder === null) {
      return records.filter(record => !record.folderId);
    }
    return records.filter(record => record.folderId === activeFolder);
  };

  const handleDeleteRecord = (recordId, e) => {
    e.stopPropagation();
    if (window.confirm('Bu takriri silmek istediğinizden emin misiniz?')) {
      const updatedRecords = records.filter(record => record.id !== recordId);
      setRecords(updatedRecords);
      localStorage.setItem('takrirRecords', JSON.stringify(updatedRecords));
    }
  };

  const handleDeleteFolder = (folderId) => {
    if (window.confirm('Bu klasörü ve içindeki tüm takrirleri silmek istediğinizden emin misiniz?')) {
      const updatedFolders = folders.filter(folder => folder.id !== folderId);
      setFolders(updatedFolders);
      localStorage.setItem('takrirFolders', JSON.stringify(updatedFolders));

      const updatedRecords = records.filter(record => record.folderId !== folderId);
      setRecords(updatedRecords);
      localStorage.setItem('takrirRecords', JSON.stringify(updatedRecords));

      setActiveFolder(null);
    }
  };

  const saveAllData = () => {
    const allData = {
      items,
      generalInfo,
      procedureInfo,
      takrirInfo,
      cezaSahsilestirmeInfo,
      sanikSabikasıInfo,
      tebliğnameInfo,
      tetkikHakimiGorusuInfo,
      onIncelemeInfo,
      temyizNedenleriInfo,
      delillerInfo
    };

    // Taraf kartlarındaki penaltyCards verilerini de ekleyelim
    const updatedItems = items.map(item => {
      if (item.type === 'taraf' && item.penaltyCards) {
        return { ...item, penaltyCards: item.penaltyCards };
      }
      return item;
    });

    allData.items = updatedItems;

    // Mevcut kayıtları al
    const existingRecords = JSON.parse(localStorage.getItem('takrirRecords')) || [];
    
    // Yeni kaydı ekle
    const newRecord = {
      id: Date.now(),
      date: new Date().toLocaleString(),
      title: generalInfo.mahkemesi || 'Yeni Takrir',
      data: allData,
      folderId: activeFolder
    };
    existingRecords.push(newRecord);

    // Güncellenmiş kayıtları localStorage'a kaydet
    localStorage.setItem('takrirRecords', JSON.stringify(existingRecords));

    showNotification('Takrir başarıyla kaydedildi!', 'success');
  };

  const exportData = () => {
    const dataToExport = {
      folders: folders,
      records: records
    };
    const jsonString = JSON.stringify(dataToExport, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'takrir_verileri.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    showNotification('Veriler başarıyla dışa aktarıldı!', 'success');
  };

  const importData = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const importedData = JSON.parse(e.target.result);
          if (importedData.folders && importedData.records) {
            setFolders(importedData.folders);
            setRecords(importedData.records);
            localStorage.setItem('takrirFolders', JSON.stringify(importedData.folders));
            localStorage.setItem('takrirRecords', JSON.stringify(importedData.records));
            showNotification('Veriler başarıyla içe aktarıldı!', 'success');
          } else {
            showNotification('Geçersiz veri formatı!', 'error');
          }
        } catch (error) {
          showNotification('Veri içe aktarılırken bir hata oluştu!', 'error');
        }
      };
      reader.readAsText(file);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="max-w-7xl mx-auto p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-8 text-gray-800">Kayıtlı Takrirler</h1>
      
      <div className="mb-8">
        <div className="flex items-center space-x-4">
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="Yeni klasör adı"
            className="flex-grow p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <button
            onClick={handleCreateFolder}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300 ease-in-out flex items-center"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Klasör Oluştur
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
        <div 
          onClick={() => handleSelectFolder(null)}
          className={`p-4 rounded-lg shadow-md cursor-pointer transition duration-300 ease-in-out ${
            activeFolder === null ? 'bg-blue-500 text-white' : 'bg-white hover:bg-gray-50'
          }`}
        >
          <FolderIcon className="h-12 w-12 mb-2" />
          <h3 className="font-semibold text-lg">Ana Dizin</h3>
        </div>
        {folders.map(folder => (
          <div 
            key={folder.id} 
            className={`relative p-4 rounded-lg shadow-md cursor-pointer transition duration-300 ease-in-out ${
              activeFolder === folder.id ? 'bg-blue-500 text-white' : 'bg-white hover:bg-gray-50'
            }`}
            onClick={() => handleSelectFolder(folder.id)}
          >
            <FolderIcon className="h-12 w-12 mb-2" />
            <h3 className="font-semibold text-lg">{folder.name}</h3>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteFolder(folder.id);
              }}
              className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300 ease-in-out"
            >
              <XMarkIcon className="h-4 w-4" />
            </button>
          </div>
        ))}
      </div>

      {getFilteredRecords().length === 0 ? (
        <p className="text-gray-600 text-center">Bu klasörde henüz kaydedilmiş takrir bulunmamaktadır.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {getFilteredRecords().map((record) => (
            <div 
              key={record.id} 
              className="bg-white p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition duration-300 ease-in-out relative"
              onClick={() => handleRecordClick(record)}
            >
              <DocumentIcon className="h-8 w-8 text-blue-500 mb-2" />
              <h3 className="font-semibold text-lg text-gray-800">{record.title}</h3>
              <p className="text-sm text-gray-500 mt-1">{record.date}</p>
              <button
                onClick={(e) => handleDeleteRecord(record.id, e)}
                className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition duration-300 ease-in-out"
              >
                <XMarkIcon className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
      )}

      <div className="flex justify-end space-x-2 mb-4">
        <button
          onClick={exportData}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
          Dışa Aktar
        </button>
        <button
          onClick={triggerFileInput}
          className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        >
          <ArrowUpTrayIcon className="h-5 w-5 mr-2" />
          İçe Aktar
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={importData}
          accept=".json"
          style={{ display: 'none' }}
        />
      </div>
    </div>
  );
};

export default KayitliTakrirler;