import React, { useState, useEffect } from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { predictWithGoogleAI } from '../../../utils/googleAI.js';
import { normalizeKanunMaddeleri } from '../../../utils/kanunMaddeNormalizasyon.js';

const AIEnhancer = ({ icerik, onEnhance, tumEtiketler, tumKanunMaddeleri }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const generateEnhancement = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const prompt = `
        Sen bir Yargıtay kararı uzmanısın. Aşağıdaki metni analiz et ve şu görevleri yerine getir:
        1. Metnin kısa bir özetini oluştur (maksimum 2 cümle ve en fazla toplam 300 karakter). Özet, kararın ana noktalarını ve bozma ya da onama kararının temel gerekçesini/gerekçelerini içermeli. Eğer öninceleme bölümünde istisnai bir durum varsa, o da özete dahil edilmelidir.
        2. Metin için uygun ve spesifik bir başlık öner (maksimum 100 karakter). Başlık, kararın konusunu ve ana hukuki meseleyi yansıtmalı.
        3. Metne uygun 5 etiket öner. Etiketler, kararda geçen spesifik hukuki kavramlar veya özel durumlar olmalı. Genel kavramlardan (örn. 'ceza', 'yargıtay') kaçınılmalı.
        4. Metinde geçen ilgili kanun maddelerini belirle ve listele. Örnek format: 'TCK 58. Madde', 'CMK 100. Madde' gibi.

        Mevcut etiketler: ${tumEtiketler.join(', ')}
        Mevcut kanun maddeleri: ${tumKanunMaddeleri.join(', ')}
        
        Metin:
        ${icerik}
        
        Yanıtını şu formatta ver:
        Özet: [özet]
        Başlık: [başlık]
        Etiketler: [etiket1], [etiket2], [etiket3], [etiket4], [etiket5]
        Kanun Maddeleri: [madde1], [madde2], [madde3], ...
      `;

      const aiResponse = await predictWithGoogleAI(prompt);
      
      // AI yanıtını işle
      const ozet = aiResponse.match(/Özet: (.*)/)?.[1] || '';
      const baslik = aiResponse.match(/Başlık: (.*)/)?.[1] || '';
      const etiketler = aiResponse.match(/Etiketler: (.*)/)?.[1]?.split(', ') || [];
      const kanunMaddeleri = normalizeKanunMaddeleri(aiResponse.match(/Kanun Maddeleri: (.*)/)?.[1]?.split(', ') || []);

      onEnhance({ ozet, baslik, etiketler, kanunMaddeleri });
    } catch (err) {
      setError('AI geliştirmesi sırasında bir hata oluştu. Lütfen tekrar deneyin.');
      console.error('AI geliştirme hatası:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-end">
      <button
        type="button"
        onClick={generateEnhancement}
        disabled={isLoading}
        className={`text-gray-500 hover:text-blue-600 transition-colors duration-200 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        title={isLoading ? 'İşleniyor...' : 'AI ile Geliştir'}
      >
        <SparklesIcon className={`h-6 w-6 ${isLoading ? 'animate-pulse' : ''}`} />
      </button>
      
      {error && <p className="text-red-500 mt-2 text-sm">{error}</p>}
    </div>
  );
};

export default AIEnhancer;
