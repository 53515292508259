export function tarihFarki(baslangic, bitis) {
  let yil = bitis.getFullYear() - baslangic.getFullYear();
  let ay = bitis.getMonth() - baslangic.getMonth();
  let gun = bitis.getDate() - baslangic.getDate();

  // Gün ayarlaması
  if (gun < 0) {
    ay--;
    const oncekiAy = new Date(bitis.getFullYear(), bitis.getMonth(), 0);
    gun += oncekiAy.getDate();
  }

  // Ay ayarlaması
  if (ay < 0) {
    yil--;
    ay += 12;
  }

  // Toplam gün sayısı hesaplama
  const gunSayisi = Math.floor((bitis - baslangic) / (1000 * 60 * 60 * 24));

  return { yil, ay, gun, gunSayisi };
}

export function ekleYil(tarih, yil) {
  const yeniTarih = new Date(tarih);
  yeniTarih.setFullYear(yeniTarih.getFullYear() + Math.floor(yil));
  return yeniTarih;
}

export function ekleGun(tarih, gun) {
  const yeniTarih = new Date(tarih);
  yeniTarih.setDate(yeniTarih.getDate() + Math.round(gun));
  return yeniTarih;
}

export function ekleAy(tarih, ay) {
  const yeniTarih = new Date(tarih);
  yeniTarih.setMonth(yeniTarih.getMonth() + Math.round(ay));
  return yeniTarih;
}

