import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faInfoCircle, faClock, faCalendar } from '@fortawesome/free-solid-svg-icons';

// Tarih formatını değiştiren yardımcı fonksiyon
const formatDate = (dateString) => {
  if (!dateString) return '';
  
  // Eğer tarih zaten GG/AA/YYYY formatındaysa direkt döndür
  if (dateString.includes('/')) {
    return dateString;
  }
  
  // Eğer tarih YYYY-AA-GG formatındaysa dönüştür
  if (dateString.includes('-')) {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  }
  
  return dateString;
};

export const SunriseCard = ({ time, district, city, date }) => (
  <div className="bg-gradient-to-br from-yellow-400 to-orange-500 rounded-lg shadow-lg p-6 text-white relative">
    <div className="flex items-center justify-between mb-4">
      <FontAwesomeIcon icon={faSun} className="text-4xl sm:text-5xl md:text-6xl" />
      <h2 className="text-lg sm:text-xl md:text-3xl font-bold whitespace-nowrap">Güneşin Doğuşu</h2>
    </div>
    <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-center mt-4">{time}</p>
    {district && city && (
      <p className="absolute top-2 right-2 text-xs text-white opacity-75">
        {district} ({city})
      </p>
    )}
    {date && (
      <p className="absolute bottom-2 right-2 text-xs text-white opacity-75 flex items-center">
        <FontAwesomeIcon icon={faCalendar} className="mr-1" />
        {formatDate(date)}
      </p>
    )}
  </div>
);

export const SunsetCard = ({ time, district, city, date }) => (
  <div className="bg-gradient-to-br from-blue-700 to-purple-900 rounded-lg shadow-lg p-6 text-white relative">
    <div className="flex items-center justify-between mb-4">
      <FontAwesomeIcon icon={faMoon} className="text-4xl sm:text-5xl md:text-6xl" />
      <h2 className="text-lg sm:text-xl md:text-3xl font-bold whitespace-nowrap">Güneşin Batışı</h2>
    </div>
    <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-center mt-4">{time}</p>
    {district && city && (
      <p className="absolute top-2 right-2 text-xs text-white opacity-75">
        {district} ({city})
      </p>
    )}
    {date && (
      <p className="absolute bottom-2 right-2 text-xs text-white opacity-75 flex items-center">
        <FontAwesomeIcon icon={faCalendar} className="mr-1" />
        {formatDate(date)}
      </p>
    )}
  </div>
);

export const DstCard = ({ isDst, normalSunrise, normalSunset, dstSunrise, dstSunset, onShowDetails, dstPeriod }) => {
  const formatDate = (dateString) => {
    if (dateString === "süresiz") return "süresiz";
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="bg-gradient-to-br from-blue-100 to-purple-200 rounded-lg shadow-lg p-6 relative">
      <h3 className="text-2xl font-bold mb-4 text-indigo-800 flex items-center">
        <FontAwesomeIcon icon={faClock} className="mr-2" />
        {isDst ? "Yaz Saati Uygulandı" : "Yaz Saati Uygulanmadı"}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg p-4 shadow">
          <h4 className="text-lg font-semibold text-indigo-600 mb-2 flex items-center">
            <FontAwesomeIcon icon={faSun} className="mr-2" />
            Güneşin Doğuşu
          </h4>
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-600">Normal:</span>
            <span className="font-bold text-indigo-800 text-xl">{normalSunrise}</span>
          </div>
          {isDst && (
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Yaz Saati:</span>
              <span className="font-bold text-purple-800 text-xl">{dstSunrise}</span>
            </div>
          )}
        </div>
        <div className="bg-white rounded-lg p-4 shadow">
          <h4 className="text-lg font-semibold text-purple-600 mb-2 flex items-center">
            <FontAwesomeIcon icon={faMoon} className="mr-2" />
            Güneşin Batışı
          </h4>
          <div className="flex justify-between items-center mb-2">
            <span className="text-gray-600">Normal:</span>
            <span className="font-bold text-indigo-800 text-xl">{normalSunset}</span>
          </div>
          {isDst && (
            <div className="flex justify-between items-center">
              <span className="text-gray-600">Yaz Saati:</span>
              <span className="font-bold text-purple-800 text-xl">{dstSunset}</span>
            </div>
          )}
        </div>
      </div>
      {dstPeriod && (
        <div className="mt-4 bg-white rounded-lg p-4 shadow">
          <p className="text-sm text-gray-700">
            <span className="font-semibold">Not:</span> {
              dstPeriod.start === "2016-03-27" && dstPeriod.end === "süresiz"
                ? "27/03/2016 tarihinden sonra kalıcı yaz saati uygulamasına geçildi."
                : `${formatDate(dstPeriod.start)} - ${formatDate(dstPeriod.end)} tarihleri arasında yaz saati uygulanmıştır.`
            }
          </p>
        </div>
      )}
      <button 
        onClick={onShowDetails} 
        className="absolute top-2 right-2 text-indigo-600 hover:text-indigo-800 transition duration-300 ease-in-out"
        aria-label="Detaylar"
      >
        <FontAwesomeIcon icon={faInfoCircle} className="text-xl" />
      </button>
    </div>
  );
};