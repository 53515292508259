import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { isValid, parse } from 'date-fns';

const EditPartyModal = ({ isOpen, onClose, party, onSave }) => {
  const [editedParty, setEditedParty] = useState(party || {});
  const [dateError, setDateError] = useState('');

  useEffect(() => {
    setEditedParty(party || {});
  }, [party]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditedParty(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    
    if (name === 'birthDate') {
      setDateError('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!editedParty.birthDate) {
      setDateError('Lütfen bir doğum tarihi giriniz.');
      return;
    }
    const birthDate = parse(editedParty.birthDate, 'yyyy-MM-dd', new Date());
    if (!isValid(birthDate)) {
      setDateError('Lütfen geçerli bir doğum tarihi giriniz.');
      return;
    }
    if (typeof onSave === 'function') {
      onSave(editedParty);
      onClose();
    } else {
      console.error('onSave fonksiyonu tanımlı değil');
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  {party ? 'Taraf Bilgilerini Düzenle' : 'Yeni Taraf Ekle'}
                </Dialog.Title>
                {/* Kapatma butonu */}
                <button
                  onClick={onClose}
                  className="absolute top-2 right-2 text-gray-400 hover:text-gray-500"
                  aria-label="Kapat"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="mb-4">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">İsim</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={editedParty.name || ''}
                      onChange={handleInputChange}
                      className="mt-1 block w-full p-2.5 text-base rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                  <div className="mb-4">
                    <label htmlFor="role" className="block text-sm font-medium text-gray-700">Rol</label>
                    <select
                      name="role"
                      id="role"
                      value={editedParty.role || ''}
                      onChange={handleInputChange}
                      className="mt-1 block w-full p-2.5 text-base rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="">Seçiniz</option>
                      <option value="Sanık">Sanık</option>
                      <option value="Mağdur">Mağdur</option>
                      <option value="Müşteki">Müşteki</option>
                      <option value="SSÇ">SSÇ</option>
                    </select>
                  </div>
                  <div className="mb-4">
                    <label htmlFor="birthDate" className="block text-sm font-medium text-gray-700">Doğum Tarihi</label>
                    <input
                      type="date"
                      name="birthDate"
                      id="birthDate"
                      value={editedParty.birthDate || ''}
                      onChange={handleInputChange}
                      className={`mt-1 block w-full p-2.5 text-base rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${dateError ? 'border-red-500' : ''}`}
                    />
                    {dateError && <p className="mt-2 text-sm text-red-600">{dateError}</p>}
                  </div>
                  <div className="mb-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="SIR"
                        checked={editedParty.SIR || false}
                        onChange={handleInputChange}
                        className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                      <span className="ml-2 text-sm text-gray-600">SIR</span>
                    </label>
                  </div>
                  <div className="mb-4">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        name="FM"
                        checked={editedParty.FM || false}
                        onChange={handleInputChange}
                        className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      />
                      <span className="ml-2 text-sm text-gray-600">FM</span>
                    </label>
                  </div>
                  {/* Butonlar için yeni bir div */}
                  <div className="mt-6 flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={onClose}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    >
                      İptal
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    >
                      {party ? 'Güncelle' : 'Ekle'}
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditPartyModal;