import React from 'react';
import CreatableSelect from 'react-select/creatable';

export const Etiketleme = ({ etiketler, onChange, kayitliEtiketler }) => {
  const options = kayitliEtiketler.map(etiket => ({ value: etiket, label: etiket }));
  const value = etiketler.map(etiket => 
    typeof etiket === 'string' ? { value: etiket, label: etiket } : etiket
  );

  const handleChange = (newValue) => {
    onChange(newValue.map(item => item.value));
  };

  return (
    <div>
      <label htmlFor="etiketler" className="block text-sm font-medium text-gray-700">Etiketler</label>
      <CreatableSelect
        isMulti
        name="etiketler"
        options={options}
        value={value}
        onChange={handleChange}
        className="mt-1"
        placeholder="Etiket seçin veya ekleyin..."
        noOptionsMessage={() => "Etiket bulunamadı"}
        formatCreateLabel={(inputValue) => `"${inputValue}" ekle`}
      />
    </div>
  );
};

export const KanunMaddeleri = ({ kanunMaddeleri, onChange, kayitliKanunMaddeleri }) => {
  const options = kayitliKanunMaddeleri.map(madde => ({ value: madde, label: madde }));
  const value = kanunMaddeleri.map(madde => 
    typeof madde === 'string' ? { value: madde, label: madde } : madde
  );

  const handleChange = (newValue) => {
    onChange(newValue.map(item => item.value));
  };

  return (
    <div>
      <label htmlFor="kanunMaddeleri" className="block text-sm font-medium text-gray-700">İlgili Kanun Maddeleri</label>
      <CreatableSelect
        isMulti
        name="kanunMaddeleri"
        options={options}
        value={value}
        onChange={handleChange}
        className="mt-1"
        placeholder="Kanun maddesi seçin veya ekleyin..."
        noOptionsMessage={() => "Kanun maddesi bulunamadı"}
        formatCreateLabel={(inputValue) => `"${inputValue}" ekle`}
      />
    </div>
  );
};

export const Kategoriler = ({ kategoriler, onChange, kayitliKategoriler }) => {
  const flattenKategoriler = (kategoriler) => {
    return kategoriler.reduce((acc, kategori) => {
      acc.push({ value: kategori.id, label: kategori.isim });
      if (kategori.altKategoriler && kategori.altKategoriler.length > 0) {
        acc.push(...flattenKategoriler(kategori.altKategoriler));
      }
      return acc;
    }, []);
  };

  const options = flattenKategoriler(kayitliKategoriler);
  const value = kategoriler.map(kategori => 
    typeof kategori === 'object' ? { value: kategori.id, label: kategori.isim } : { value: kategori, label: kategori }
  );

  const handleChange = (newValue) => {
    onChange(newValue.map(item => ({ id: item.value, isim: item.label })));
  };

  return (
    <div>
      <label htmlFor="kategoriler" className="block text-sm font-medium text-gray-700">Kategoriler</label>
      <CreatableSelect
        isMulti
        name="kategoriler"
        options={options}
        value={value}
        onChange={handleChange}
        className="mt-1"
        placeholder="Kategori seçin veya ekleyin..."
        noOptionsMessage={() => "Kategori bulunamadı"}
        formatCreateLabel={(inputValue) => `"${inputValue}" ekle`}
      />
    </div>
  );
};
