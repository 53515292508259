import React, { useState } from 'react';
import { DocumentTextIcon, GlobeAltIcon, SparklesIcon, AdjustmentsHorizontalIcon, DocumentCheckIcon } from '@heroicons/react/24/outline';
import AIEnhancer from './duzenleme/AIEnhancer';
import DetaylarModal from './duzenleme/DetaylarModal';

const HeaderCard = ({ 
  baslik, 
  onDuzenle, 
  onIptal, 
  onAIGelistir, 
  onDetaylar, 
  gorunumModu, 
  setGorunumModu, 
  activeTab, 
  setActiveTab, 
  icerik, 
  tumEtiketler, 
  tumKanunMaddeleri,
  duzenlenmisSablon,
  kategoriler,
  handleChange,
  handleSelectChange,
  kayitliEtiketler,
  kayitliKanunMaddeleri,
  onSave,
  handleSubmit
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleModalSave = (updatedSablon) => {
    onSave(updatedSablon);
    toggleModal();
  };

  return (
    <>
      <style>{`
        .switch {
          position: relative;
          display: inline-block;
          width: 160px;
          height: 34px;
        }
        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #e0e0e0;
          transition: .4s;
          border-radius: 4px;
        }
        .slider:before {
          position: absolute;
          content: "";
          height: 26px;
          width: 78px;
          left: 4px;
          bottom: 4px;
          background-color: white;
          transition: .4s;
          border-radius: 2px;
        }
        input:checked + .slider:before {
          transform: translateX(74px);
        }
        .slider-text {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          width: 78px;
          font-weight: bold;
          font-size: 14px;
          transition: .4s;
          z-index: 1;
        }
        .slider-text.left {
          left: 4px;
        }
        .slider-text.right {
          right: 4px;
        }
        .slider-text {
          color: #9e9e9e;
        }
        input:not(:checked) + .slider .slider-text.left,
        input:checked + .slider .slider-text.right {
          color: #424242;
        }
      `}</style>
      <div className="bg-white border-b border-gray-200 p-4 mb-4 shadow-sm">
        <div className="flex flex-wrap items-center">
          <div className="w-1/5 flex items-center">
            <label className="switch">
              <input
                type="checkbox"
                checked={activeTab === 'duzenle'}
                onChange={() => setActiveTab(activeTab === 'onizle' ? 'duzenle' : 'onizle')}
              />
              <span className="slider">
                <span className="slider-text left">Önizle</span>
                <span className="slider-text right">Düzenle</span>
              </span>
            </label>
          </div>
          <div className="w-3/5 text-center py-2">
            <h2 className="text-xl font-semibold text-gray-800 break-words">{baslik}</h2>
          </div>
          <div className="w-1/5 flex justify-end items-center space-x-4">
            <AIEnhancer
              icerik={icerik}
              onEnhance={onAIGelistir}
              tumEtiketler={tumEtiketler}
              tumKanunMaddeleri={tumKanunMaddeleri}
            />
            <button
              onClick={toggleModal}
              className="text-gray-600 hover:text-blue-600 transition-colors duration-200"
              title="Detaylar"
            >
              <AdjustmentsHorizontalIcon className="h-6 w-6" />
            </button>
            <div className="border-l border-gray-300 h-6 mx-2"></div>
            <div className="flex bg-gray-100 rounded-md p-1">
              <button
                onClick={() => setGorunumModu('sayfa')}
                className={`p-1 rounded ${gorunumModu === 'sayfa' ? 'bg-white shadow' : 'hover:bg-gray-200'}`}
                title="Sayfa Görünümü"
              >
                <DocumentTextIcon className="h-5 w-5 text-gray-600" />
              </button>
              <button
                onClick={() => setGorunumModu('web')}
                className={`p-1 rounded ${gorunumModu === 'web' ? 'bg-white shadow' : 'hover:bg-gray-200'}`}
                title="Web Görünümü"
              >
                <GlobeAltIcon className="h-5 w-5 text-gray-600" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <DetaylarModal
          duzenlenmisSablon={duzenlenmisSablon}
          kategoriler={kategoriler}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          kayitliEtiketler={tumEtiketler}
          kayitliKanunMaddeleri={tumKanunMaddeleri}
          onClose={toggleModal}
          onSave={onSave}
        />
      )}
    </>
  );
};

export default HeaderCard;
