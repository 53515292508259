import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/sablonlar`;

export const getSablonlar = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

export const getSablonById = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Şablon alınırken hata:', error.response?.data || error.message);
    throw error;
  }
};

export const createSablon = async (sablon) => {
  const response = await axios.post(API_URL, sablon);
  return response.data;
};

export const updateSablon = async (id, sablonData) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, sablonData);
    return response.data;
  } catch (error) {
    console.error('Şablon güncellenirken hata:', error);
    throw error;
  }
};

export const deleteSablon = async (id) => {
  const response = await axios.delete(`${API_URL}/${id}`);
  return response.data;
};

