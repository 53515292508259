import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { turkishToLower } from '../utils/stringUtils';

const AutocompleteSearch = ({ data, onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.length > 1) {
      const filteredSuggestions = data.filter(
        item => turkishToLower(item.name).includes(turkishToLower(value))
      );
      setSuggestions(filteredSuggestions.slice(0, 5));
    setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleSelect = (item) => {
    setSearchTerm(item.name);
    onSelect(item);
    setShowSuggestions(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedItem = data.find(item => turkishToLower(item.name) === turkishToLower(searchTerm));
    if (selectedItem) {
      onSelect(selectedItem);
    } else {
      alert('Lütfen listeden bir seçim yapın.');
    }
    setShowSuggestions(false);
  };

  return (
    <div ref={wrapperRef} className="relative">
      <form onSubmit={handleSubmit} className="flex">
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          placeholder="İl veya ilçe ara..."
          className="flex-grow p-2 border border-gray-300 rounded-l-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
        />
        <button
          type="submit"
          className="bg-indigo-600 text-white p-2 rounded-r-md hover:bg-indigo-700 transition duration-300 ease-in-out"
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </form>
      {showSuggestions && suggestions.length > 0 && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded-md shadow-lg">
          {suggestions.map((item, index) => (
                <li
              key={index}
                  onClick={() => handleSelect(item)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
                >
                    {item.name}
              {item.parent && <span className="text-gray-500 ml-2">({item.parent})</span>}
                </li>
              ))}
            </ul>
      )}
    </div>
  );
};

export default AutocompleteSearch;