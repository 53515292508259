export const turkishToLower = (str) => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/İ/g, 'i')
    .replace(/I/g, 'ı')
    .replace(/Ğ/g, 'ğ')
    .replace(/Ü/g, 'ü')
    .replace(/Ş/g, 'ş')
    .replace(/Ö/g, 'ö')
    .replace(/Ç/g, 'ç')
    .toLowerCase();
};

export const normalizeString = (str) => {
    return str.replace(/İ/g, 'i')
    .replace(/I/g, 'ı')
    .replace(/Ğ/g, 'ğ')
    .replace(/Ü/g, 'ü')
    .replace(/Ş/g, 'ş')
    .replace(/Ö/g, 'ö')
    .replace(/Ç/g, 'ç')
    .toLowerCase();
   
};

const turkishCollator = new Intl.Collator('tr', { sensitivity: 'base' });

export const turkishSort = (a, b) => {
  const turkishCollator = new Intl.Collator('tr', {
    sensitivity: 'base',
    numeric: true
  });
  return turkishCollator.compare(a, b);
};

export const turkishToUpper = (str) => {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/i/g, 'İ')
        .replace(/ı/g, 'I')
        .replace(/ğ/g, 'Ğ')
        .replace(/ü/g, 'Ü')
        .replace(/ş/g, 'Ş')
        .replace(/ö/g, 'Ö')
        .replace(/ç/g, 'Ç')
        .toUpperCase();
};

export const capitalizeFirstLetter = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
        return '';
    }
    return turkishToUpper(str.charAt(0)) + turkishToLower(str.slice(1));
};

export const capitalizeFullName = (fullName) => {
    return fullName.split(' ').map(capitalizeFirstLetter).join(' ');
};