import axios from 'axios';
import { turkishSort } from '../utils/stringUtils';
import { cityNameMapping } from '../utils/cityNameMapping';
import citiesDistrictsData from '../data/cities-districts.json';

const API_URL = `${process.env.REACT_APP_API_URL}/sun-times`;

// Şehir ismini veritabanındaki haline çeviren yardımcı fonksiyon
const getDbCityName = (cityName) => {
  // cityNameMapping'i ters çevir
  const reversedMapping = Object.entries(cityNameMapping).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});
  
  return reversedMapping[cityName] || cityName;
};

const formatCityName = (cityName) => {
  // Şehir ismini küçük harfe çevirip, mapping'den düzgün halini al
  const formattedName = cityNameMapping[cityName.toLowerCase()] || cityName;
  return formattedName;
};

// Verileri JSON dosyasına kaydetmek için kullanılacak fonksiyon
export const updateCitiesDistrictsData = async () => {
  try {
    const citiesResponse = await axios.get(`${API_URL}/cities`);
    const cities = citiesResponse.data.map(city => ({
      City: formatCityName(city.City)
    }));

    const districts = {};
    for (const city of citiesResponse.data) {
      const districtsResponse = await axios.get(`${API_URL}/districts`, {
        params: { city: city.City }
      });
      districts[formatCityName(city.City)] = districtsResponse.data.map(d => d.District);
    }

    // Burada normalde dosyaya yazma işlemi yapılacak, ancak frontend'de bunu yapamayız
    // Bu işlem backend tarafında yapılmalı
    console.log('Veriler güncellendi:', { cities, districts });
    return { cities, districts };
  } catch (error) {
    console.error('Veriler güncellenirken hata:', error);
    throw error;
  }
};

export const getCities = async () => {
  try {
    // Önce JSON dosyasından okuma yapalım
    if (citiesDistrictsData.cities && citiesDistrictsData.cities.length > 0) {
      const sortedCities = citiesDistrictsData.cities.sort((a, b) => turkishSort(a.City, b.City));
      return sortedCities;
    }

    // JSON dosyası boşsa veya hata varsa API'den alalım
    const response = await axios.get(`${API_URL}/cities`);
    const formattedCities = response.data.map(city => ({
      ...city,
      City: formatCityName(city.City)
    }));
    const sortedCities = formattedCities.sort((a, b) => turkishSort(a.City, b.City));
    return sortedCities;
  } catch (error) {
    console.error('Şehirler alınırken hata:', error);
    throw error;
  }
};

export const getDistricts = async (city) => {
  try {
    // Önce JSON dosyasından okuma yapalım
    if (citiesDistrictsData.districts && citiesDistrictsData.districts[city]) {
      const allDistricts = citiesDistrictsData.districts[city].map(district => ({
        District: district
      }));
      
      // "İl merkezi"ni bul ve ayır
      const ilMerkeziIndex = allDistricts.findIndex(d => d.District === 'İl merkezi');
      let sortedDistricts = [];
      
      // Eğer "İl merkezi" varsa, önce onu ekle
      if (ilMerkeziIndex !== -1) {
        sortedDistricts.push(allDistricts[ilMerkeziIndex]);
        allDistricts.splice(ilMerkeziIndex, 1); // "İl merkezi"ni listeden çıkar
      }
      
      // Diğer ilçeleri sırala ve ekle
      sortedDistricts = [
        ...sortedDistricts,
        ...allDistricts.sort((a, b) => turkishSort(a.District, b.District))
      ];
      
      return sortedDistricts;
    }

    // JSON dosyası boşsa veya hata varsa API'den alalım
    const dbCityName = getDbCityName(city);
    const response = await axios.get(`${API_URL}/districts`, {
      params: { city: dbCityName }
    });
    
    // API'den gelen ilçeleri sırala ("İl merkezi" her zaman başta olacak)
    const allDistricts = response.data;
    const ilMerkeziIndex = allDistricts.findIndex(d => d.District === 'İl merkezi');
    let sortedDistricts = [];
    
    // Eğer "İl merkezi" varsa, önce onu ekle
    if (ilMerkeziIndex !== -1) {
      sortedDistricts.push(allDistricts[ilMerkeziIndex]);
      allDistricts.splice(ilMerkeziIndex, 1); // "İl merkezi"ni listeden çıkar
    }
    
    // Diğer ilçeleri sırala ve ekle
    sortedDistricts = [
      ...sortedDistricts,
      ...allDistricts.sort((a, b) => turkishSort(a.District, b.District))
    ];
    
    return sortedDistricts;
  } catch (error) {
    console.error('İlçeler alınırken hata:', error);
    throw error;
  }
};

export const getSunTimes = async (city, district, day, month) => {
  try {
    const dbCityName = getDbCityName(city);
    const response = await axios.get(`${API_URL}/sun-times`, {
      params: { 
        city: dbCityName, 
        district, 
        day, 
        month 
      }
    });
    return response.data;
  } catch (error) {
    console.error('Güneş vakitleri alınırken hata:', error);
    throw error;
  }
}; 