import React, { useState, useEffect, useRef } from 'react';
import { PlusCircle, Trash2, Edit2, ChevronDown, Save, Copy } from 'lucide-react';
import { useDrag, useDrop } from 'react-dnd';
import Modal from '../modals/Modal.js';
import EditColumnModal from '../modals/EditColumnModal.js';
import { cezaEksilt, cezaArttir, formatCeza } from '../../utils/cezaHesaplamalari.js';
import Column from './penaltyCardComponents/Column';
import ColumnMenu from './penaltyCardComponents/ColumnMenu.js';
import { templates } from '../../templates/predefinedData.js';
import { saveCardAsTemplate } from '../../utils/templateUtils.js';
import { getPredefinedColumns } from '../../utils/columnUtils.js';
import AddCardMenu from './AddCardMenu.js';

const ItemTypes = {
  PENALTY_CARD: 'penaltyCard'
};

const DraggableCard = ({ card, index, moveCard, deleteCard, saveAsTemplate, handleEditColumn, showColumnMenu, deleteColumn, startResizing, startResizingHeight, onCopyCard }) => {
  const ref = React.useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.PENALTY_CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.PENALTY_CARD,
    item: () => {
      return { id: card.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div 
      ref={ref} 
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="w-full max-w-xl mx-auto bg-white shadow-sm border border-gray-500 rounded-sm overflow-hidden mb-2"
    >
      <div className="flex relative" style={{ height: `${card.columns[0].height}px` }}>
        <div className="w-6 group relative flex items-center justify-center bg-gray-100 font-bold text-lg border-r border-gray-400">
          <span className="transform -rotate-90">{card.title}</span>
          <div className="absolute top-0 left-0 w-full flex flex-col items-center opacity-0 group-hover:opacity-100 transition-opacity">
            <button 
              className="h-8 w-full flex items-center justify-center hover:bg-red-100"
              onClick={() => deleteCard(card.id)}
            >
              <Trash2 className="h-4 w-4 text-red-500" />
            </button>
            <button 
              className="h-8 w-full flex items-center justify-center hover:bg-blue-100"
              onClick={() => saveAsTemplate(card.id)}
            >
              <Save className="h-4 w-4 text-blue-500" />
            </button>
            <button 
              className="h-8 w-full flex items-center justify-center hover:bg-green-100"
              onClick={() => onCopyCard(card)}
            >
              <Copy className="h-4 w-4 text-green-500" />
            </button>
          </div>
        </div>
        <div className="flex-grow flex">
          {card.columns.map((column, colIndex) => (
            <Column
              key={column.id}
              column={column}
              index={colIndex}
              cardId={card.id}
              handleEditColumn={handleEditColumn}
              showColumnMenu={showColumnMenu}
              deleteColumn={deleteColumn}
              startResizing={startResizing}
            />
          ))}
        </div>
        <div 
          className="absolute bottom-0 left-0 right-0 h-1 cursor-row-resize bg-gray-300 opacity-0 hover:opacity-100"
          onMouseDown={(e) => startResizingHeight(card.id, e)}
        />
      </div>
    </div>
  );
};

const PenaltyCard = ({ penaltyInfo = [], onUpdate, onAddColumn }) => {
  const [cards, setCards] = useState(penaltyInfo.length > 0 ? penaltyInfo : []);
  const [editingColumn, setEditingColumn] = useState(null);
  const [resizingColumn, setResizingColumn] = useState(null);
  const [resizingHeight, setResizingHeight] = useState(null);
  const resizeRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTemplateMenuOpen, setIsTemplateMenuOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [predefinedColumns, setPredefinedColumns] = useState([]);

  useEffect(() => {
    if (penaltyInfo.length > 0) {
      setCards(penaltyInfo);
    }
  }, [penaltyInfo]);

  useEffect(() => {
    // Ön tanımlı kolonları yükle
    const loadPredefinedColumns = () => {
      const columns = getPredefinedColumns();
      setPredefinedColumns(columns);
    };

    loadPredefinedColumns();
    // LocalStorage'daki değişiklikleri dinle
    window.addEventListener('storage', loadPredefinedColumns);

    return () => {
      window.removeEventListener('storage', loadPredefinedColumns);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Local storage'dan şablonları yükle
    const loadTemplates = () => {
      const storedTemplates = JSON.parse(localStorage.getItem('templates')) || [];
      setTemplates(storedTemplates);
    };

    loadTemplates();
    // LocalStorage'daki değişiklikleri dinle
    window.addEventListener('storage', loadTemplates);

    return () => {
      window.removeEventListener('storage', loadTemplates);
    };
  }, []);

  const showColumnMenu = (e, cardId, columnIndex) => {
    e.stopPropagation();
    const rect = e.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom,
      left: rect.left
    });
    setActiveMenu({ cardId, columnIndex });
  };

  const handleAddColumn = (cardId, columnIndex, predefinedColumn = null) => {
    addColumn(cardId, columnIndex, predefinedColumn);
    setActiveMenu(null);
  };

  const toggleTemplateMenu = () => {
    setIsTemplateMenuOpen(!isTemplateMenuOpen);
  };

  const addCard = (template = null) => {
    let newCard;
    if (template) {
      // Şablondan yeni kart oluştur
      newCard = {
        id: Date.now(),
        title: String.fromCharCode(65 + cards.length),
        columns: template.columns.map(col => ({
          ...col,
          id: Date.now() + Math.random(), // Benzersiz ID oluştur
        }))
      };
    } else {
      // Boş kart oluştur
      newCard = {
        id: Date.now(),
        title: String.fromCharCode(65 + cards.length),
        columns: [
          { 
            id: Date.now(), 
            law: '', 
            penalty: { year: 0, month: 0, day: 0, fine: 0 }, 
            discountRate: { type: 'indirim', rate: { numerator: 0, denominator: 1 } }, 
            width: 100, 
            height: 40 
          }
        ]
      };
    }
    const updatedCards = [...cards, newCard];
    setCards(updatedCards);
    onUpdate(updatedCards);
    setIsTemplateMenuOpen(false);
  };
  
  const calculateNewPenalty = (column, prevPenalty) => {
    if (!prevPenalty || !column.discountRate) return column.penalty;
  
    if (column.discountRate.type === 'indirim') {
      return cezaEksilt(prevPenalty, column.discountRate);
    } else {
      return cezaArttir(prevPenalty, column.discountRate);
    }
  };

  const addColumn = (cardId, index, predefinedColumn = null) => {
    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        let newColumn;
        if (predefinedColumn) {
          if (predefinedColumn.isSingleLine || predefinedColumn.isTwoLineText) {
            newColumn = { 
              ...predefinedColumn, 
              id: Date.now(), 
              width: 25, 
              height: card.columns[0].height,
            };
          } else {
            const prevColumn = card.columns[index];
            const prevPenalty = prevColumn ? prevColumn.penalty : { year: 0, month: 0, day: 0, fine: 0 };
            const newPenalty = calculateNewPenalty(predefinedColumn, prevPenalty);
            newColumn = { 
              ...predefinedColumn, 
              id: Date.now(), 
              width: 25, 
              height: card.columns[0].height,
              penalty: newPenalty
            };
          }
        } else {
          newColumn = { 
            id: Date.now(), 
            law: '', 
            penalty: { year: 0, month: 0, day: 0, fine: 0 }, 
            discountRate: { type: 'indirim', rate: { numerator: 0, denominator: 1 } }, 
            width: 25, 
            height: card.columns[0].height,
            isSingleLine: false,
            isTwoLineText: false,
            isBasePenalty: false
          };
        }
        const newColumns = [...card.columns.slice(0, index + 1), newColumn, ...card.columns.slice(index + 1)];
        return { ...card, columns: newColumns.map(col => ({ ...col, width: 100 / newColumns.length })) };
      }
      return card;
    });
    onUpdate(updatedCards);
  };

  const deleteCard = (cardId) => {
    const newCards = cards.filter(card => card.id !== cardId);
    onUpdate(newCards.map((card, index) => ({
      ...card,
      title: String.fromCharCode(65 + index)
    })));
  };

  const deleteColumn = (cardId, columnId) => {
    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        const newColumns = card.columns.filter(col => col.id !== columnId);
        return { ...card, columns: newColumns.map(col => ({ ...col, width: 100 / newColumns.length })) };
      }
      return card;
    });
    onUpdate(updatedCards);
  };

  const updateColumn = (cardId, columnId, field, value) => {
    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        return {
          ...card,
          columns: card.columns.map(col => 
            col.id === columnId ? { ...col, [field]: value } : col
          )
        };
      }
      return card;
    });
    onUpdate(updatedCards);
  };

  const updateCardHeight = (cardId, newHeight) => {
    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        return {
          ...card,
          columns: card.columns.map(col => ({ ...col, height: newHeight }))
        };
      }
      return card;
    });
    onUpdate(updatedCards);
  };

  const startResizing = (cardId, columnId, e) => {
    const column = cards.find(c => c.id === cardId).columns.find(col => col.id === columnId);
    setResizingColumn({
      cardId,
      columnId,
      startX: e.clientX,
      startWidth: column.width
    });
  };

  const startResizingHeight = (cardId, e) => {
    const card = cards.find(c => c.id === cardId);
    setResizingHeight({
      cardId,
      startY: e.clientY,
      startHeight: card.columns[0].height
    });
  };

  const handleEditColumn = (column, cardId, columnIndex) => {
    if (column) {
      const card = cards.find(c => c.id === cardId);
      const prevColumn = card.columns[columnIndex - 1];
      setEditingColumn({...column, cardId, columnIndex, prevPenalty: prevColumn ? prevColumn.penalty : { year: 0, month: 0, day: 0, fine: 0 }});
      setIsEditModalOpen(true);
    }
  };

  const handleSaveColumn = (editedColumn) => {
    const { cardId, columnIndex } = editedColumn;
    
    const updatedCards = cards.map(card => {
      if (card.id === cardId) {
        const newColumns = [...card.columns];
        newColumns[columnIndex] = {
          ...editedColumn,
          penalty: editedColumn.penalty,
          discountRate: editedColumn.discountRate
        };
        return { ...card, columns: newColumns };
      }
      return card;
    });
    onUpdate(updatedCards);
  
    setEditingColumn(null);
    setIsEditModalOpen(false);
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (resizingColumn) {
        const { cardId, columnId, startX, startWidth } = resizingColumn;
        const diff = e.clientX - startX;
        const newWidth = Math.max(10, Math.min(50, startWidth + diff * 0.5));
        updateColumn(cardId, columnId, 'width', newWidth);
      }
      if (resizingHeight) {
        const { cardId, startY, startHeight } = resizingHeight;
        const diff = e.clientY - startY;
        const newHeight = Math.max(40, startHeight + diff);
        updateCardHeight(cardId, newHeight);
      }
    };

    const handleMouseUp = () => {
      setResizingColumn(null);
      setResizingHeight(null);
    };

    if (resizingColumn || resizingHeight) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [resizingColumn, resizingHeight]);

  const saveAsTemplate = (cardId) => {
    const cardToSave = cards.find(card => card.id === cardId);
    if (cardToSave) {
      const newTemplate = saveCardAsTemplate(cardToSave);
      // Opsiyonel: Kullanıcıya bir bildirim gösterebilirsiniz
      alert(`"${newTemplate.title}" adıyla yeni bir şablon kaydedildi.`);
    }
  };

  const moveCard = (dragIndex, hoverIndex) => {
    const dragCard = cards[dragIndex];
    const updatedCards = [...cards];
    updatedCards.splice(dragIndex, 1);
    updatedCards.splice(hoverIndex, 0, dragCard);
    
    setCards(updatedCards);
    onUpdate(updatedCards);
  };

  const handleCopyCard = (card) => {
    const cardCopy = {
      ...card,
      id: Date.now(),
      title: String.fromCharCode(65 + cards.length),
      columns: card.columns.map(col => ({
        ...col,
        id: Date.now() + Math.random()
      }))
    };
    
    // Kopyalanan kartı hemen ekle
    const updatedCards = [...cards, cardCopy];
    setCards(updatedCards);
    onUpdate(updatedCards);

    // Kullanıcıya bildirim göster
    const notification = document.createElement('div');
    notification.className = 'fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-md shadow-lg z-50';
    notification.textContent = 'Ceza kartı kopyalandı';
    document.body.appendChild(notification);
    
    // 2 saniye sonra bildirimi kaldır
    setTimeout(() => {
      notification.remove();
    }, 2000);
  };

  return (
    <div className="space-y-2">
      {cards.length > 0 ? (
        cards.map((card, index) => (
          <DraggableCard
            key={card.id}
            card={card}
            index={index}
            moveCard={moveCard}
            deleteCard={deleteCard}
            saveAsTemplate={saveAsTemplate}
            handleEditColumn={handleEditColumn}
            showColumnMenu={showColumnMenu}
            deleteColumn={deleteColumn}
            startResizing={startResizing}
            startResizingHeight={startResizingHeight}
            onCopyCard={handleCopyCard}
          />
        ))
      ) : (
        <div className="text-center text-gray-500">Henüz ceza kartı eklenmemiş.</div>
      )}
      <div className="flex justify-center mt-2">
        <AddCardMenu
          isOpen={isTemplateMenuOpen}
          toggleMenu={toggleTemplateMenu}
          addCard={addCard}
          templates={templates}
        />
      </div>
      <EditColumnModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        column={editingColumn}
        onSave={handleSaveColumn}
        prevPenalty={editingColumn?.prevPenalty}
      />
      <ColumnMenu
        menuPosition={menuPosition}
        activeMenu={activeMenu}
        handleAddColumn={handleAddColumn}
        setActiveMenu={setActiveMenu}
        predefinedColumns={predefinedColumns}
      />
    </div>
  );
};

export default PenaltyCard;