import { ekleYil, ekleGun } from './tarihHesaplamalari';
import { cezaArttir, cezaEksilt } from '../utils/cezaHesaplamalari';

const yasGrubuOranlari = {
  'yetiskin': { rate: { numerator: 1, denominator: 1 } },
  '15-18': { rate: { numerator: 2, denominator: 3 } },
  '12-15': { rate: { numerator: 1, denominator: 2 } }
};

export function hesaplaZamanAsimi(
  sucTarihi, 
  zamanAsimiSuresi, 
  durduranSebepler, 
  kesenSebepler, 
  yasGrubu = 'yetiskin',
  yurtDisiSuc = false,
  sucNiteligi = 'normal',
  cocugaKarsiSuc = false
) {
  // Eklenmesi gereken kontroller
  const kesmedenSonraMaxUzama = zamanAsimiSuresi * 0.5; // TCK 67/4'e göre en fazla yarısı kadar uzayabilir
  
  // Yurt dışında işlenen suçlar için zamanaşımı kontrolü (TCK 66/7)
  if (yurtDisiSuc && zamanAsimiSuresi > 10) {
    return {
      zamanAsimiUygulanmaz: true
    };
  }
  
  // Yaş grubuna göre zamanaşımı süresini ayarla
  const oran = yasGrubuOranlari[yasGrubu];
  if (!oran) {
    throw new Error('Geçersiz yaş grubu');
  }

  // Önce normal zamanaşımı süresini belirle
  const normalSure = {
    year: zamanAsimiSuresi,
    month: 0,
    day: 0,
    fine: 0
  };
  
  // Yaş grubuna göre süreyi hesapla
  const ayarlanmisSure = yasGrubu === 'yetiskin' ? normalSure : cezaEksilt(normalSure, {
    rate: {
      numerator: oran.rate.denominator - oran.rate.numerator,
      denominator: oran.rate.denominator
    }
  });

  // Olağan zamanaşımı süresini hesapla
  const olaganSure = {
    year: ayarlanmisSure.year,
    month: ayarlanmisSure.month,
    day: ayarlanmisSure.day,
    fine: 0
  };

  // Olağanüstü zamanaşımı süresini hesapla (1.5 katı)
  const olaganustuSure = {
    year: Math.floor(ayarlanmisSure.year * 1.5),
    month: Math.floor((ayarlanmisSure.year * 1.5 - Math.floor(ayarlanmisSure.year * 1.5)) * 12),
    day: 0,
    fine: 0
  };

  console.log(`Yaş grubu: ${yasGrubu}, Oran: ${JSON.stringify(oran)}, Normal süre: ${zamanAsimiSuresi}, Ayarlanmış süre:`, ayarlanmisSure);

  // İşlem takibi için değişkenleri başlangıçta tanımla
  let islemler = [];
  let sonKesmeTarihi = new Date(sucTarihi);
  
  // Durma sürelerini takip etmek için Set kullanıyoruz
  const hesaplananDurmaSureler = new Set();
  let toplamDurmaSuresi = 0;

  // Durma sürelerini hesapla
  durduranSebepler.forEach(sebep => {
    const baslangic = new Date(sebep.baslangic);
    const bitis = new Date(sebep.bitis);
    
    let currentDate = new Date(baslangic);
    while (currentDate <= bitis) {
      const gunId = currentDate.toISOString().split('T')[0];
      if (!hesaplananDurmaSureler.has(gunId)) {
        hesaplananDurmaSureler.add(gunId);
        toplamDurmaSuresi++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  });

  // Kesen sebepleri tarihe göre sırala ve işle
  const siraliKesenSebepler = [...kesenSebepler]
    .sort((a, b) => new Date(a.tarih) - new Date(b.tarih));

  for (const kesme of siraliKesenSebepler) {
    const kesmeTarihi = new Date(kesme.tarih);
    
    // Mevcut zamanaşımı süresini hesapla
    const mevcutZamanAsimi = ekleGun(
      ekleYil(sonKesmeTarihi, ayarlanmisSure.year),
      ayarlanmisSure.day + (ayarlanmisSure.month * 30) +
      toplamDurmaSuresi
    );

    // Kesme tarihi zamanaşımı dolmadan önceyse işleme al
    if (kesmeTarihi < mevcutZamanAsimi) {
      sonKesmeTarihi = kesmeTarihi;
      islemler.push({
        tip: 'kesme',
        tarih: kesmeTarihi,
        aciklama: kesme.tip,
        durmaSuresi: toplamDurmaSuresi
      });
    }
  }

  // Olağan zamanaşımını hesapla (suç/kesme tarihinden itibaren)
  let olaganZamanAsimi = ekleGun(
    ekleYil(sonKesmeTarihi, olaganSure.year),
    (olaganSure.day + (olaganSure.month * 30))
  );

  // Olağanüstü zamanaşımını hesapla (suç tarihinden itibaren)
  let olaganustuZamanAsimi = ekleGun(
    ekleYil(sucTarihi, olaganustuSure.year),
    (olaganustuSure.day + (olaganustuSure.month * 30))
  );

  // Durma sürelerini ekle
  olaganZamanAsimi = ekleGun(olaganZamanAsimi, toplamDurmaSuresi);
  olaganustuZamanAsimi = ekleGun(olaganustuZamanAsimi, toplamDurmaSuresi);

  const bugun = new Date();
  const olaganZamanAsimiDoldu = bugun > olaganZamanAsimi;
  const olaganustuZamanAsimiDoldu = bugun > olaganustuZamanAsimi;

  // Her iki zamanaşımı süresini de kontrol et
  return {
    olaganZamanAsimi,
    olaganustuZamanAsimi,
    toplamDurmaSuresi,
    kalanGun: olaganZamanAsimiDoldu 
      ? Math.ceil((olaganustuZamanAsimi - bugun) / (1000 * 60 * 60 * 24))
      : Math.ceil((olaganZamanAsimi - bugun) / (1000 * 60 * 60 * 24)),
    sonKesilmeTarihi: sonKesmeTarihi,
    yasGrubuOrani: oran,
    zamanAsimiDoldu: olaganustuZamanAsimiDoldu,
    olaganZamanAsimiDoldu,
    olaganustuZamanAsimiDoldu,
    islemler,
    yasGrubuBilgisi: {
      grup: yasGrubu,
      oran: oran,
      normalSure: normalSure,
      ayarlanmisSure: ayarlanmisSure,
      olaganSure,
      olaganustuSure
    }
  };
}
