import React, { useState } from 'react';
import { FolderIcon, FolderOpenIcon, ChevronLeftIcon, ChevronRightIcon, PlusIcon } from '@heroicons/react/20/solid';
import YeniKategoriModal from './YeniKategoriModal';

const KategoriKlasorGorunumu = ({ 
  kategoriler, 
  secilenKategoriler, 
  handleKategoriSecim, 
  handleContextMenu, 
  onYeniKategoriEkle
}) => {
  const [currentPath, setCurrentPath] = useState([]);
  const [modalAcik, setModalAcik] = useState(false);
  const [yeniKategoriParent, setYeniKategoriParent] = useState(null);
  const [currentKategoriId, setCurrentKategoriId] = useState(null);

  const getCurrentKategoriler = () => {
    let current = kategoriler;
    for (let kategori of currentPath) {
      current = current.find(k => k.isim === kategori)?.altKategoriler || [];
    }
    return current;
  };

  const handleKategoriClick = (kategori) => {
    console.log('Kategori ID:', kategori.id);
    setCurrentPath([...currentPath, kategori.isim]);
    setCurrentKategoriId(kategori.id);
  };

  const handleBack = () => {
    if (currentPath.length > 0) {
      const newPath = currentPath.slice(0, -1);
      setCurrentPath(newPath);
      
      const findParentKategori = (kategoriler, path) => {
        if (path.length === 0) return null;
        let current = kategoriler;
        for (let i = 0; i < path.length - 1; i++) {
          current = current.find(k => k.isim === path[i])?.altKategoriler || [];
        }
        return current.find(k => k.isim === path[path.length - 1]);
      };

      const parentKategori = findParentKategori(kategoriler, newPath);
      setCurrentKategoriId(parentKategori ? parentKategori.id : null);
    }
  };

  const currentKategoriler = getCurrentKategoriler();

  const handleYeniKlasorEkle = () => {
    setYeniKategoriParent(currentPath.length > 0 ? currentPath[currentPath.length - 1] : null);
    setModalAcik(true);
  };
  
  return (
    <div className="p-4">
      <div className="flex items-center mb-4 text-sm text-gray-600">
        <button
          onClick={handleBack}
          disabled={currentPath.length === 0}
          className={`mr-2 ${currentPath.length === 0 ? 'text-gray-400' : 'text-blue-600 hover:text-blue-800'}`}
        >
          <ChevronLeftIcon className="h-5 w-5 inline" />
        </button>
        {currentPath.length === 0 ? 'Ana Dizin' : currentPath.join(' > ')}
      </div>
      <div className="grid grid-cols-3 gap-6">
        <div
          className="flex flex-col items-center p-3 cursor-pointer hover:bg-gray-100 rounded"
          onClick={handleYeniKlasorEkle}
        >
          <PlusIcon className="h-16 w-16 text-gray-400" />
          <span className="mt-2 text-sm text-center">Yeni Klasör Ekle</span>
        </div>
        {currentKategoriler.map((kategori) => (
          <div
            key={kategori.id || kategori.isim}
            className="flex flex-col items-center p-3 cursor-pointer hover:bg-gray-100 rounded group relative"
            onClick={() => handleKategoriClick(kategori)}
            onContextMenu={(e) => handleContextMenu(e, kategori)}
          >
            {kategori.altKategoriler && kategori.altKategoriler.length > 0 ? (
              <FolderOpenIcon className="h-16 w-16 text-yellow-600" />
            ) : (
              <FolderIcon className="h-16 w-16 text-yellow-500" />
            )}
            <div className="mt-2 text-center w-full">
              <input
                type="checkbox"
                checked={secilenKategoriler.includes(kategori.isim)}
                onChange={() => handleKategoriSecim(kategori.isim, kategori.altKategoriler || [])}
                className="mr-1"
                onClick={(e) => e.stopPropagation()}
              />
              <span className="text-sm block max-w-full line-clamp-2" title={kategori.isim}>
                {kategori.isim}
              </span>
            </div>
            {kategori.altKategoriler && kategori.altKategoriler.length > 0 && (
              <ChevronRightIcon className="h-5 w-5 text-gray-400 absolute right-2 bottom-2" />
            )}
          </div>
        ))}
      </div>
      <YeniKategoriModal 
        isOpen={modalAcik}
        onClose={() => {
          setModalAcik(false);
          setYeniKategoriParent(null);
        }}
        onKategoriEkle={async (yeniKategoriAdi) => {
          const sonuc = await onYeniKategoriEkle(yeniKategoriAdi, currentKategoriId);
          if (sonuc !== false) {
            setModalAcik(false);
            setYeniKategoriParent(null);
          }
          return sonuc;
        }}
        parentKategori={yeniKategoriParent}
        parentId={currentKategoriId}
        ustKategoriler={currentPath}
        kategoriler={kategoriler}
      />
    </div>
  );
};

export default KategoriKlasorGorunumu;
