import React from 'react';
import { ekleYil, ekleGun, tarihFarki } from './tarihHesaplamalari';
import { calculateDateDifference } from '../utils/dateUtils';

const formatTarih = (tarih) => {
  const date = new Date(tarih);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  
  return `${day}/${month}/${year}`;
};

// Süre formatlama yardımcı fonksiyonu - dateUtils kullanarak güncellendi
const formatSure = (baslangic, bitis) => {
  const sureFarki = calculateDateDifference(baslangic, bitis);
  if (!sureFarki) return '';

  // Kısa format için dönüştürme (örn: "2 yıl 3 ay 5 gün" -> "2y 3a 5g")
  return sureFarki
    .replace(' yıl', 'y')
    .replace(' ay', 'a')
    .replace(' gün', 'g');
};

// Tarih çizgisi bileşeni
const TarihCizgisi = ({ tarih, label, color = "gray", left, tip }) => (
  <div
    className="absolute group"
    style={{
      left: `${left}%`,
      bottom: '0',
      transform: 'translateX(-50%)',
    }}
  >
    <div className="relative">
      {/* Tarih etiketi */}
      <div className={`
        inline-flex
        items-center
        px-1
        py-1
        ${label === "Olağan Z.A." || label === "Olağanüstü Z.A." ? 'bg-red-50 border-red-200' : `bg-${color}-50 border-${color}-200`}
        border
        rounded-sm
        shadow-sm
        text-[10px]
        leading-tight
        whitespace-nowrap
        group-hover:bg-${color}-100
        transition-colors
        duration-200
        absolute
        bottom-8
      `}
      style={{
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg) translateX(50%)',
        fontSize: '0.65rem',
      }}
      >
        <span className={`${label === "Olağan Z.A." || label === "Olağanüstü Z.A." ? 'text-red-800' : `text-${color}-800`}`}>
          {formatTarih(tarih)}
        </span>
        {label && (
          <span className={`ml-0.5 ${label === "Olağan Z.A." || label === "Olağanüstü Z.A." ? 'text-red-600' : `text-${color}-600`} font-medium`}>
            ({label})
          </span>
        )}
      </div>

      {/* Tooltip */}
      {tip && (
        <div className="absolute left-1/2 bottom-full mb-1 -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <div className={`
            bg-white
            px-2
            py-1
            text-xs
            rounded
            shadow-lg
            border
            border-gray-200
            whitespace-nowrap
          `}>
            {tip}
          </div>
        </div>
      )}

      {/* Dikey çizgi */}
      {(label === "Olağan Z.A." || label === "Olağanüstü Z.A.") && (
        <div className="absolute left-1/2 bottom-0 w-px bg-red-500 opacity-30 group-hover:opacity-50 transition-opacity duration-200"
             style={{ height: 'calc(100vh - 200px)' }} />
      )}

      {/* Nokta */}
      <div className={`w-2 h-2 rounded-full ${
        label === "Olağan Z.A." || label === "Olağanüstü Z.A." 
          ? 'bg-red-500' 
          : `bg-${color}-500`
      } absolute left-1/2 -translate-x-1/2 bottom-2`} />
    </div>
  </div>
);

// Ölçek bileşeni
const TimelineOlcek = ({ baslangicTarihi, bitisTarihi, width, olaylar }) => {
  const toplamSure = bitisTarihi - baslangicTarihi;
  const yillar = [];
  const baslangicYili = new Date(baslangicTarihi).getFullYear();
  const bitisYili = new Date(bitisTarihi).getFullYear();

  // Yıl işaretlerini oluştur
  for (let yil = baslangicYili; yil <= bitisYili; yil++) {
    const tarih = new Date(yil, 0, 1);
    const position = ((tarih - baslangicTarihi) / toplamSure) * 100;
    if (position >= 0 && position <= 100) {
      yillar.push({ yil, position });
    }
  }

  return (
    <div className="relative h-32">
      {/* Ana çizgi */}
      <div className="absolute bottom-0 left-0 right-0 h-px bg-gray-300" />
      
      {/* Yıl işaretleri */}
      {yillar.map(({ yil, position }) => (
        <div
          key={yil}
          className="absolute bottom-0 transform -translate-x-1/2"
          style={{ left: `${position}%` }}
        >
          {/* Dikey çizgi */}
          <div className="w-px h-2 bg-gray-400" />
          {/* Yıl etiketi */}
          <div className="text-xs text-gray-600 mt-1">{yil}</div>
        </div>
      ))}

      {/* Ay işaretleri */}
      {yillar.map(({ yil, position }, index) => {
        const aylar = [];
        for (let ay = 1; ay <= 11; ay++) {
          const tarih = new Date(yil, ay, 1);
          const ayPosition = ((tarih - baslangicTarihi) / toplamSure) * 100;
          if (ayPosition >= 0 && ayPosition <= 100) {
            aylar.push(ayPosition);
          }
        }
        return aylar.map((ayPosition, ayIndex) => (
          <div
            key={`${yil}-${ayIndex}`}
            className="absolute bottom-0 transform -translate-x-1/2"
            style={{ left: `${ayPosition}%` }}
          >
            <div className="w-px h-1 bg-gray-300" />
          </div>
        ));
      })}

      {/* Önemli tarihler */}
      {olaylar.map((olay, index) => (
        <TarihCizgisi
          key={index}
          tarih={olay.tarih}
          label={olay.label}
          color={olay.color}
          left={((olay.tarih - baslangicTarihi) / toplamSure) * 100}
          tip={olay.tip}
        />
      ))}
    </div>
  );
};

// Timeline içeriği kısmında durma sürelerinden önceki süreleri gösteren fonksiyonu ekleyelim
const SureEtiketi = ({ baslangic, bitis, color = "blue" }) => {
  const sureFarki = calculateDateDifference(baslangic, bitis);
  if (!sureFarki) return null;
  
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className={`text-[9px] font-medium text-${color}-700 truncate px-0.5`}>
        {formatSure(baslangic, bitis)}
      </div>
    </div>
  );
};

function ZamanAsimiTimeline({ 
  sonuc, 
  sucTarihi, 
  durduranSebepler, 
  kesenSebepler, 
  zamanAsimiSuresi,
  ayarlanmisSure,
  toplamDurmaSuresi
}) {
  // Eğer ayarlanmisSure yoksa, varsayılan değer oluştur
  const defaultSure = {
    year: zamanAsimiSuresi,
    month: 0,
    day: 0
  };

  const sure = ayarlanmisSure || defaultSure;
  const durmaSuresi = toplamDurmaSuresi || 0;

  // İlk zamanaşımı süresini hesapla
  const ilkZamanAsimiTarihi = ekleGun(
    ekleYil(sucTarihi, sure.year),
    (sure.day + (sure.month * 30) + durmaSuresi)
  );

  // Toplam süreyi hesapla (başlangıç ve bitiş tarihlerini kullanarak)
  const timelineBaslangic = new Date(sucTarihi);
  const timelineBitis = sonuc.olaganustuZamanAsimi;
  const toplamSure = timelineBitis - timelineBaslangic;
  
  // Kesme olaylarını sırala
  const siraliKesmeler = [...kesenSebepler].sort((a, b) => new Date(a.tarih) - new Date(b.tarih));

  // Timeline parçalarını oluştur
  const timelineParcalari = [];
  let sonTarih = new Date(sucTarihi);

  // Her kesme olayı için yeni bir parça oluştur
  siraliKesmeler.forEach((kesme) => {
    const kesmeTarihi = new Date(kesme.tarih);
    if (kesmeTarihi > sonTarih) {
      timelineParcalari.push({
        baslangic: sonTarih,
        bitis: kesmeTarihi,
        kesmeOlayi: kesme
      });
      sonTarih = kesmeTarihi;
    }
  });

  // Son parçayı ekle
  timelineParcalari.push({
    baslangic: sonTarih,
    bitis: sonuc.olaganZamanAsimi,
    kesmeOlayi: null
  });

  // Önemli tarihleri oluştur
  const onemliTarihler = [
    { tarih: new Date(sucTarihi), label: "Suç Tarihi", color: "blue" },
    // Durma sürelerinin başlangıç ve bitiş tarihlerini ekle
    ...durduranSebepler.flatMap(durma => [
      { 
        tarih: new Date(durma.baslangic), 
        label: "Durma Başl.", 
        color: "yellow",
        tip: durma.tip 
      },
      { 
        tarih: new Date(durma.bitis), 
        label: "Durma Bit.", 
        color: "yellow",
        tip: durma.tip 
      }
    ]),
    // Kesme tarihlerini ekle
    ...siraliKesmeler.map(kesme => ({
      tarih: new Date(kesme.tarih),
      label: "Kesme",
      color: "green",
      tip: kesme.tip
    })),
    { tarih: sonuc.olaganZamanAsimi, label: "Olağan Z.A.", color: "blue" },
    { tarih: sonuc.olaganustuZamanAsimi, label: "Olağanüstü Z.A.", color: "purple" },
    { tarih: new Date(), label: "Bugün", color: "red" }
  ].sort((a, b) => a.tarih - b.tarih);

  return (
    <div className="mt-8 bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="bg-gradient-to-r from-indigo-500 to-purple-500 px-6 py-4">
        <h2 className="text-2xl font-bold text-white">Zamanaşımı Süreci</h2>
      </div>

      <div className="p-6 relative">
        <div className="space-y-4">
          {/* Olağan Zamanaşımı Timeline */}
          <div className="flex items-center">
            <div className="w-32 text-sm font-medium text-gray-600 pr-4">
              Olağan Zamanaşımı
            </div>
            <div className="flex-1">
              <div className="relative h-6">
                <div className="absolute inset-0 bg-gray-100 rounded-lg">
                  {/* İlk zamanaşımı süresi (kesme olmadan) */}
                  <div
                    className="absolute h-full group"
                    style={{
                      left: `${((new Date(sucTarihi) - timelineBaslangic) / toplamSure) * 100}%`,
                      width: `${((ilkZamanAsimiTarihi - new Date(sucTarihi)) / toplamSure) * 100}%`,
                    }}
                  >
                    <div className="absolute inset-0 bg-blue-200" />
                    <SureEtiketi
                      baslangic={new Date(sucTarihi)}
                      bitis={ilkZamanAsimiTarihi}
                    />
                    
                    {/* İlk zamanaşımı noktası - kesik çizgi olarak değiştirildi */}
                    <div 
                      className="absolute right-0 top-0 bottom-0 group z-20"
                      style={{ transform: 'translateX(50%)' }}
                    >
                      <div className="h-full w-0.5 bg-orange-500 opacity-75 border-l border-dashed border-orange-600">
                        <div className="absolute h-full w-0.5 bg-orange-500 animate-pulse opacity-50"></div>
                      </div>
                      
                      {/* Tooltip */}
                      <div className="absolute top-1/2 left-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity z-30 whitespace-nowrap">
                        <div className="bg-orange-100 text-orange-800 text-xs px-2 py-1 rounded shadow-sm border border-orange-200 ml-2">
                          İlk Zamanaşımı: {formatTarih(ilkZamanAsimiTarihi)}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Kesme sonrası uzayan süre */}
                  {kesenSebepler.length > 0 && (
                    <div
                      className="absolute h-full"
                      style={{
                        left: `${((ilkZamanAsimiTarihi - timelineBaslangic) / toplamSure) * 100}%`,
                        width: `${((sonuc.olaganZamanAsimi - ilkZamanAsimiTarihi) / toplamSure) * 100}%`,
                      }}
                    >
                      <div className="absolute inset-0 bg-green-200" />
                      <SureEtiketi
                        baslangic={ilkZamanAsimiTarihi}
                        bitis={sonuc.olaganZamanAsimi}
                        color="green"
                      />
                    </div>
                  )}

                  {/* Durma süreleri - mevcut kod aynı kalacak */}
                  {durduranSebepler.map((durma, index) => {
                    const baslangic = new Date(durma.baslangic);
                    const bitis = new Date(durma.bitis);
                    const left = ((baslangic - new Date(sucTarihi)) / toplamSure) * 100;
                    const width = ((bitis - baslangic) / toplamSure) * 100;

                    // Önceki durma süresinin bitiş tarihi veya suç tarihi
                    const oncekiDurmaBitis = index > 0 
                      ? new Date(durduranSebepler[index - 1].bitis)
                      : new Date(sucTarihi);

                    // Sonraki durma başlangıcı veya zamanaşımı tarihi
                    const sonrakiDurmaBaslangic = index < durduranSebepler.length - 1 
                      ? new Date(durduranSebepler[index + 1].baslangic)
                      : sonuc.olaganZamanAsimi;

                    return (
                      <React.Fragment key={`durma-${index}`}>
                        {/* Durma öncesi süre */}
                        <div
                          className="absolute h-full"
                          style={{
                            left: `${((oncekiDurmaBitis - new Date(sucTarihi)) / toplamSure) * 100}%`,
                            width: `${((baslangic - oncekiDurmaBitis) / toplamSure) * 100}%`
                          }}
                        >
                          <div className="absolute inset-0 bg-blue-200" />
                          <SureEtiketi baslangic={oncekiDurmaBitis} bitis={baslangic} />
                        </div>

                        {/* Durma süresi */}
                        <div
                          className="absolute h-full group"
                          style={{
                            left: `${left}%`,
                            width: `${width}%`
                          }}
                        >
                          <div className="absolute inset-0 bg-yellow-300" />
                          <div className="absolute inset-0 flex items-center justify-center">
                            <div className="text-[9px] font-medium text-yellow-800 truncate px-0.5">
                              {formatSure(baslangic, bitis)}
                            </div>
                          </div>
                          
                          {/* Hover süre etiketi */}
                          <div className="absolute -top-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                            <div className="bg-yellow-100 text-yellow-800 text-[10px] px-1.5 py-0.5 rounded border border-yellow-200 whitespace-nowrap shadow-sm">
                              {calculateDateDifference(baslangic, bitis)} {/* Tam format için */}
                            </div>
                          </div>
                        </div>

                        {/* Durma sonrası süre */}
                        <div
                          className="absolute h-full"
                          style={{
                            left: `${((bitis - new Date(sucTarihi)) / toplamSure) * 100}%`,
                            width: `${((sonrakiDurmaBaslangic - bitis) / toplamSure) * 100}%`
                          }}
                        >
                          <div className="absolute inset-0 bg-blue-200" />
                          <SureEtiketi baslangic={bitis} bitis={sonrakiDurmaBaslangic} />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* Olağanüstü Zamanaşımı Timeline */}
          <div className="flex items-center">
            <div className="w-32 text-sm font-medium text-gray-600 pr-4">
              Olağanüstü Z.A.
            </div>
            <div className="flex-1 relative h-6 bg-gray-100 rounded-lg">
              <div className="absolute h-full group">
                <div className="absolute inset-0 bg-purple-200" 
                  style={{
                    width: `${((sonuc.olaganustuZamanAsimi - new Date(sucTarihi)) / toplamSure) * 100}%`
                  }}
                />
                <SureEtiketi 
                  baslangic={new Date(sucTarihi)} 
                  bitis={sonuc.olaganustuZamanAsimi}
                  color="purple" 
                />
              </div>

              {/* Durma süreleri */}
              {durduranSebepler.map((durma, index) => {
                const baslangic = new Date(durma.baslangic);
                const bitis = new Date(durma.bitis);
                const left = ((baslangic - new Date(sucTarihi)) / toplamSure) * 100;
                const width = ((bitis - baslangic) / toplamSure) * 100;

                // Önceki durma süresinin bitiş tarihi veya suç tarihi
                const oncekiDurmaBitis = index > 0 
                  ? new Date(durduranSebepler[index - 1].bitis)
                  : new Date(sucTarihi);

                // Sonraki durma başlangıcı veya zamanaşımı tarihi
                const sonrakiDurmaBaslangic = index < durduranSebepler.length - 1 
                  ? new Date(durduranSebepler[index + 1].baslangic)
                  : sonuc.olaganZamanAsimi;

                return (
                  <React.Fragment key={`durma-olaganustu-${index}`}>
                    {/* Durma öncesi süre */}
                    <div
                      className="absolute h-full"
                      style={{
                        left: `${((oncekiDurmaBitis - new Date(sucTarihi)) / toplamSure) * 100}%`,
                        width: `${((baslangic - oncekiDurmaBitis) / toplamSure) * 100}%`
                      }}
                    >
                      <div className="absolute inset-0 bg-purple-200" />
                      <SureEtiketi baslangic={oncekiDurmaBitis} bitis={baslangic} color="purple" />
                    </div>

                    {/* Durma süresi */}
                    <div
                      className="absolute h-full group"
                      style={{
                        left: `${left}%`,
                        width: `${width}%`
                      }}
                    >
                      <div className="absolute inset-0 bg-yellow-300" />
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="text-[9px] font-medium text-yellow-800 truncate px-0.5">
                          {formatSure(baslangic, bitis)}
                        </div>
                      </div>
                      
                      {/* Hover süre etiketi */}
                      <div className="absolute -top-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                        <div className="bg-yellow-100 text-yellow-800 text-[10px] px-1.5 py-0.5 rounded border border-yellow-200 whitespace-nowrap shadow-sm">
                          {calculateDateDifference(baslangic, bitis)} {/* Tam format için */}
                        </div>
                      </div>
                    </div>

                    {/* Durma sonrası süre */}
                    <div
                      className="absolute h-full"
                      style={{
                        left: `${((bitis - new Date(sucTarihi)) / toplamSure) * 100}%`,
                        width: `${((sonrakiDurmaBaslangic - bitis) / toplamSure) * 100}%`
                      }}
                    >
                      <div className="absolute inset-0 bg-blue-200" />
                      <SureEtiketi baslangic={bitis} bitis={sonrakiDurmaBaslangic} />
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>

          {/* Kesme sonrası timelinelar */}
          {siraliKesmeler.map((kesme, index) => (
            <div key={`kesme-timeline-${index}`} className="flex items-center">
              <div className="w-32 text-sm font-medium text-gray-600 pr-4">
                {`${index + 1}. Kesme Sonrası`}
              </div>
              <div className="flex-1 relative h-6 bg-gray-100 rounded-lg">
                <div className="absolute h-full group">
                  <div className="absolute inset-0 bg-blue-200"
                    style={{
                      left: `${((new Date(kesme.tarih) - new Date(sucTarihi)) / toplamSure) * 100}%`,
                      width: `${((sonuc.olaganZamanAsimi - new Date(kesme.tarih)) / toplamSure) * 100}%`
                    }}
                  />
                  <SureEtiketi 
                    baslangic={new Date(kesme.tarih)} 
                    bitis={sonuc.olaganZamanAsimi} 
                  />
                </div>

                {/* Bu timeline'a ait durma süreleri */}
                {durduranSebepler
                  .filter(durma => new Date(durma.baslangic) > new Date(kesme.tarih))
                  .map((durma, durmaIndex) => {
                    const baslangic = new Date(durma.baslangic);
                    const bitis = new Date(durma.bitis);
                    const left = ((baslangic - new Date(sucTarihi)) / toplamSure) * 100;
                    const width = ((bitis - baslangic) / toplamSure) * 100;

                    // Önceki durma süresinin bitiş tarihi veya kesme tarihi
                    const oncekiDurmaBitis = durmaIndex > 0 
                      ? new Date(durduranSebepler[durmaIndex - 1].bitis)
                      : new Date(kesme.tarih);

                    // Sonraki durma başlangıcı veya zamanaşımı tarihi
                    const sonrakiDurmaBaslangic = durmaIndex < durduranSebepler.length - 1 
                      ? new Date(durduranSebepler[durmaIndex + 1].baslangic)
                      : sonuc.olaganZamanAsimi;

                    return (
                      <React.Fragment key={`kesme-${index}-durma-${durmaIndex}`}>
                        {/* Durma öncesi süre */}
                        <div
                          className="absolute h-full"
                          style={{
                            left: `${((oncekiDurmaBitis - new Date(sucTarihi)) / toplamSure) * 100}%`,
                            width: `${((baslangic - oncekiDurmaBitis) / toplamSure) * 100}%`
                          }}
                        >
                          <div className="absolute inset-0 bg-blue-200" />
                          <SureEtiketi baslangic={oncekiDurmaBitis} bitis={baslangic} />
                        </div>

                        {/* Durma süresi */}
                        <div
                          className="absolute h-full group"
                          style={{
                            left: `${left}%`,
                            width: `${width}%`
                          }}
                        >
                          <div className="absolute inset-0 bg-yellow-300" />
                          <div className="absolute inset-0 flex items-center justify-center">
                            <div className="text-[9px] font-medium text-yellow-800 truncate px-0.5">
                              {formatSure(baslangic, bitis)}
                            </div>
                          </div>
                          
                          {/* Hover süre etiketi */}
                          <div className="absolute -top-5 left-1/2 transform -translate-x-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                            <div className="bg-yellow-100 text-yellow-800 text-[10px] px-1.5 py-0.5 rounded border border-yellow-200 whitespace-nowrap shadow-sm">
                              {calculateDateDifference(baslangic, bitis)}
                            </div>
                          </div>
                        </div>

                        {/* Durma sonrası süre */}
                        <div
                          className="absolute h-full"
                          style={{
                            left: `${((bitis - new Date(sucTarihi)) / toplamSure) * 100}%`,
                            width: `${((sonrakiDurmaBaslangic - bitis) / toplamSure) * 100}%`
                          }}
                        >
                          <div className="absolute inset-0 bg-blue-200" />
                          <SureEtiketi baslangic={bitis} bitis={sonrakiDurmaBaslangic} />
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          ))}

          {/* Ölçek - Yüksekliği artırıldı */}
          <div className="flex items-start mt-16">
            <div className="w-32 text-sm font-medium text-gray-600 pr-4 pt-8">
              Zaman Ölçeği
            </div>
            <div className="flex-1">
              <TimelineOlcek
                baslangicTarihi={new Date(sucTarihi)}
                bitisTarihi={sonuc.olaganustuZamanAsimi}
                width="100%"
                olaylar={onemliTarihler}
              />
            </div>
          </div>
        </div>

        {/* Açıklamalar */}
        <div className="flex items-center space-x-6 mt-4">
          <div className="flex items-center">
            <div className="w-4 h-4 bg-blue-200 rounded mr-2" />
            <span className="text-sm text-gray-600">Olağan Zamanaşımı</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-purple-200 rounded mr-2" />
            <span className="text-sm text-gray-600">Olağanüstü Zamanaşımı</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-yellow-300 rounded mr-2" />
            <span className="text-sm text-gray-600">Durma Süresi</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-green-500 rounded mr-2" />
            <span className="text-sm text-gray-600">Kesme Olayı</span>
          </div>
          <div className="flex items-center">
            <div className="w-0.5 h-4 bg-red-500 rounded mr-2" />
            <span className="text-sm text-gray-600">Bugün</span>
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-green-200 rounded mr-2" />
            <span className="text-sm text-gray-600">Kesme Sonrası Uzama</span>
          </div>
          <div className="flex items-center">
            <div className="w-3 h-3 bg-orange-500 rounded-full mr-2" />
            <span className="text-sm text-gray-600">İlk Zamanaşımı Tarihi</span>
          </div>
        </div>

        {/* Özet Bilgiler */}
        <div className="mt-6 grid grid-cols-4 gap-4">
          <div className="bg-blue-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-blue-800">Olağan Zamanaşımı</h3>
            <p className="text-lg font-bold text-blue-600">{formatTarih(sonuc.olaganZamanAsimi)}</p>
          </div>
          <div className="bg-purple-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-purple-800">Olağanüstü Zamanaşımı</h3>
            <p className="text-lg font-bold text-purple-600">{formatTarih(sonuc.olaganustuZamanAsimi)}</p>
          </div>
          <div className="bg-yellow-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-yellow-800">Toplam Durma</h3>
            <p className="text-lg font-bold text-yellow-600">{sonuc.toplamDurdurmaSuresi} gün</p>
          </div>
          <div className="bg-green-50 rounded-lg p-4">
            <h3 className="text-sm font-medium text-green-800">Kesme Sayısı</h3>
            <p className="text-lg font-bold text-green-600">{kesenSebepler.length}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZamanAsimiTimeline; 