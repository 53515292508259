import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt, faSun, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SunriseCard, SunsetCard, DstCard } from '../components/SunTimeCards.js';
import AutocompleteSearch from '../components/AutocompleteSearch.js';
import { turkishToLower, turkishSort } from '../utils/stringUtils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import tr from 'date-fns/locale/tr';
import * as sunTimeService from '../services/sunTimeService';

// Türkçe lokalizasyonunu kaydet
registerLocale('tr', tr);
setDefaultLocale('tr');

// Ekran boyutunu kontrol etmek için bir hook ekleyelim
const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return matches;
};

const DateInput = React.memo(({ label, value, onChange, onBlur, onKeyPress, inputRef, error }) => {
  const [localValue, setLocalValue] = useState(value);
  const [showNativeDatePicker, setShowNativeDatePicker] = useState(false);
  const [tempDate, setTempDate] = useState('');
  const [validationError, setValidationError] = useState('');
  const buttonRef = useRef(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const isLargeScreen = useMediaQuery('(min-width: 768px)');

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const isValidDate = (dateString) => {
    if (!dateString) return true;
    if (dateString.length !== 10) return false;

    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    
    return date && 
           date.getFullYear() === year && 
           date.getMonth() === month - 1 && 
           date.getDate() === day &&
           year >= 1900 && 
           year <= new Date().getFullYear() + 100;
  };

  const formatDate = useCallback((input) => {
    const cleaned = input.replace(/[^\d]/g, '');
    const match = cleaned.match(/^(\d{1,2})(\d{1,2})?(\d{0,4})?$/);
    if (match) {
      const [, day, month, year] = match;
      if (day) {
        if (month) {
          if (year) {
            return `${day}/${month}/${year}`;
          }
          return `${day}/${month}`;
        }
        return day;
      }
    }
    return cleaned;
  }, []);

  const validateAndSetDate = useCallback((dateString) => {
    if (!dateString) {
      setValidationError('');
      return true;
    }

    if (dateString.length === 10) {
      if (isValidDate(dateString)) {
        setValidationError('');
        return true;
      } else {
        setValidationError('Geçersiz tarih');
        return false;
      }
    } else if (dateString.length > 0) {
      setValidationError('');
    }
    return true;
  }, []);

  const handleChange = useCallback((e) => {
    let input = e.target.value;
    if (input.replace(/[^\d]/g, '').length <= 8) {
      const formatted = formatDate(input);
      setLocalValue(formatted);
      if (!formatted) {
        setValidationError('');
      } else {
        validateAndSetDate(formatted);
      }
      onChange(formatted);
    }
  }, [formatDate, onChange, validateAndSetDate]);

  const handleBlur = useCallback((e) => {
    let formatted = e.target.value;
    if (formatted.length === 8 && !formatted.includes('/')) {
      formatted = `${formatted.slice(0, 2)}/${formatted.slice(2, 4)}/${formatted.slice(4)}`;
    } else if (formatted.length > 5 && formatted.indexOf('/') === 2 && formatted.lastIndexOf('/') === 2) {
      formatted = `${formatted.slice(0, 5)}/${formatted.slice(5)}`;
    }
    
    if (validateAndSetDate(formatted)) {
      setLocalValue(formatted);
      onChange(formatted);
    }
    
    onBlur && onBlur(e);
  }, [onChange, onBlur, validateAndSetDate]);

  const handleFocus = useCallback(() => {
    setLocalValue('');
    onChange('');
  }, [onChange]);

  const handleCalendarClick = useCallback((e) => {
    e.stopPropagation();
    if (isLargeScreen) {
      setIsDatePickerOpen(true);
    } else {
      setShowNativeDatePicker(true);
    }
    setTempDate('');
    
    if (buttonRef.current) {
      buttonRef.current.style.pointerEvents = 'none';
    }

    setTimeout(() => {
      if (buttonRef.current) {
        buttonRef.current.style.pointerEvents = 'auto';
      }
    }, 300);
  }, [isLargeScreen]);

  const handleNativeDatePickerChange = useCallback((e) => {
    const selectedDate = e.target.value;
    if (selectedDate) {
      setTempDate(selectedDate);
    }
  }, []);

  const handleNativeDatePickerBlur = useCallback(() => {
    if (tempDate) {
      const date = new Date(tempDate);
      const formattedDate = date.toLocaleDateString('tr-TR', {day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\./g, '/');
      setLocalValue(formattedDate);
      onChange(formattedDate);
      setValidationError('');
    }
    
    setTimeout(() => {
      setShowNativeDatePicker(false);
    }, 100);
  }, [tempDate, onChange]);

  const handleButtonMouseDown = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const handleDatePickerChange = useCallback((date) => {
    if (date) {
      const formattedDate = date.toLocaleDateString('tr-TR', {day: '2-digit', month: '2-digit', year: 'numeric'}).replace(/\./g, '/');
      setLocalValue(formattedDate);
      onChange(formattedDate);
      setValidationError('');
    }
    setIsDatePickerOpen(false);
  }, [onChange]);

  const parseDate = useCallback((dateString) => {
    if (dateString.length !== 10) return null;
    const [day, month, year] = dateString.split(/[-./]/);
    return new Date(year, month - 1, day);
  }, []);

  const currentYear = new Date().getFullYear();
  const yearRange = 100;

  return (
    <div className="relative">
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          className={`w-full h-12 px-4 py-2 border ${validationError ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 text-base`}
          placeholder="GG/AA/YYYY"
        />
        <button 
          ref={buttonRef}
          onClick={handleCalendarClick}
          onMouseDown={handleButtonMouseDown}
          onTouchStart={handleButtonMouseDown}
          className="absolute right-0 top-0 bottom-0 px-3 flex items-center justify-center text-indigo-600 hover:bg-indigo-100 rounded-r-lg transition-colors duration-200"
        >
          <FontAwesomeIcon icon={faCalendarAlt} className="text-xl" />
        </button>
        {isLargeScreen && isDatePickerOpen && (
          <DatePicker
            selected={localValue ? parseDate(localValue) : null}
            onChange={handleDatePickerChange}
            onClickOutside={() => setIsDatePickerOpen(false)}
            inline
            dateFormat="dd/MM/yyyy"
            locale="tr"
            showYearDropdown
            showMonthDropdown
            scrollableYearDropdown
            scrollableMonthYearDropdown
            yearDropdownItemNumber={yearRange}
            minDate={new Date(currentYear - yearRange, 0, 1)}
            maxDate={new Date()}
            monthNames={[
              "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
              "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
            ]}
            monthNamesShort={[
              "Oca", "Şub", "Mar", "Nis", "May", "Haz",
              "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"
            ]}
            dayNames={["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]}
            dayNamesShort={["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cmt"]}
            dayNamesMin={["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"]}
            dropdownMode="select"
          />
        )}
        {!isLargeScreen && showNativeDatePicker && (
          <input
            type="date"
            onChange={handleNativeDatePickerChange}
            onBlur={handleNativeDatePickerBlur}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            autoFocus
          />
        )}
      </div>
      {validationError && (
        <div className="absolute top-0 right-0 mt-2 mr-2">
          <span className="bg-red-500 text-white text-xs font-bold px-2 py-1 rounded-full">
            {validationError}
          </span>
        </div>
      )}
    </div>
  );
});

const CustomAlert = ({ message, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative animate-fade-in-up">
      <button 
        onClick={onClose}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
      >
        <FontAwesomeIcon icon={faTimes} className="w-5 h-5" />
      </button>
      <div className="flex items-start mb-4">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-yellow-500 w-6 h-6 mr-3 mt-1" />
        <div className="flex-1">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">Eksik Bilgi</h3>
          <div className="text-gray-600">
            {Array.isArray(message) ? (
              <ul className="list-disc pl-5 space-y-1">
                {message.map((msg, index) => (
                  <li key={index}>{msg}</li>
                ))}
              </ul>
            ) : (
              <p>{message}</p>
            )}
          </div>
        </div>
      </div>
      <button
        onClick={onClose}
        className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300"
      >
        Tamam
      </button>
    </div>
  </div>
);

const SunTimes = () => {
  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [result, setResult] = useState({ 
    sunrise: '', 
    sunset: '',
    city: '',
    district: '',
    date: '', // Yeni eklenen alan
    dstPeriod: null // Yeni eklenen alan
  });
  const [isDst, setIsDst] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [dstPeriod, setDstPeriod] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [showDstCard, setShowDstCard] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: [] });

  const dstPeriods = [
    { start: "1990-03-25", end: "1990-09-30" },
    { start: "1991-03-31", end: "1991-09-29" },
    { start: "1992-03-29", end: "1992-09-27" },
    { start: "1993-03-28", end: "1993-09-26" },
    { start: "1994-03-20", end: "1994-09-25" },
    { start: "1995-03-26", end: "1995-09-24" },
    { start: "1996-03-31", end: "1996-10-27" },
    { start: "1997-03-30", end: "1997-10-26" },
    { start: "1998-03-29", end: "1998-10-25" },
    { start: "1999-03-28", end: "1999-10-31" },
    { start: "2000-03-26", end: "2000-10-29" },
    { start: "2001-03-25", end: "2001-10-28" },
    { start: "2002-03-31", end: "2002-10-27" },
    { start: "2003-03-30", end: "2003-10-26" },
    { start: "2004-03-28", end: "2004-10-31" },
    { start: "2005-03-27", end: "2005-10-30" },
    { start: "2006-03-26", end: "2006-10-29" },
    { start: "2007-03-25", end: "2007-10-28" },
    { start: "2008-03-30", end: "2008-10-26" },
    { start: "2009-03-29", end: "2009-10-25" },
    { start: "2010-03-28", end: "2010-10-31" },
    { start: "2011-03-28", end: "2011-10-30" },
    { start: "2012-03-25", end: "2012-10-28" },
    { start: "2013-03-31", end: "2013-10-27" },
    { start: "2014-03-31", end: "2014-10-26" },
    { start: "2015-03-29", end: "2015-10-25" },
    { start: "2016-03-27", end: "süresiz" }
  ];

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await sunTimeService.getCities();
        const cities = response.map(city => ({
          value: city.City,
          label: city.City
        }));
        setCities(cities);
      } catch (error) {
        console.error('Şehirler yüklenirken hata:', error);
      }
    };
    fetchCities();
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      if (selectedCity) {
        try {
          const response = await sunTimeService.getDistricts(selectedCity);
          setDistricts(response.map(d => d.District));
        } catch (error) {
          console.error('İlçeler yüklenirken hata:', error);
        }
      }
    };
    if (selectedCity) {
      fetchDistricts();
    }
  }, [selectedCity]);

  useEffect(() => {
    const loadSearchData = async () => {
      try {
        const citiesResponse = await sunTimeService.getCities();
        const cities = citiesResponse.map(city => ({
            name: city.City,
            type: 'city',
            key: city.City
          }));

        const allDistricts = [];
        for (const city of citiesResponse) {
            const districtsResponse = await sunTimeService.getDistricts(city.City);
          const districts = districtsResponse.map(district => ({
                name: district.District,
                type: 'district',
                parent: city.City,
                key: district.District,
                cityKey: city.City
              }));
            allDistricts.push(...districts);
        }

        setSearchData([...cities, ...allDistricts]);
      } catch (error) {
        console.error('Arama verileri yüklenirken hata:', error);
      }
    };

      loadSearchData();
  }, []);

  const getSunTimes = async () => {
    const missingFields = [];
    if (!selectedCity) missingFields.push("Lütfen bir şehir seçin");
    if (!selectedDistrict) missingFields.push("Lütfen bir ilçe seçin");
    if (!selectedDate) missingFields.push("Lütfen bir tarih seçin");

    if (missingFields.length > 0) {
      setAlert({ show: true, message: missingFields });
      return;
    }

    const dateComponents = selectedDate.split('/').map(Number);
    const testDate = new Date(dateComponents[2], dateComponents[1] - 1, dateComponents[0]);
    
    if (!testDate || testDate.toString() === 'Invalid Date') {
      setAlert({ show: true, message: ["Lütfen geçerli bir tarih girin"] });
      return;
    }

    const monthNames = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 
                      'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'];

    try {
      const response = await sunTimeService.getSunTimes(
        selectedCity,
        selectedDistrict,
        dateComponents[0],
        monthNames[dateComponents[1] - 1]
      );

      const normalSunrise = response['Gün. Doğ.'] || "Bilinmiyor";
      const normalSunset = response['Gün. Bat.'] || "Bilinmiyor";

      const isDstActive = checkIfDstActive(testDate.toISOString().split('T')[0]);
      setIsDst(isDstActive);

      let dstSunrise = normalSunrise;
      let dstSunset = normalSunset;

      if (isDstActive) {
        dstSunrise = adjustForDst(normalSunrise);
        dstSunset = adjustForDst(normalSunset);
      }

      const currentYearDstPeriod = getCurrentYearDstPeriod(testDate.toISOString().split('T')[0]);

      setResult({ 
        sunrise: isDstActive ? dstSunrise : normalSunrise, 
        sunset: isDstActive ? dstSunset : normalSunset,
        normalSunrise,
        normalSunset,
        dstSunrise,
        dstSunset,
        city: selectedCity,
        district: selectedDistrict,
        date: selectedDate,
        dstPeriod: currentYearDstPeriod
      });

      setShowDstCard(true);
    } catch (error) {
      console.error('Güneş vakitleri alınırken hata:', error);
      setResult({ 
        sunrise: "Veri bulunamadı.", 
        sunset: "Veri bulunamadı.",
        normalSunrise: "",
        normalSunset: "",
        dstSunrise: "",
        dstSunset: "",
        city: selectedCity,
        district: selectedDistrict,
        date: selectedDate
      });
    }
  };

  const checkIfDstActive = (date) => {
    const selectedDate = new Date(date);
    for (const period of dstPeriods) {
      const start = new Date(period.start);
      const end = period.end === "süresiz" ? new Date("9999-12-31") : new Date(period.end);
      if (selectedDate >= start && selectedDate <= end) {
        setDstPeriod({
          start: period.start,
          end: period.end === "süresiz" ? "süresiz" : period.end
        });
        return true;
      }
    }
    setDstPeriod(null);
    return false;
  };

  const adjustForDst = (time) => {
    if (time === "Bilinmiyor") return time;

    const [hours, minutes] = time.split(":");
    let adjustedHours = parseInt(hours) + 1;
    if (adjustedHours === 24) adjustedHours = 0;
    return `${adjustedHours.toString().padStart(2, "0")}:${minutes}`;
  };

  const handleSearch = (selectedItem) => {
    if (selectedItem.type === 'city') {
      setSelectedCity(selectedItem.key);
      setSelectedDistrict('');
    } else if (selectedItem.type === 'district') {
      setSelectedCity(selectedItem.cityKey);
      setSelectedDistrict(selectedItem.key);
    }
  };

  const formatDate = (dateString) => {
    if (dateString === "süresiz") return "süresiz";
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const getCurrentYearDstPeriod = (date) => {
    const currentYear = new Date(date).getFullYear();
    return dstPeriods.find(period => {
      const startYear = parseInt(period.start.split('-')[0]);
      const endYear = period.end === "süresiz" ? 9999 : parseInt(period.end.split('-')[0]);
      return currentYear >= startYear && currentYear <= endYear;
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      {alert.show && (
        <CustomAlert 
          message={alert.message} 
          onClose={() => setAlert({ show: false, message: [] })} 
        />
      )}
      <div className="container mx-auto px-4 py-8">
        {/* Ana İçerik */}
        <div className="max-w-6xl mx-auto">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
            <div className="bg-gradient-to-r from-blue-900 to-blue-800 px-5 py-3 shadow-inner">
              <div className="flex items-center">
                <div className="bg-white/20 backdrop-blur-sm rounded-full p-1.5 flex items-center justify-center w-8 h-8">
                  <FontAwesomeIcon icon={faSun} className="w-4 h-4 text-white" />
                </div>
                <h1 className="text-base sm:text-lg md:text-xl font-bold text-white ml-2.5 tracking-wide whitespace-nowrap">
                  Güneşin Doğuş ve Batış Saatleri
                </h1>
              </div>
            </div>
            <div className="bg-gradient-to-b from-gray-50 to-white p-6">
              <div className="mb-6">
                <AutocompleteSearch data={searchData} onSelect={handleSearch} />
              </div>

              <div className="bg-white rounded-lg shadow-lg p-4 sm:p-6 mb-8">
                <div className="grid grid-cols-1 gap-6">
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="city" className="block mb-2 font-semibold text-gray-700">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-indigo-600" />
                        Şehir Seç
                      </label>
                      <div className="relative">
                        <select 
                          id="city" 
                          value={selectedCity} 
                          onChange={(e) => setSelectedCity(e.target.value)} 
                          className="w-full h-12 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 appearance-none bg-white text-base"
                        >
                          <option value="">-- Şehir Seç --</option>
                          {cities.map(city => (
                            <option key={city.value} value={city.value}>{city.label}</option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="district" className="block mb-2 font-semibold text-gray-700">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-indigo-600" />
                        İlçe Seç
                      </label>
                      <div className="relative">
                        <select 
                          id="district" 
                          value={selectedDistrict} 
                          onChange={(e) => setSelectedDistrict(e.target.value)} 
                          className="w-full h-12 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 appearance-none bg-white text-base"
                        >
                          <option value="">-- İlçe Seç --</option>
                          {districts.map(district => (
                            <option key={district} value={district}>{district}</option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label htmlFor="date" className="block mb-2 font-semibold text-gray-700">
                        <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-indigo-600" />
                        Tarih Seç
                      </label>
                      <DateInput
                        value={selectedDate}
                        onChange={setSelectedDate}
                        inputRef={null}
                      />
                    </div>
                  </div>
                </div>

                <button 
                  onClick={getSunTimes} 
                  className="w-full mt-6 bg-indigo-600 text-white p-3 rounded-lg hover:bg-indigo-700 transition duration-300 ease-in-out transform hover:scale-[1.01] text-lg font-semibold"
                >
                  Saatleri Göster
                </button>
              </div>

              {result.sunrise && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                  <SunriseCard 
                    time={result.sunrise} 
                    district={result.district}
                    city={result.city}
                    date={result.date}
                  />
                  <SunsetCard 
                    time={result.sunset} 
                    district={result.district}
                    city={result.city}
                    date={result.date}
                  />
                </div>
              )}

              {showDstCard && isDst !== null && (
                <div className="mt-8">
                  <DstCard 
                    isDst={isDst}
                    normalSunrise={result.normalSunrise}
                    normalSunset={result.normalSunset}
                    dstSunrise={result.dstSunrise}
                    dstSunset={result.dstSunset}
                    onShowDetails={() => setShowPopup(true)}
                    dstPeriod={dstPeriod}
                  />
                </div>
              )}

              {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                  <div className="bg-white p-8 rounded-lg max-w-md w-full">
                    {result.date && (
                      <div className="mb-6 bg-blue-100 p-4 rounded-lg shadow">
                        <h3 className="text-lg font-semibold text-blue-800 mb-2">Seçilen Tarihin Yaz Saati Uygulaması</h3>
                        {result.dstPeriod ? (
                          <>
                            <p className="text-blue-700">
                              Başlangıç: {formatDate(result.dstPeriod.start)}
                            </p>
                            <p className="text-blue-700">
                              Bitiş: {formatDate(result.dstPeriod.end)}
                            </p>
                          </>
                        ) : (
                          <p className="text-blue-700">Bu tarih için yaz saati uygulaması bilgisi bulunamadı.</p>
                        )}
                      </div>
                    )}
                    <h2 className="text-2xl font-bold mb-4 text-indigo-800">Yaz Saati Uygulaması Hakkında</h2>
                    <p className="text-gray-700 mb-6">
                      Türkiye'de belirli tarihlerde yaz saati uygulandı ve sonradan kaldırıldı. 
                      27 Mart 2016 tarihinden sonra kalıcı yaz saati uygulamasına geçildi.
                    </p>
                    <button 
                      onClick={() => setShowPopup(false)} 
                      className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 ease-in-out"
                    >
                      Kapat
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SunTimes;