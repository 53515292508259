import React from 'react';
import TarihGirisi from './TarihGirisi';

const yasGruplari = [
  { id: 'yetiskin', label: '18 yaş ve üzeri', min: 18, max: Infinity, oran: 1 },
  { id: '15-18', label: '15-18 yaş arası', min: 15, max: 18, oran: 2/3 },
  { id: '12-15', label: '12-15 yaş arası', min: 12, max: 15, oran: 1/2 }
];

function YasGrubuSecimi({ 
  dogumTarihi, 
  onDogumTarihiChange, 
  sucTarihi,
  manuelYasGrubu,
  secilenYasGrubu,
  onYasGrubuChange,
  otomatikYasGrubu
}) {
  const hesaplaYas = (dogumTarihi, sucTarihi) => {
    const yas = new Date(sucTarihi - dogumTarihi).getFullYear() - 1970;
    return yas;
  };

  const hesaplananYas = dogumTarihi ? hesaplaYas(dogumTarihi, sucTarihi) : null;

  return (
    <div className="space-y-4">
      {!manuelYasGrubu ? (
        <div className="space-y-4">
          <TarihGirisi
            label="Doğum Tarihi"
            value={dogumTarihi}
            onChange={onDogumTarihiChange}
          />
          {otomatikYasGrubu && (
            <div className="mt-2 bg-green-50 rounded-lg p-3">
              <p className="text-sm font-medium text-green-800">
                Suç tarihindeki yaş: {hesaplananYas}
              </p>
              <p className="text-sm text-green-700 mt-1">
                Yaş grubu: {yasGruplari.find(g => g.id === otomatikYasGrubu)?.label}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Yaş Grubu
          </label>
          <div className="relative mt-1">
            <select
              value={secilenYasGrubu}
              onChange={(e) => onYasGrubuChange(e.target.value)}
              className="appearance-none block w-full px-3 py-2.5 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500 sm:text-sm"
            >
              {yasGruplari.map((grup) => (
                <option key={grup.id} value={grup.id}>
                  {grup.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
              </svg>
            </div>
          </div>
          <p className="mt-2 text-sm text-gray-500">
            Seçilen yaş grubuna göre zamanaşımı süresi hesaplanacaktır
          </p>
        </div>
      )}
    </div>
  );
}

export default YasGrubuSecimi; 