import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { GeneralInfoProvider } from './contexts/GeneralInfoContext';
import { NotificationProvider } from './contexts/NotificationContext.js';
import Notification from './components/Notification.js';
import { FaFileAlt, FaBalanceScale, FaSun, FaCalendarAlt, FaHourglassEnd, FaClipboardList, FaBook, FaCog } from 'react-icons/fa';

// Sayfaları import et
import Summaries from './components/Summaries.js';
import Calculation from './components/Calculation.js';
// import ShowReport from './components/ShowReport';
import Settings from './pages/Settings.js';
import KayitliTakrirler from './pages/KayitliTakrirler.js';
import SunTimes from './pages/SunTimes.js';
import KararSablonlari from './pages/KararSablonlari.js';
import DateDifference from './pages/DateDifference.js';
import ZamanAsimi from './pages/ZamanAsimi.js';

function App() {
  return (
    <Router>
      <NotificationProvider>
        <GeneralInfoProvider>
          <AppContent />
        </GeneralInfoProvider>
      </NotificationProvider>
    </Router>
  );
}

function AppContent() {
  const [reportData, setReportData] = React.useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const updateReportData = (section, data) => {
    setReportData(prevData => ({
      ...prevData,
      [section]: data
    }));
  };

  useEffect(() => {
    const selectedTakrir = localStorage.getItem('selectedTakrir');
    if (selectedTakrir) {
      updateReportData('all', JSON.parse(selectedTakrir));
      localStorage.removeItem('selectedTakrir');
    }
  }, []);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <Notification />
      <nav className="bg-white shadow-lg">
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="flex-shrink-0 flex items-center">
               
                <span className="ml-2 text-xl font-semibold text-gray-800">Mustafa EKİM</span>
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              {/* <NavLink to="/summaries">Takrir Raporu</NavLink> */}
              <NavLink to="/ceza-hesaplama">Ceza Hesaplama</NavLink>
              <NavLink to="/gunes-saatleri">Güneş Saatleri</NavLink>
              <NavLink to="/tarih-farki">Tarih ve Yaş Hesaplama</NavLink>
              {/* <NavLink to="/zaman-asimi">Zaman Aşımı</NavLink> */}
              {/* <NavLink to="/karar-sablonlari">Karar Şablonları</NavLink> */}
              {/* <NavLink to="/kayitli-takrirler">Kayıtlı Takrirler</NavLink> */}
              {/* <NavLink to="/ayarlar">Ayarlar</NavLink> */}
            </div>
            <div className="flex items-center sm:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Ana menüyü aç</span>
                <svg className={`${isMenuOpen ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <svg className={`${isMenuOpen ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Mobil menü */}
        <div className={`sm:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="pt-2 pb-3 space-y-1">
            <MobileNavLink to="/ceza-hesaplama">Ceza Hesaplama</MobileNavLink>
            <MobileNavLink to="/gunes-saatleri">Güneş Saatleri</MobileNavLink>
            <MobileNavLink to="/tarih-farki">Tarih ve Yaş Hesaplama</MobileNavLink>
            {/* <MobileNavLink to="/zaman-asimi">Zaman Aşımı</MobileNavLink> */}
            {/* <MobileNavLink to="/karar-sablonlari">Karar Şablonları</MobileNavLink> */}
            {/* <MobileNavLink to="/takrir-raporu">Takrir Raporu</MobileNavLink> */}
            {/* <MobileNavLink to="/kayitli-takrirler">Kayıtlı Takrirler</MobileNavLink> */}
            {/* <MobileNavLink to="/ayarlar">Ayarlar</MobileNavLink> */}
          </div>
        </div>
      </nav>

      <main className="flex-grow w-full mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <Routes>
          <Route path="/ceza-hesaplama" element={<Calculation onChange={(data) => updateReportData('calculation', data)} data={reportData.calculation} />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/kayitli-takrirler" element={<KayitliTakrirler />} />
          <Route path="/gunes-saatleri" element={<SunTimes />} />
          <Route path="/karar-sablonlari" element={<KararSablonlari />} />
          <Route path="/ayarlar" element={<Settings onChange={(data) => updateReportData('settings', data)} data={reportData.settings} />} />
          <Route path="/tarih-farki" element={<DateDifference />} />
          <Route path="/zaman-asimi" element={<ZamanAsimi />} />
          <Route path="/summaries" element={<Summaries />} />
        </Routes>
      </main>
    </div>
  );
}

function NavLink({ to, children }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`px-3 py-2 rounded-md text-sm font-medium ${
        isActive
          ? 'bg-indigo-500 text-white'
          : 'text-gray-600 hover:bg-indigo-100 hover:text-indigo-700'
      } transition duration-150 ease-in-out`}
    >
      {children}
    </Link>
  );
}

function MobileNavLink({ to, children }) {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`block px-3 py-2 rounded-md text-base font-medium ${
        isActive
          ? 'bg-indigo-500 text-white'
          : 'text-gray-700 hover:bg-indigo-100 hover:text-indigo-700'
      } transition duration-150 ease-in-out`}
    >
      {children}
    </Link>
  );
}

function HomePage() {
  const menuItems = [
    /* {
      title: 'Takrir Raporu',
      Icon: FaFileAlt,
      path: '/summaries',
      description: 'Takrir raporu oluşturun ve düzenleyin',
      gradient: 'from-green-600/90 to-emerald-700/90',
      hoverGradient: 'hover:from-green-500/90 hover:to-emerald-600/90'
    }, */
    {
      title: 'Ceza Hesaplama',
      Icon: FaBalanceScale,
      path: '/ceza-hesaplama',
      description: 'Ceza hesaplamaları yapın',
      gradient: 'from-red-600/90 to-pink-700/90',
      hoverGradient: 'hover:from-red-500/90 hover:to-pink-600/90'
    },
    {
      title: 'Güneş Saatleri',
      Icon: FaSun,
      path: '/gunes-saatleri',
      description: 'Güneş doğuş ve batış saatlerini görüntüleyin',
      gradient: 'from-yellow-500/90 to-orange-600/90',
      hoverGradient: 'hover:from-yellow-400/90 hover:to-orange-500/90'
    },
    {
      title: 'Tarih ve Yaş Hesaplama',
      Icon: FaCalendarAlt,
      path: '/tarih-farki',
      description: 'Tarih farkı ve yaş hesaplamaları yapın',
      gradient: 'from-indigo-600/90 to-blue-700/90',
      hoverGradient: 'hover:from-indigo-500/90 hover:to-blue-600/90'
    }
    /* {
      title: 'Zaman Aşımı',
      Icon: FaHourglassEnd,
      path: '/zaman-asimi',
      description: 'Zaman aşımı hesaplamaları yapın',
      gradient: 'from-purple-600/90 to-violet-700/90',
      hoverGradient: 'hover:from-purple-500/90 hover:to-violet-600/90'
    },
    {
      title: 'Karar Şablonları',
      Icon: FaClipboardList,
      path: '/karar-sablonlari',
      description: 'Hazır karar şablonlarına erişin',
      gradient: 'from-blue-600/90 to-cyan-700/90',
      hoverGradient: 'hover:from-blue-500/90 hover:to-cyan-600/90'
    },
    {
      title: 'Kayıtlı Takrirler',
      Icon: FaBook,
      path: '/kayitli-takrirler',
      description: 'Kayıtlı takrirlerinizi görüntüleyin',
      gradient: 'from-teal-600/90 to-emerald-700/90',
      hoverGradient: 'hover:from-teal-500/90 hover:to-emerald-600/90'
    },
    {
      title: 'Ayarlar',
      Icon: FaCog,
      path: '/ayarlar',
      description: 'Uygulama ayarlarını düzenleyin',
      gradient: 'from-gray-700/90 to-gray-800/90',
      hoverGradient: 'hover:from-gray-600/90 hover:to-gray-700/90'
    } */
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {menuItems.map((item, index) => {
            const IconComponent = item.Icon;
            return (
              <Link
                key={index}
                to={item.path}
                className="block overflow-hidden rounded-xl h-full"
              >
                <div className={`bg-gradient-to-r ${item.gradient} ${item.hoverGradient} rounded-xl shadow-md transition-all duration-500 ease-in-out h-full min-h-[200px] flex flex-col justify-between`}>
                  <div className="relative p-5 flex flex-col items-center text-center space-y-3 group h-full">
                    <div className="p-3 rounded-full bg-white/10 backdrop-blur-sm ring-1 ring-white/20 transition-all duration-300 group-hover:bg-white/20 group-hover:ring-white/30">
                      <IconComponent className="w-6 h-6 text-white/90 transition-transform duration-300 group-hover:scale-105" />
                    </div>
                    <div className="flex-grow flex flex-col justify-center space-y-2">
                      <h2 className="text-lg font-semibold text-white/90">{item.title}</h2>
                      <p className="text-white/80 text-xs leading-relaxed">{item.description}</p>
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-r from-white/0 via-white/10 to-white/0 opacity-0 group-hover:opacity-100 transition-opacity duration-500 blur-xl"></div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
