import React, { useState, useEffect } from 'react';
import Select from 'react-select/creatable';

const TakrirCard = ({ info, onUpdate }) => {
  const [localInfo, setLocalInfo] = useState({
    hakim: '',
    yargitayEsasNo: '',
    takrirGunu: '',
    ...info // info prop'u varsa, bu değerleri üzerine yazar
  });
  const [hakimOptions, setHakimOptions] = useState([]);

  useEffect(() => {
    if (info) {
      setLocalInfo(prevInfo => ({ ...prevInfo, ...info }));
    }
    loadHakimOptions();
  }, [info]);

  const loadHakimOptions = () => {
    const storedHakimler = JSON.parse(localStorage.getItem('hakimler')) || [];
    setHakimOptions(storedHakimler.map(hakim => ({ value: hakim, label: hakim })));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleHakimChange = (selectedOption) => {
    setLocalInfo(prev => ({ ...prev, hakim: selectedOption ? selectedOption.value : '' }));
  };

  const handleCreateHakim = (inputValue) => {
    const newHakim = { value: inputValue, label: inputValue };
    setHakimOptions(prev => [...prev, newHakim]);
    
    // Local storage'a yeni hakimi ekle
    const storedHakimler = JSON.parse(localStorage.getItem('hakimler')) || [];
    localStorage.setItem('hakimler', JSON.stringify([...storedHakimler, inputValue]));
    
    // Yeni hakimi seç
    setLocalInfo(prev => ({ ...prev, hakim: inputValue }));
    return newHakim;
  };

  const handleBlur = () => {
    onUpdate(localInfo);
  };

  const formatYargitayEsasNo = (value) => {
    // Sadece sayıları ve "/" karakterini koru
    const cleaned = value.replace(/[^\d/]/g, '');
    
    // Eğer "/" karakteri varsa ve doğru konumdaysa, olduğu gibi bırak
    if (cleaned.includes('/') && cleaned.indexOf('/') === 4) {
      return cleaned;
    }
    
    // Sadece sayıları al
    const numbers = cleaned.replace(/\D/g, '');
    
    // Eğer 4'ten fazla rakam varsa ve "/" yoksa, otomatik ekle
    if (numbers.length > 4) {
      return `${numbers.slice(0, 4)}/${numbers.slice(4)}`;
    }
    
    return numbers;
  };

  const handleYargitayEsasNoChange = (e) => {
    let inputValue = e.target.value;
    
    // Eğer kullanıcı "/" girmeye çalışıyorsa ve öncesinde 4 rakam yoksa, engelle
    if (inputValue.includes('/') && inputValue.indexOf('/') < 4) {
      inputValue = inputValue.replace('/', '');
    }
    
    const formattedValue = formatYargitayEsasNo(inputValue);
    setLocalInfo(prev => ({ ...prev, yargitayEsasNo: formattedValue }));
  };

  return (
    <div className="space-y-4">
      <div className="flex space-x-4">
        <div className="flex-1">
          <label htmlFor="hakim" className="block text-sm font-medium text-gray-700">Hâkim</label>
          <Select
            id="hakim"
            name="hakim"
            options={hakimOptions}
            value={hakimOptions.find(option => option.value === localInfo.hakim) || null}
            onChange={handleHakimChange}
            onCreateOption={handleCreateHakim}
            onBlur={handleBlur}
            className="mt-1 block w-full"
            classNamePrefix="select"
            isClearable
            isSearchable
            placeholder="Hâkim seçin veya yeni ekleyin"
          />
        </div>
        <div className="flex-1">
          <label htmlFor="yargitayEsasNo" className="block text-sm font-medium text-gray-700">Yargıtay Esas No</label>
          <input
            type="text"
            id="yargitayEsasNo"
            name="yargitayEsasNo"
            value={localInfo.yargitayEsasNo || ''}
            onChange={handleYargitayEsasNoChange}
            onBlur={handleBlur}
            placeholder="YYYY/NNNN"
            className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-3 py-2"
          />
        </div>
        <div className="flex-1">
          <label htmlFor="takrirGunu" className="block text-sm font-medium text-gray-700">Takrir Günü</label>
          <input
            type="date"
            id="takrirGunu"
            name="takrirGunu"
            value={localInfo.takrirGunu || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 px-3 py-2"
          />
        </div>
      </div>
    </div>
  );
};

export default TakrirCard;