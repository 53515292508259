import React, { useEffect, useRef, useState } from 'react';
import { PlusCircle, Search } from 'lucide-react';

const ColumnMenu = ({ menuPosition, activeMenu, handleAddColumn, setActiveMenu, predefinedColumns }) => {
  const menuRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [menuStyle, setMenuStyle] = useState({});

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };

    const handleResize = () => {
      if (menuPosition && menuRef.current) {
        updateMenuPosition();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
  }, [setActiveMenu]);

  const updateMenuPosition = () => {
    if (!menuPosition || !menuRef.current) return;

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const menuHeight = menuRef.current.offsetHeight;
    const menuWidth = menuRef.current.offsetWidth;
    const spaceBelow = windowHeight - menuPosition.top;
    const spaceRight = windowWidth - menuPosition.left;

    const maxAvailableHeight = Math.min(
      spaceBelow > menuHeight ? spaceBelow - 20 : windowHeight - 40,
      500 // Maksimum 500px yükseklik
    );

    let newStyle = {
      maxHeight: `${maxAvailableHeight}px`,
      width: 'auto',
      minWidth: '16rem',
      maxWidth: '90vw'
    };

    if (spaceBelow < menuHeight && menuPosition.top > menuHeight) {
      const topPosition = Math.max(20, menuPosition.top - Math.min(menuHeight, maxAvailableHeight));
      newStyle.top = `${topPosition}px`;
    } else {
      newStyle.top = `${menuPosition.top}px`;
    }

    if (spaceRight < menuWidth) {
      newStyle.right = `${windowWidth - menuPosition.left}px`;
    } else {
      newStyle.left = `${menuPosition.left}px`;
    }

    setMenuStyle(newStyle);
  };

  useEffect(() => {
    updateMenuPosition();
  }, [menuPosition, searchTerm]);

  if (!menuPosition || !activeMenu) return null;

  const filteredColumns = [
    { id: 'empty', type: 'empty', name: 'Boş Kolon Ekle' },
    ...predefinedColumns
  ].filter(column => {
    const searchLower = searchTerm.toLowerCase();
    if (column.type === 'empty') {
      return column.name.toLowerCase().includes(searchLower);
    }
    const columnText = column.isSingleLine ? column.content :
                      column.isTwoLineText ? `${column.topContent} ${column.bottomContent}` :
                      column.law || column.content || '';
    return columnText.toLowerCase().includes(searchLower);
  });

  return (
    <div
      ref={menuRef}
      className="fixed bg-white border border-gray-200 rounded-lg shadow-lg z-50 overflow-hidden flex flex-col"
      style={menuStyle}
    >
      {/* Arama Kutusu */}
      <div className="p-2 border-b border-gray-200 flex-shrink-0">
        <div className="relative">
          <Search className="absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
          <input
            type="text"
            placeholder="Kolon ara..."
            className="w-full pl-8 pr-4 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {/* Kolon Listesi */}
      <div 
        className="overflow-y-auto flex-grow scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent"
      >
        {filteredColumns.length === 0 ? (
          <div className="px-4 py-2 text-sm text-gray-500">
            Sonuç bulunamadı
          </div>
        ) : (
          <div className="py-1">
            {filteredColumns.map((column) => (
              <button
                key={column.id}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition-colors duration-150 focus:bg-gray-100 focus:outline-none"
                onClick={() => {
                  if (column.type === 'empty') {
                    handleAddColumn(activeMenu.cardId, activeMenu.columnIndex);
                  } else {
                    handleAddColumn(activeMenu.cardId, activeMenu.columnIndex, column);
                  }
                  setActiveMenu(null);
                }}
              >
                <div className="flex items-center space-x-2">
                  <PlusCircle className="h-4 w-4 flex-shrink-0" />
                  <span className="line-clamp-1 break-all">
                    {column.type === 'empty' ? column.name :
                     column.isSingleLine ? column.content :
                     column.isTwoLineText ? `${column.topContent} / ${column.bottomContent}` :
                     column.law || column.content || 'Ön Tanımlı Kolon'}
                  </span>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ColumnMenu;