import React from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';

const Tab = ({ children, isActive, onClick, onClose, isFirst }) => {
  const truncatedText = typeof children === 'string' ? children.slice(0, 20) + (children.length > 20 ? '...' : '') : children;

  return (
    <div 
      className={`
        relative flex items-center px-4 py-2 mr-1 rounded-t-lg transition-all duration-200 ease-in-out
        ${isActive 
          ? isFirst
            ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-md'
            : 'bg-gradient-to-r from-orange-400 to-orange-600 text-white shadow-md' 
          : isFirst
            ? 'bg-blue-200 text-blue-800 hover:bg-blue-400'
            : 'bg-orange-100 text-orange-800 hover:bg-orange-200'
        }
        max-w-[180px] group overflow-hidden
      `}
    >
      <div 
        className="absolute inset-0 bg-white opacity-20"
        style={{
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 10px))'
        }}
      />
      <div 
        className="flex-grow truncate cursor-pointer font-medium relative z-10" 
        onClick={onClick}
      >
        {truncatedText}
      </div>
      {onClose && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className={`
            ml-2 rounded-full p-1 relative z-10
            ${isActive 
              ? 'text-white hover:bg-white hover:bg-opacity-20' 
              : isFirst
                ? 'text-blue-400 opacity-0 group-hover:opacity-100 hover:text-blue-700 hover:bg-blue-300'
                : 'text-orange-400 opacity-0 group-hover:opacity-100 hover:text-orange-700 hover:bg-orange-300'
            }
            transition-all duration-200 ease-in-out
          `}
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

export default Tab;
