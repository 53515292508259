import { turkishToLower } from '../../utils/stringUtils';

export function filtreliSablonlariGetir(sablonlar, secilenKategoriler, secilenEtiketler, secilenKanunMaddeleri, aramaMetni) {
  const aramaMetniKucuk = turkishToLower(aramaMetni || '');
  const tumKategorilerSecili = secilenKategoriler.includes('Tümü');
  const kategorisizSecili = secilenKategoriler.includes('Kategorisiz');
  
  return sablonlar.filter(sablon => {
    const kategoriKosulu = tumKategorilerSecili || 
      (kategorisizSecili && (!sablon.kategoriler || sablon.kategoriler.length === 0)) ||
      (sablon.kategoriler && sablon.kategoriler.some(kategori => secilenKategoriler.includes(kategori.isim)));
    
    const etiketKosulu = secilenEtiketler.length === 0 || 
      (sablon.etiketler && secilenEtiketler.every(etiket => sablon.etiketler.includes(etiket)));
    
    const kanunMaddesiKosulu = secilenKanunMaddeleri.length === 0 || 
      (sablon.kanun_maddeleri && secilenKanunMaddeleri.every(madde => sablon.kanun_maddeleri.includes(madde)));
    
    const aramaKosulu = !aramaMetniKucuk || 
      turkishToLower(sablon.baslik).includes(aramaMetniKucuk) ||
      (sablon.etiketler && sablon.etiketler.some(etiket => turkishToLower(etiket).includes(aramaMetniKucuk))) ||
      (sablon.icerik && turkishToLower(sablon.icerik).includes(aramaMetniKucuk));

    return kategoriKosulu && etiketKosulu && kanunMaddesiKosulu && aramaKosulu;
  });
}
