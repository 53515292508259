import React, { useRef, useEffect, useState } from 'react';
import { PlusCircle, ChevronDown, Search } from 'lucide-react';

const AddCardMenu = ({ isOpen, toggleMenu, addCard, templates }) => {
  const menuRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleMenu();
        setSearchTerm('');
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, toggleMenu]);

  const filteredTemplates = templates.filter(template =>
    template.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative inline-block text-left" ref={menuRef}>
      <button 
        className="px-4 py-2 text-sm border rounded-md inline-flex items-center gap-2 bg-white hover:bg-gray-50 text-gray-700"
        onClick={toggleMenu}
      >
        <PlusCircle className="h-4 w-4" /> 
        <span>Yeni Ceza Kartı</span>
        <ChevronDown className="h-4 w-4" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="p-2 border-b border-gray-200">
            <div className="relative">
              <Search className="absolute left-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
              <input
                type="text"
                className="w-full pl-8 pr-3 py-1.5 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Şablon ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className="max-h-60 overflow-y-auto py-1">
            <button
              className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 flex items-center gap-2"
              onClick={() => {
                addCard();
                toggleMenu();
                setSearchTerm('');
              }}
            >
              <PlusCircle className="h-4 w-4 text-gray-500" />
              <span>Boş Kart</span>
            </button>

            {filteredTemplates.length > 0 ? (
              filteredTemplates.map((template, index) => (
                <button
                  key={index}
                  className="w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                  onClick={() => {
                    addCard(template);
                    toggleMenu();
                    setSearchTerm('');
                  }}
                >
                  <PlusCircle className="h-4 w-4 text-gray-500" />
                  <span>{template.title}</span>
                </button>
              ))
            ) : searchTerm ? (
              <div className="px-4 py-2 text-sm text-gray-500 text-center">
                Sonuç bulunamadı
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddCardMenu;