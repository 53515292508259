import React, { useState } from 'react';
import { cezaArttir, cezaEksilt, formatCeza } from '../utils/cezaHesaplamalari';
import { PlusIcon, MinusIcon, TrashIcon, ArrowUpIcon, CalculatorIcon } from '@heroicons/react/24/outline';

const formatCezaWithSpaces = (ceza) => {
  const parts = [];
  if (ceza.year > 0) parts.push(`${ceza.year} yıl`);
  if (ceza.month > 0) parts.push(`${ceza.month} ay`);
  if (ceza.day > 0) parts.push(`${ceza.day} gün`);
  return parts.join(' ') + ' '; // Sona bir boşluk ekliyoruz
};

const formatAPC = (fine) => {
  return fine > 0 ? `${fine} gün APC` : '';
};

const Calculation = ({ onChange, data }) => {
  const [ceza, setCeza] = useState({ year: 0, month: 0, day: 0, fine: 0 });
  const [oran, setOran] = useState({ numerator: 1, denominator: 2 });
  const [hesaplamaGecmisi, setHesaplamaGecmisi] = useState([]);
  const [customNumerator, setCustomNumerator] = useState('');
  const [customDenominator, setCustomDenominator] = useState('');
  const [isCustomRate, setIsCustomRate] = useState(false);

  const handleCezaChange = (e) => {
    const { name, value } = e.target;
    setCeza(prev => ({ ...prev, [name]: parseInt(value) || 0 }));
  };

  const handleOranChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'other') {
      setIsCustomRate(true);
      // Diğer seçeneği seçildiğinde mevcut oranı koruyalım
      setCustomNumerator(oran.numerator.toString());
      setCustomDenominator(oran.denominator.toString());
    } else {
      setIsCustomRate(false);
      const [numerator, denominator] = selectedValue.split('/').map(Number);
      setOran({ numerator, denominator });
      setCustomNumerator(numerator.toString());
      setCustomDenominator(denominator.toString());
    }
  };

  const handleCustomRateChange = (part) => (e) => {
    const value = e.target.value;
    if (part === 'numerator') {
      setCustomNumerator(value);
    } else {
      setCustomDenominator(value);
    }
    
    const numerator = part === 'numerator' ? parseInt(value, 10) : parseInt(customNumerator, 10);
    const denominator = part === 'denominator' ? parseInt(value, 10) : parseInt(customDenominator, 10);
    
    if (numerator && denominator) {
      setOran({ numerator, denominator });
    }
  };

  const hesapla = (isArttir) => {
    let yeniCeza;
    if (isArttir) {
      yeniCeza = cezaArttir(ceza, { rate: oran });
    } else {
      yeniCeza = cezaEksilt(ceza, { rate: oran });
    }
    // Yeni hesaplamayı listenin sonuna ekliyoruz
    setHesaplamaGecmisi(prev => [...prev, { ceza, oran, sonuc: yeniCeza, isArttir }]);
    setCeza(yeniCeza);
    if (onChange) onChange(yeniCeza);
  };

  const yeniHesap = () => {
    setCeza({ year: 0, month: 0, day: 0, fine: 0 });
    setOran({ numerator: 1, denominator: 2 });
    setCustomNumerator('');
    setCustomDenominator('');
    setIsCustomRate(false);
    setHesaplamaGecmisi([]);
  };

  const handleYukariTasi = (gecmisCeza, e) => {
    e.stopPropagation(); // Tıklama olayının üst öğelere yayılmasını engeller
    setCeza(gecmisCeza);
  };

  const handleGecmisSil = (index, e) => {
    e.stopPropagation(); // Tıklama olayının üst öğelere yayılmasını engeller
    setHesaplamaGecmisi(prev => prev.filter((_, i) => i !== index));
  };

  const handleInputFocus = (event) => {
    event.target.select();
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-6xl mx-auto">
          <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
            <div className="bg-gradient-to-r from-[#475569] to-[#64748B] px-5 py-3 shadow-inner border-b border-gray-300 relative overflow-hidden">
              <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(255,255,255,0.1)_0%,transparent_50%)] opacity-50"></div>
              <div className="flex items-center relative z-10">
                <div className="bg-gradient-to-br from-[#E2E8F0] to-[#F8FAFC] backdrop-blur-sm rounded-full p-1.5 flex items-center justify-center w-8 h-8 shadow-lg">
                  <CalculatorIcon className="w-4 h-4 text-[#475569]" />
                </div>
                <h1 className="text-xl font-bold text-gray-50 ml-2.5 tracking-wide">
                  Ceza Hesaplama
                </h1>
              </div>
            </div>
            <div className="bg-gradient-to-b from-gray-50 to-white p-6">
              <div className="mb-8">
                <div className="flex justify-end">
                  <button
                    onClick={yeniHesap}
                    className="w-full md:w-auto px-6 py-3 bg-blue-800 text-white text-sm font-semibold rounded-lg hover:scale-[1.01] hover:bg-blue-700 transition-all duration-300 shadow-sm flex items-center justify-center space-x-2"
                  >
                    <CalculatorIcon className="w-4 h-4" />
                    <span>Yeni Hesap</span>
                  </button>
                </div>
              </div>
              <div className="space-y-8">
                {/* Yıl, Ay, Gün Grid */}
                <div className="space-y-6">
                  <div className="grid grid-cols-3 gap-6">
                    {['Yıl', 'Ay', 'Gün'].map((label, index) => (
                      <div key={index} className="relative">
                        <label className="absolute -top-2 left-2 bg-white px-1 text-xs font-medium text-gray-600">{label}</label>
                        <input
                          type="number"
                          name={['year', 'month', 'day'][index]}
                          value={ceza[['year', 'month', 'day'][index]]}
                          onChange={handleCezaChange}
                          onFocus={handleInputFocus}
                          className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                        />
                      </div>
                    ))}
                  </div>
                  <div className="relative">
                    <label className="absolute -top-2 left-2 bg-white px-1 text-xs font-medium text-gray-600">Adli Para Cezası</label>
                    <input
                      type="number"
                      name="fine"
                      value={ceza.fine}
                      onChange={handleCezaChange}
                      onFocus={handleInputFocus}
                      className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200"
                    />
                  </div>
                </div>

                {/* Oran ve Butonlar Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="relative">
                    <label className="absolute -top-2 left-2 bg-white px-1 text-xs font-medium text-gray-600">Oran</label>
                    <div className="flex items-center space-x-2">
                      <select
                        value={isCustomRate ? 'other' : `${oran.numerator}/${oran.denominator}`}
                        onChange={handleOranChange}
                        className={`${isCustomRate ? 'w-1/3' : 'w-full'} h-12 px-4 appearance-none bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 text-base`}
                      >
                        {['1/1','1/2', '1/3', '1/6', '2/3', '3/4', '5/6', '1/12'].map(rate => (
                          <option key={rate} value={rate}>{rate}</option>
                        ))}
                        <option value="other">Diğer</option>
                      </select>
                      {isCustomRate && (
                        <>
                          <input
                            type="number"
                            value={customNumerator}
                            onChange={handleCustomRateChange('numerator')}
                            onFocus={handleInputFocus}
                            className="w-1/3 h-12 px-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 text-base"
                            placeholder="Pay"
                          />
                          <span className="text-2xl font-bold text-gray-500">/</span>
                          <input
                            type="number"
                            value={customDenominator}
                            onChange={handleCustomRateChange('denominator')}
                            onFocus={handleInputFocus}
                            className="w-1/3 h-12 px-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 text-base"
                            placeholder="Payda"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => hesapla(true)}
                      className="flex-1 px-6 py-3 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition-all duration-200 shadow-md hover:shadow-lg flex items-center justify-center"
                    >
                      <PlusIcon className="h-5 w-5 mr-2" />
                      Arttır
                    </button>
                    <button
                      onClick={() => hesapla(false)}
                      className="flex-1 px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-all duration-200 shadow-md hover:shadow-lg  flex items-center justify-center"
                    >
                      <MinusIcon className="h-5 w-5 mr-2" />
                      Azalt
                    </button>
                  </div>
                </div>

                {/* Sonuç ceza kartı */}
                {hesaplamaGecmisi.length > 0 && (
                  <div className="mt-8 p-6 bg-gradient-to-r from-blue-500 to-indigo-600 rounded-xl shadow-lg hover:shadow-xl transform hover:scale-[1.01] transition-all duration-300">
                    <div className="text-sm text-white opacity-80 mb-2">
                      {formatCezaWithSpaces(hesaplamaGecmisi[hesaplamaGecmisi.length - 1].ceza)} 
                      {hesaplamaGecmisi[hesaplamaGecmisi.length - 1].ceza.fine > 0 && 
                        <span className="block text-xs opacity-70">
                          {formatAPC(hesaplamaGecmisi[hesaplamaGecmisi.length - 1].ceza.fine)}
                        </span>
                      }
                      ceza {' '}
                      {hesaplamaGecmisi[hesaplamaGecmisi.length - 1].oran.numerator}/
                      {hesaplamaGecmisi[hesaplamaGecmisi.length - 1].oran.denominator} oranında 
                      {hesaplamaGecmisi[hesaplamaGecmisi.length - 1].isArttir ? ' arttırıldı' : ' eksiltildi'} ve bulunan sonuç:
                    </div>
                    <div className="text-3xl font-bold text-white">
                      {formatCezaWithSpaces(ceza)}
                      {ceza.fine > 0 && 
                        <span className="block text-lg opacity-80 mt-1">
                          {formatAPC(ceza.fine)}
                        </span>
                      }
                    </div>
                  </div>
                )}

                {/* Hesaplama Geçmişi */}
                {hesaplamaGecmisi.length > 0 && (
                  <div className="mt-8">
                    <h3 className="text-2xl font-semibold text-gray-800 mb-4">Hesaplama Geçmişi</h3>
                    <div className="space-y-4">
                      {hesaplamaGecmisi.map((hesaplama, index) => (
                        <div 
                          key={index} 
                          className="relative bg-white rounded-xl shadow-sm border border-gray-100 hover:shadow-md transition-all duration-200 group overflow-hidden"
                        >
                          <div className="flex items-center justify-between group-hover:pr-14 transition-all duration-200 h-full">
                            <div className="flex-1 p-4 border-r border-gray-100">
                              <p className="text-xs font-medium text-gray-400 uppercase tracking-wider mb-1">Temel Ceza</p>
                              <p className="text-lg font-semibold text-gray-700">
                                {formatCezaWithSpaces(hesaplama.ceza)}
                                {hesaplama.ceza.fine > 0 && 
                                  <span className="block text-sm text-gray-500">
                                    {formatAPC(hesaplama.ceza.fine)}
                                  </span>
                                }
                              </p>
                            </div>
                            <div className="flex-1 p-4 bg-gray-50 flex flex-col items-center justify-center">
                              <p className="text-2xl font-bold text-indigo-600 mb-1">
                                {hesaplama.oran.numerator}/{hesaplama.oran.denominator}
                              </p>
                              <div className="flex items-center justify-center">
                                {hesaplama.isArttir ? (
                                  <span className="bg-green-100 text-green-700 text-xs font-medium px-2.5 py-0.5 rounded-full">Artırım</span>
                                ) : (
                                  <span className="bg-red-100 text-red-700 text-xs font-medium px-2.5 py-0.5 rounded-full">İndirim</span>
                                )}
                              </div>
                            </div>
                            <div className="flex-1 p-4 border-l border-gray-100">
                              <p className="text-xs font-medium text-gray-400 uppercase tracking-wider mb-1">Sonuç</p>
                              <p className="text-lg font-semibold text-indigo-600">
                                {formatCezaWithSpaces(hesaplama.sonuc)}
                                {hesaplama.sonuc.fine > 0 && 
                                  <span className="block text-sm text-gray-500">
                                    {formatAPC(hesaplama.sonuc.fine)}
                                  </span>
                                }
                              </p>
                            </div>
                          </div>
                          <div className="absolute right-4 top-1/2 hidden group-hover:flex flex-col -translate-y-1/2 space-y-2">
                            <button 
                              className="p-1.5 bg-indigo-100 text-indigo-600 rounded-full hover:bg-indigo-200 focus:outline-none transition-colors duration-200"
                              onClick={(e) => handleYukariTasi(hesaplama.ceza, e)}
                              title="Yukarı Taşı"
                            >
                              <ArrowUpIcon className="h-4 w-4" />
                            </button>
                            <button 
                              className="p-1.5 bg-red-100 text-red-600 rounded-full hover:bg-red-200 focus:outline-none transition-colors duration-200"
                              onClick={(e) => handleGecmisSil(index, e)}
                              title="Sil"
                            >
                              <TrashIcon className="h-4 w-4" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calculation;
