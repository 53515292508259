export const cityNameMapping = {
  'adana': 'Adana',
  'adiyaman': 'Adıyaman',
  'afyonkarahisar': 'Afyonkarahisar',
  'agri': 'Ağrı',
  'amasya': 'Amasya',
  'ankara': 'Ankara',
  'antalya': 'Antalya',
  'artvin': 'Artvin',
  'aydin': 'Aydın',
  'balikesir': 'Balıkesir',
  'bilecik': 'Bilecik',
  'bingol': 'Bingöl',
  'bitlis': 'Bitlis',
  'bolu': 'Bolu',
  'burdur': 'Burdur',
  'bursa': 'Bursa',
  'canakkale': 'Çanakkale',
  'cankiri': 'Çankırı',
  'corum': 'Çorum',
  'denizli': 'Denizli',
  'diyarbakir': 'Diyarbakır',
  'edirne': 'Edirne',
  'elazig': 'Elazığ',
  'erzincan': 'Erzincan',
  'erzurum': 'Erzurum',
  'eskisehir': 'Eskişehir',
  'gaziantep': 'Gaziantep',
  'giresun': 'Giresun',
  'gumushane': 'Gümüşhane',
  'hakkari': 'Hakkari',
  'hatay': 'Hatay',
  'isparta': 'Isparta',
  'mersin': 'Mersin',
  'istanbul': 'İstanbul',
  'izmir': 'İzmir',
  'kars': 'Kars',
  'kastamonu': 'Kastamonu',
  'kayseri': 'Kayseri',
  'kirklareli': 'Kırklareli',
  'kirsehir': 'Kırşehir',
  'kocaeli': 'Kocaeli',
  'konya': 'Konya',
  'kutahya': 'Kütahya',
  'malatya': 'Malatya',
  'manisa': 'Manisa',
  'kahramanmaras': 'Kahramanmaraş',
  'mardin': 'Mardin',
  'mugla': 'Muğla',
  'mus': 'Muş',
  'nevsehir': 'Nevşehir',
  'nigde': 'Niğde',
  'ordu': 'Ordu',
  'rize': 'Rize',
  'sakarya': 'Sakarya',
  'samsun': 'Samsun',
  'siirt': 'Siirt',
  'sinop': 'Sinop',
  'sivas': 'Sivas',
  'tekirdag': 'Tekirdağ',
  'tokat': 'Tokat',
  'trabzon': 'Trabzon',
  'tunceli': 'Tunceli',
  'sanliurfa': 'Şanlıurfa',
  'usak': 'Uşak',
  'van': 'Van',
  'yozgat': 'Yozgat',
  'zonguldak': 'Zonguldak',
  'aksaray': 'Aksaray',
  'bayburt': 'Bayburt',
  'karaman': 'Karaman',
  'kirikkale': 'Kırıkkale',
  'batman': 'Batman',
  'sirnak': 'Şırnak',
  'bartin': 'Bartın',
  'ardahan': 'Ardahan',
  'igdir': 'Iğdır',
  'yalova': 'Yalova',
  'karabuk': 'Karabük',
  'kilis': 'Kilis',
  'osmaniye': 'Osmaniye',
  'duzce': 'Düzce'
}; 