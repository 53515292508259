import React, { lazy, Suspense, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import 'react-quill/dist/quill.snow.css';
// import AIEnhancer from './AIEnhancer';
import { Etiketleme, KanunMaddeleri } from './Etiketleme';
import DetaylarModal from './DetaylarModal';

const ReactQuill = lazy(() => import('react-quill'));

const DuzenlemeFormu = ({
  sablon,
  duzenlenmisSablon,
  kategoriler,
  kayitliEtiketler,
  kayitliKanunMaddeleri,
  handleChange,
  handleIcerikChange,
  handleSelectChange,
  handleAIEnhance,
  handleSubmit
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleModalSave = (updatedSablon) => {
    handleChange({ target: { name: 'ozet', value: updatedSablon.ozet } });
    handleChange({ target: { name: 'baslik', value: updatedSablon.baslik } });
    handleSelectChange('etiketler', updatedSablon.etiketler);
    handleSelectChange('kanun_maddeleri', updatedSablon.kanun_maddeleri);
    handleSelectChange('kategoriler', updatedSablon.kategoriler);
    toggleModal();
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto p-6 space-y-6">
        <div className="space-y-4">
          <div className="bg-gray-50 p-4 rounded-lg shadow-inner border border-gray-300">
            <label htmlFor="icerik" className="block text-lg font-semibold text-gray-800 mb-2">İçerik</label>
            <div className="h-[calc(100vh-450px)] min-h-[300px] overflow-hidden">
              <Suspense fallback={<div>Yükleniyor...</div>}>
                <ReactQuill
                  id="icerik"
                  value={duzenlenmisSablon.icerik}
                  onChange={handleIcerikChange}
                  className="h-full pb-12"
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ 'header': [1, 2, 3, false] }],
                      ['bold', 'italic', 'underline'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      ['link', 'image'],
                      ['clean']
                    ],
                  }}
                  ref={(el) => {
                    if (el) {
                      el.getEditor().root.setAttribute('data-gramm', 'false');
                    }
                  }}
                />
              </Suspense>
            </div>
          </div>
          {/* <AIEnhancer
            icerik={duzenlenmisSablon.icerik}
            mevcutEtiketler={duzenlenmisSablon.etiketler}
            mevcutKanunMaddeleri={duzenlenmisSablon.kanunMaddeleri || []}
            onEnhance={handleAIEnhance}
          /> */}
        </div>
      </div>
      {isModalOpen && (
        <DetaylarModal
          duzenlenmisSablon={duzenlenmisSablon}
          kategoriler={kategoriler}
          handleChange={handleChange}
          handleSelectChange={handleSelectChange}
          kayitliEtiketler={kayitliEtiketler}
          kayitliKanunMaddeleri={kayitliKanunMaddeleri}
          onClose={toggleModal}
          onSave={handleSubmit}
        />
      )}
    </div>
  );
};

export default DuzenlemeFormu;
