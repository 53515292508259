import React, { useState, useCallback, useEffect } from 'react';
import StatementCard from './StatementCard';
import PartyCard from './partyCard/PartyCard.js';
import GeneralInfoCard from './cards/GeneralInfoCard.js';
import ProcedureCard from './cards/ProcedureCard.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { exportToWord } from './WordExport';
import { ChevronDown, ChevronUp, Plus, Users, FileText, MessageSquare, Settings } from 'lucide-react';
import TakrirCard from './cards/TakrirCard.js';
import CezaSahsilestirmeCard from './cards/CezaSahsilestirmeCard.js';
import SanikSabikasıCard from './cards/SanikSabikasıCard.js';
import TebliğnameCard from './cards/TebliğnameCard.js';
import TetkikHakimiGorusuCard from './cards/TetkikHakimiGorusuCard.js';
import OnIncelemeCard from './cards/OnIncelemeCard.js';
import TemyizNedenleriCard from './cards/TemyizNedenleriCard.js';
import DelillerCard from './cards/DelillerCard.js';
import { useNavigate } from 'react-router-dom';
import CollapsiblePanel from './summariesComponents/CollapsiblePanel.js';
import EditPartyModal from './EditPartyModal';
import TemyizTalebindeBulunanlarCard from './cards/TemyizTalebindeBulunanlarCard.js';
import PartiesPanel from './PartiesPanel.js';
import WordSettingsCard from './cards/WordSettingsCard.js';

const Summaries = () => {
  const [items, setItems] = useState([]);
  const [showPartyMenu, setShowPartyMenu] = useState(false);
  const [generalInfo, setGeneralInfo] = useState({
    mahkemesi: '',
    esasNo: '',
    kararNo: '',
    suc: '',
    sucTarihi: '',
    kararTarihi: '',
  });
  const [procedureInfo, setProcedureInfo] = useState({
    davaŞartı: '',
    şikayet: '',
    önÖdeme: '',
    uzlaştırma: '',
    basitYargılama: '',
  });

  // Panel açılıp kapanma durumları için state'ler
  const [isGeneralInfoOpen, setIsGeneralInfoOpen] = useState(true);
  const [isProcedureInfoOpen, setIsProcedureInfoOpen] = useState(true);
  const [isPartiesOpen, setIsPartiesOpen] = useState(true);
  const [isSummariesOpen, setIsSummariesOpen] = useState(true);
  const [isStatementsOpen, setIsStatementsOpen] = useState(true);
  const [isTakrirOpen, setIsTakrirOpen] = useState(true);
  const [takrirInfo, setTakrirInfo] = useState({
    hakim: '',
    takrirGunu: '',
  });
  const [isCezaSahsilestirmeOpen, setIsCezaSahsilestirmeOpen] = useState(true);
  const [cezaSahsilestirmeInfo, setCezaSahsilestirmeInfo] = useState({
    yerelMahkemeUygulamasi: '',
    tck62: '',
    tck50: '',
    tck51: '',
    cmk231: '',
  });
  const [isSanikSabikasıOpen, setIsSanikSabikasıOpen] = useState(true);
  const [isTebliğnameOpen, setIsTebliğnameOpen] = useState(true);
  const [isTetkikHakimiGorusuOpen, setIsTetkikHakimiGorusuOpen] = useState(true);

  const [sanikSabikasıInfo, setSanikSabikasıInfo] = useState({ sanikSabikasi: '' });
  const [tebliğnameInfo, setTebliğnameInfo] = useState('');
  const [tetkikHakimiGorusuInfo, setTetkikHakimiGorusuInfo] = useState({ tetkikHakimiGorusu: '' });

  const [isOnIncelemeOpen, setIsOnIncelemeOpen] = useState(true);
  const [isTemyizNedenleriOpen, setIsTemyizNedenleriOpen] = useState(true);
  const [isDelillerOpen, setIsDelillerOpen] = useState(true);

  const [onIncelemeInfo, setOnIncelemeInfo] = useState('');
  const [temyizNedenleriInfo, setTemyizNedenleriInfo] = useState('');
  const [delillerInfo, setDelillerInfo] = useState('');

  const [folders, setFolders] = useState([]);
  const [activeFolder, setActiveFolder] = useState(null);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingParty, setEditingParty] = useState(null);

  const [isTemyizTalebindeBulunanlarOpen, setIsTemyizTalebindeBulunanlarOpen] = useState(true);
  const [temyizTalebindeBulunanlarInfo, setTemyizTalebindeBulunanlarInfo] = useState('');

  const [isWordSettingsOpen, setIsWordSettingsOpen] = useState(false);
  const [wordMargins, setWordMargins] = useState({ top: 1.5, bottom: 1.5, left: 2, right: 2 });

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Items güncellendi:", items);
    console.log("İfade tipi öğeler:", items.filter(item => item.type === 'tanık'));
  }, [items]);

  const updateStatement = (id, updatedInfo) => {
    setItems(prevItems => {
      const newItems = prevItems.map(item => {
        if (item.id === id) {
          const updatedItem = { ...item, ...updatedInfo };
          console.log("Güncellenmiş öğe:", updatedItem);
          return updatedItem;
        }
        return item;
      });
      console.log("İfade güncellendi:", newItems);
      console.log("Güncellenen ifade:", newItems.find(item => item.id === id));
      console.log("Tüm ifadeler:", newItems.filter(item => ['tanık', 'mağdur', 'sanık'].includes(item.type)));
      return newItems;
    });
  };

  const handleUpdateItem = (updatedItem) => {
    console.log("Summaries: Item güncelleniyor", updatedItem);
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === updatedItem.id ? { ...item, ...updatedItem } : item
      )
    );
  };

  useEffect(() => {
    console.log("Summaries: items güncellendi", items);
    // items güncellendiğinde localStorage'a kaydet
    localStorage.setItem('summariesItems', JSON.stringify(items));
  }, [items]);

  const saveAllData = () => {
    const yargitayEsasNo = takrirInfo.yargitayEsasNo || 'YeniTakrir';
    
    // Mevcut kayıtları al
    const existingRecords = JSON.parse(localStorage.getItem('takrirRecords')) || [];
    
    // Aynı esas numarasına sahip kayıt var mı kontrol et
    const existingRecordIndex = existingRecords.findIndex(record => record.title === yargitayEsasNo);
    
    const allData = {
      items: items.map(item => {
        if (item.type === 'taraf') {
          return {
            ...item,
            penaltyCards: item.penaltyCards ? {
              cards: Array.isArray(item.penaltyCards.cards) ? item.penaltyCards.cards : 
                     (Array.isArray(item.penaltyCards) ? item.penaltyCards : [])
            } : { cards: [] }
          };
        }
        return item;
      }),
      generalInfo,
      procedureInfo,
      takrirInfo,
      cezaSahsilestirmeInfo,
      sanikSabikasıInfo,
      tebliğnameInfo,
      tetkikHakimiGorusuInfo,
      onIncelemeInfo,
      temyizNedenleriInfo,
      delillerInfo,
      temyizTalebindeBulunanlarInfo
    };
    
    const saveRecord = () => {
      const newRecord = {
        id: Date.now(),
        date: new Date().toLocaleString(),
        title: yargitayEsasNo,
        data: allData,
        folderId: activeFolder
      };
      
      if (existingRecordIndex !== -1) {
        // Mevcut kaydı güncelle
        existingRecords[existingRecordIndex] = newRecord;
      } else {
        // Yeni kayıt ekle
        existingRecords.push(newRecord);
      }
      
      // Güncellenmiş kayıtları localStorage'a kaydet
      localStorage.setItem('takrirRecords', JSON.stringify(existingRecords));
      localStorage.setItem('allData', JSON.stringify(allData));
      
      alert('Takrir başarıyla kaydedildi!');
    };
    
    if (existingRecordIndex !== -1) {
      // Eğer aynı esas numarasına sahip kayıt varsa, kullanıcıya sor
      if (window.confirm(`"${yargitayEsasNo}" esas numaralı kayıt zaten mevcut. Üzerine yazmak istiyor musunuz?`)) {
        saveRecord();
      } else {
        // Kullanıcı üzerine yazmak istemezse, yeni bir kayıt olarak ekle
        const newTitle = `${yargitayEsasNo}_${Date.now()}`;
        if (window.confirm(`Yeni bir kayıt olarak "${newTitle}" adıyla kaydedilecek. Onaylıyor musunuz?`)) {
          const newRecord = {
            id: Date.now(),
            date: new Date().toLocaleString(),
            title: newTitle,
            data: allData,
            folderId: activeFolder
          };
          existingRecords.push(newRecord);
          localStorage.setItem('takrirRecords', JSON.stringify(existingRecords));
          localStorage.setItem('allData', JSON.stringify(allData));
          alert('Takrir yeni bir kayıt olarak başarıyla kaydedildi!');
        }
      }
    } else {
      // Aynı esas numarasına sahip kayıt yoksa direkt kaydet
      saveRecord();
    }
  };

  const addNewStatement = (type) => {
    const newItem = {
      id: Date.now(),
      type: type, // 'tanık' yerine parametre olarak gelen 'type' kullanılıyor
      content: '',
      name: '',
      stage: '',
      category: type === 'özet' ? 'Özet' : 'Tanık', // Özet için farklı varsayılan kategori
      date: new Date().toISOString().slice(0, 10),
    };
    setItems(prevItems => {
      const newItems = [...prevItems, newItem];
      console.log(`Yeni ${type} eklendi:`, newItems);
      console.log("Tüm öğeler:", newItems.filter(item => ['tanık', 'mağdur', 'sanık', 'özet'].includes(item.type)));
      return newItems;
    });
  };

  const addNewParty = () => {
    setEditingParty(null);
    setIsEditModalOpen(true);
  };

  const handleSaveParty = (newParty) => {
    if (editingParty) {
      // Mevcut partiyi güncelle
      updateParty(editingParty.id, newParty);
    } else {
      // Yeni parti ekle
      const partyWithId = { ...newParty, id: Date.now(), type: 'taraf' };
      setItems(prevItems => [...prevItems, partyWithId]);
    }
    setIsEditModalOpen(false);
  };

  const updateParty = (id, updatedParty) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === id
          ? {
              ...item,
              ...updatedParty,
              penaltyCards: updatedParty.penaltyCards || []
            }
          : item
      )
    );
  };

  const addPenaltyCard = (partyId, newPenaltyCard) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === partyId
          ? {
              ...item,
              penaltyCards: [...(item.penaltyCards || []), newPenaltyCard]
            }
          : item
      )
    );
  };

  const updatePenaltyCard = (partyId, penaltyCardId, updatedPenaltyCard) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === partyId
          ? {
              ...item,
              penaltyCards: item.penaltyCards.map(card =>
                card.id === penaltyCardId ? { ...card, ...updatedPenaltyCard } : card
              )
            }
          : item
      )
    );
  };

  const deletePenaltyCard = (partyId, penaltyCardId) => {
    setItems(prevItems =>
      prevItems.map(item =>
        item.id === partyId
          ? {
              ...item,
              penaltyCards: item.penaltyCards.filter(card => card.id !== penaltyCardId)
            }
          : item
      )
    );
  };

  const deleteItem = (id) => {
    setItems(prevItems => {
      const newItems = prevItems.filter(item => item.id !== id);
      console.log("İfade silindi:", newItems);
      return newItems;
    });
  };

  const moveCard = useCallback((dragIndex, hoverIndex, panelType) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      const panelItems = newItems.filter(item => {
        if (panelType === 'taraflar') return item.type === 'taraf';
        if (panelType === 'özetler') return item.type === 'özet';
        if (panelType === 'ifadeler') return item.type === 'tanık';
        return false;
      });
      const [reorderedItem] = panelItems.splice(dragIndex, 1);
      panelItems.splice(hoverIndex, 0, reorderedItem);
      
      console.log("Taşıma sonrası items:", newItems);
      console.log("Taşıma sonrası panel items:", panelItems);
      
      const updatedItems = newItems.map(item => {
        if (panelType === 'taraflar' && item.type === 'taraf') return panelItems.shift() || item;
        if (panelType === 'özetler' && item.type === 'özet') return panelItems.shift() || item;
        if (panelType === 'ifadeler' && item.type === 'tanık') return panelItems.shift() || item;
        return item;
      });
      
      console.log("Taşıma sonrası güncellenmiş items:", updatedItems);
      console.log("Taşıma sonrası ifadeler:", updatedItems.filter(item => item.type === 'tanık'));
      
      return updatedItems;
    });
  }, []);

  const updateGeneralInfo = (updatedInfo) => {
    setGeneralInfo(updatedInfo);
  };

  const updateProcedureInfo = (updatedInfo) => {
    setProcedureInfo(updatedInfo);
  };

  const updateTakrirInfo = (updatedInfo) => {
    setTakrirInfo(updatedInfo);
  };

  const updateCezaSahsilestirmeInfo = (updatedInfo) => {
    setCezaSahsilestirmeInfo(updatedInfo);
  };

  const updateSanikSabikasıInfo = useCallback((updatedInfo) => {
    setSanikSabikasıInfo(updatedInfo);
  }, []);

  const updateTebliğnameInfo = useCallback((updatedInfo) => {
    setTebliğnameInfo(updatedInfo);
  }, []);

  const updateTetkikHakimiGorusuInfo = (updatedInfo) => {
    setTetkikHakimiGorusuInfo(updatedInfo);
  };

  const updateOnIncelemeInfo = (updatedInfo) => {
    setOnIncelemeInfo(updatedInfo);
  };

  const updateTemyizNedenleriInfo = (updatedInfo) => {
    setTemyizNedenleriInfo(updatedInfo);
  };

  const updateDelillerInfo = (updatedInfo) => {
    setDelillerInfo(updatedInfo);
  };

  const updateTemyizTalebindeBulunanlarInfo = (updatedInfo) => {
    setTemyizTalebindeBulunanlarInfo(updatedInfo);
  };

  const handleExportToWord = () => {
    const summariesToExport = items.filter(item => item.type !== 'taraf');
    const partiesToExport = items.filter(item => item.type === 'taraf');
    exportToWord(
      summariesToExport,
      generalInfo,
      procedureInfo,
      takrirInfo,
      partiesToExport,
      onIncelemeInfo,
      cezaSahsilestirmeInfo,
      sanikSabikasıInfo.sanikSabikasi,
      tebliğnameInfo,
      tetkikHakimiGorusuInfo.tetkikHakimiGorusu,
      temyizTalebindeBulunanlarInfo,
      temyizNedenleriInfo,
      delillerInfo,
      wordMargins
    );
  };

  const handleSaveWordSettings = (newMargins) => {
    setWordMargins(newMargins);
  };

  const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  useEffect(() => {
    const savedFolders = JSON.parse(localStorage.getItem('takrirFolders')) || [];
    setFolders(savedFolders);
    const selectedTakrir = JSON.parse(localStorage.getItem('selectedTakrir'));
    if (selectedTakrir) {
      setItems(selectedTakrir.items.map(item => {
        if (item.type === 'taraf') {
          return {
            ...item,
            penaltyCards: {
              cards: Array.isArray(item.penaltyCards) ? item.penaltyCards : 
                     (item.penaltyCards && Array.isArray(item.penaltyCards.cards) ? item.penaltyCards.cards : [])
            }
          };
        }
        return item;
      }));
      setGeneralInfo(selectedTakrir.generalInfo || {});
      setProcedureInfo(selectedTakrir.procedureInfo || {});
      setTakrirInfo(selectedTakrir.takrirInfo || {});
      setCezaSahsilestirmeInfo(selectedTakrir.cezaSahsilestirmeInfo || {});
      setSanikSabikasıInfo(selectedTakrir.sanikSabikasıInfo || { sanikSabikasi: '' });
      setTebliğnameInfo(selectedTakrir.tebliğnameInfo || '');
      setTetkikHakimiGorusuInfo(selectedTakrir.tetkikHakimiGorusuInfo || { tetkikHakimiGorusu: '' });
      setOnIncelemeInfo(selectedTakrir.onIncelemeInfo || '');
      setTemyizNedenleriInfo(selectedTakrir.temyizNedenleriInfo || '');
      setDelillerInfo(selectedTakrir.delillerInfo || '');
      setTemyizTalebindeBulunanlarInfo(selectedTakrir.temyizTalebindeBulunanlarInfo || '');
      
      // Yükleme tamamlandıktan sonra selectedTakrir'i temizle
      localStorage.removeItem('selectedTakrir');
    }
  }, []);

  const PanelButton = ({ onClick, icon: Icon, text, color }) => (
    <button
      onClick={onClick}
      className={`flex items-center space-x-2 px-4 py-2 ${color} text-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 ease-in-out transform hover:-translate-y-1 mb-4`}
    >
      <Icon size={18} />
      <span>{text}</span>
    </button>
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="space-y-6 relative">
        <h2 className="text-2xl font-bold mb-6">Özetler, İfadeler ve Taraflar</h2>

       {/* Takrir Raporu Paneli */}
       <CollapsiblePanel
          title="Takrir Raporu"
          isOpen={isTakrirOpen}
          setIsOpen={setIsTakrirOpen}
        >
          <TakrirCard info={takrirInfo} onUpdate={updateTakrirInfo} />
        </CollapsiblePanel>

             {/* Genel Bilgiler Paneli */}
             <CollapsiblePanel
          title="Genel Bilgiler"
          isOpen={isGeneralInfoOpen}
          setIsOpen={setIsGeneralInfoOpen}
        >
          <GeneralInfoCard info={generalInfo} onUpdate={updateGeneralInfo} />
        </CollapsiblePanel>

        
        {/* Temyiz Talebinde Bulunanlar Paneli */}
        <CollapsiblePanel
          title="Temyiz Talebinde Bulunanlar"
          isOpen={isTemyizTalebindeBulunanlarOpen}
          setIsOpen={setIsTemyizTalebindeBulunanlarOpen}
        >
          <TemyizTalebindeBulunanlarCard 
            info={temyizTalebindeBulunanlarInfo} 
            onUpdate={updateTemyizTalebindeBulunanlarInfo} 
          />
        </CollapsiblePanel>

         {/* Ön İnceleme Paneli */}
         <CollapsiblePanel
          title="Ön İncelemede Tespit Edilen Hususlar"
          isOpen={isOnIncelemeOpen}
          setIsOpen={setIsOnIncelemeOpen}
        >
          <OnIncelemeCard info={onIncelemeInfo} onUpdate={updateOnIncelemeInfo} />
        </CollapsiblePanel>

        <PartiesPanel
          isPartiesOpen={isPartiesOpen}
          setIsPartiesOpen={setIsPartiesOpen}
          items={items}
          addNewParty={addNewParty}
          updateParty={updateParty}
          deleteItem={deleteItem}
          addPenaltyCard={addPenaltyCard}
          updatePenaltyCard={updatePenaltyCard}
          deletePenaltyCard={deletePenaltyCard}
        />

        {/* Özetler Paneli */}
        <CollapsiblePanel
          title="Özetler"
          isOpen={isSummariesOpen}
          setIsOpen={setIsSummariesOpen}
        >
          <PanelButton
            onClick={() => addNewStatement('özet')}
            icon={FileText}
            text="Yeni Özet Ekle"
            color="bg-blue-500 hover:bg-blue-600"
          />
          {items.filter(item => item.type === 'özet').map((item, index) => (
            <StatementCard 
              key={item.id}
              id={item.id}
              index={index}
              info={item}
              onUpdate={updateStatement}
              onDelete={deleteItem}
              moveCard={(dragIndex, hoverIndex) => moveCard(dragIndex, hoverIndex, 'özetler')}
            />
          ))}
        </CollapsiblePanel>

        {/* İfadeler Paneli */}
        <CollapsiblePanel
          title="İfadeler"
          isOpen={isStatementsOpen}
          setIsOpen={setIsStatementsOpen}
        >
          <PanelButton
            onClick={() => addNewStatement('tanık')}
            icon={MessageSquare}
            text="Yeni İfade Ekle"
            color="bg-green-500 hover:bg-green-600"
          />
          {items.filter(item => ['tanık', 'mağdur', 'sanık'].includes(item.type)).map((item, index) => (
            <StatementCard 
              key={item.id}
              id={item.id}
              index={index}
              info={item}
              onUpdate={updateStatement}
              onDelete={deleteItem}
              moveCard={(dragIndex, hoverIndex) => moveCard(dragIndex, hoverIndex, 'ifadeler')}
            />
          ))}
        </CollapsiblePanel>

       

   

        {/* Soruşturma ve Kovuşturma Koşulları Paneli */}
        <CollapsiblePanel
          title="Soruşturma ve Kovuşturma Koşulları"
          isOpen={isProcedureInfoOpen}
          setIsOpen={setIsProcedureInfoOpen}
        >
          <ProcedureCard info={procedureInfo} onUpdate={updateProcedureInfo} />
        </CollapsiblePanel>

         

        {/* Cezanın Şahsîleştirilmesi Paneli */}
        <CollapsiblePanel
          title="Cezanın Şahsîleştirilmesi"
          isOpen={isCezaSahsilestirmeOpen}
          setIsOpen={setIsCezaSahsilestirmeOpen}
        >
          <CezaSahsilestirmeCard info={cezaSahsilestirmeInfo} onUpdate={updateCezaSahsilestirmeInfo} />
        </CollapsiblePanel>

        {/* Sanık Sabıkası Paneli */}
        <CollapsiblePanel
          title="Sanık Sabıkası"
          isOpen={isSanikSabikasıOpen}
          setIsOpen={setIsSanikSabikasıOpen}
        >
          <SanikSabikasıCard info={sanikSabikasıInfo} onUpdate={updateSanikSabikasıInfo} />
        </CollapsiblePanel>

        {/* Tebliğname Paneli */}
        <CollapsiblePanel
          title="Tebliğname"
          isOpen={isTebliğnameOpen}
          setIsOpen={setIsTebliğnameOpen}
        >
          <TebliğnameCard info={tebliğnameInfo} onUpdate={updateTebliğnameInfo} />
        </CollapsiblePanel>

        {/* Tetkik Hâkimi Görüşü Paneli */}
        <CollapsiblePanel
          title="Tetkik Hâkimi Görüşü"
          isOpen={isTetkikHakimiGorusuOpen}
          setIsOpen={setIsTetkikHakimiGorusuOpen}
        >
          <TetkikHakimiGorusuCard info={tetkikHakimiGorusuInfo} onUpdate={updateTetkikHakimiGorusuInfo} />
        </CollapsiblePanel>

      

        {/* Temyiz Nedenleri Paneli */}
        <CollapsiblePanel
          title="Temyiz Nedenleri"
          isOpen={isTemyizNedenleriOpen}
          setIsOpen={setIsTemyizNedenleriOpen}
        >
          <TemyizNedenleriCard info={temyizNedenleriInfo} onUpdate={updateTemyizNedenleriInfo} />
        </CollapsiblePanel>

        {/* Deliller Paneli */}
        <CollapsiblePanel
          title="Deliller"
          isOpen={isDelillerOpen}
          setIsOpen={setIsDelillerOpen}
        >
          <DelillerCard info={delillerInfo} onUpdate={updateDelillerInfo} />
        </CollapsiblePanel>

        <div className="mb-4">
          <select
            value={activeFolder || ''}
            onChange={(e) => setActiveFolder(e.target.value === '' ? null : Number(e.target.value))}
            className="p-2 border rounded"
          >
            <option value="">Ana Dizin</option>
            {folders.map(folder => (
              <option key={folder.id} value={folder.id}>{folder.name}</option>
            ))}
          </select>
        </div>
        <div className="flex space-x-2 mt-6">
          <button onClick={handleExportToWord} className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 transition-colors duration-300">
            Word'e Aktar
          </button>
          <button onClick={saveAllData} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition-colors duration-300">
            Kaydet
          </button>
        
        </div>

        <EditPartyModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          party={editingParty}
          onSave={handleSaveParty}
        />

        <div className="fixed bottom-4 right-4">
          <button
            onClick={() => setIsWordSettingsOpen(true)}
            className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-full shadow-lg transition-colors duration-300"
          >
            <Settings size={24} />
          </button>
        </div>

        <WordSettingsCard
          isOpen={isWordSettingsOpen}
          onClose={() => setIsWordSettingsOpen(false)}
          onSave={handleSaveWordSettings}
        />
      </div>
    </DndProvider>
  );
};

export default Summaries;