import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_URL}/taraflar`;

export const getPartyByTakrirId = async (takrirId) => {
  try {
    const response = await axios.get(`${API_URL}/takrir/${takrirId}`);
    return response.data;
  } catch (error) {
    console.error('Taraf getirme hatası:', error);
    throw error;
  }
};

export const createParty = async (partyData) => {
  try {
    console.log('Sunucuya gönderilen veri:', partyData);
    const response = await axios.post(API_URL, partyData);
    console.log('Sunucudan gelen yanıt:', response.data);
    return response.data;
  } catch (error) {
    console.error('Taraf oluşturma hatası:', error);
    if (error.response) {
      console.error('Sunucu hata yanıtı:', error.response.data);
      console.error('Hata durumu:', error.response.status);
      console.error('Hata başlıkları:', error.response.headers);
    } else if (error.request) {
      console.error('İstek yapıldı ancak yanıt alınamadı:', error.request);
    } else {
      console.error('Hata mesajı:', error.message);
    }
    throw error;
  }
};

export const updateParty = async (id, partyData) => {
  try {
    const response = await axios.put(`${API_URL}/${id}`, partyData);
    return response.data;
  } catch (error) {
    console.error('Taraf güncelleme hatası:', error);
    throw error;
  }
};

export const deleteParty = async (id) => {
  try {
    const response = await axios.delete(`${API_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Taraf silme hatası:', error);
    throw error;
  }
};
