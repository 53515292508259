import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const YeniKategoriModal = ({ isOpen, onClose, onKategoriEkle, parentKategori, parentId, duzenlenecekKategori, ustKategoriler, kategoriler }) => {
  const [kategoriAdi, setKategoriAdi] = useState('');
  const [hata, setHata] = useState('');

  useEffect(() => {
    if (duzenlenecekKategori) {
      setKategoriAdi(duzenlenecekKategori);
    } else {
      setKategoriAdi('');
    }
    setHata('');
  }, [duzenlenecekKategori, parentKategori, parentId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (kategoriAdi.trim()) {
      const sonuc = await onKategoriEkle(kategoriAdi.trim(), parentId);
      if (sonuc === false) {
        setHata(`"${kategoriAdi}" kategorisi eklenemedi veya zaten mevcut!`);
        toast.error(`"${kategoriAdi}" kategorisi eklenemedi veya zaten mevcut!`);
      } else {
        onClose();
      }
    }
  };

  if (!isOpen) return null;

  // Tüm kategori hiyerarşisini birleştir
  const fullHierarchy = [...ustKategoriler, parentKategori].filter(Boolean).join(' > ');

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full relative">
        {parentId !== null && (
          <div className="absolute top-2 right-2 text-xs text-gray-500">
            Parent ID: {parentId}
          </div>
        )}
        <h2 className="text-lg font-medium mb-4 text-gray-800">
          {duzenlenecekKategori ? 'Kategori Düzenle' : 'Yeni Kategori Ekle'}
        </h2>
        {fullHierarchy && (
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-700 mb-1">Kategori Hiyerarşisi:</p>
            <p className="text-sm text-gray-600">{fullHierarchy}</p>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={kategoriAdi}
            onChange={(e) => {
              setKategoriAdi(e.target.value);
              setHata('');
            }}
            placeholder="Kategori adı"
            className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          {hata && <p className="text-red-500 text-sm mb-4">{hata}</p>}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400"
            >
              İptal
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
            >
              {duzenlenecekKategori ? 'Güncelle' : 'Ekle'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default YeniKategoriModal;
